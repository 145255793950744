import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Button, Modal, ModalFooter,
  ModalHeader, ModalBody
} from "reactstrap"
import { withRouter } from 'react-router-dom';
function ModalComponent(props) {

  return (
    <div>
      <Modal isOpen={true}
        toggle={props.cancel}
        size={props.isLarge ? "lg" : ""}
      >
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>
          {props.children}
        </ModalBody>
        <ModalFooter>
          {
            props.isShowModalSpinner ?
              <Button
                color="primary"
                disabled={true}
              >
                <i className='bx bx-hourglass bx-spin font-size-16 align-middle me-2' />
                Loading
              </Button>
              : <Button
                color="primary"
                onClick={() => {
                  if (props.onSubmit) props.onSubmit()
                }}
              >
                {props.actionButtonTitle}
              </Button>
          }
          {' '}
          <Button onClick={props.cancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div >
  );
}

export default withRouter(ModalComponent)