import MetaTags from 'react-meta-tags';
import React from "react"
import { Row, Col, Card, CardBody, Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { addButtonSpinner, removeButtonSpinner } from "../../store/actions"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import uuid from 'react-uuid'
import ActionButton from '../../components/Buttons/ActionButton'
import AlertService from '../../services/alertService'
import { ERROR_KEY, SUCCESS_KEY } from '../../constants/mainKeys'
import { useCallback } from 'react'
import ApiService from '../../services/apiService'
import mainLogoS from "./../../assets/images/logo/main-logo-symbol.svg"

const buttonSpinnerId = uuid();

const ForgetPasswordPage = props => {

  const dispatch = useDispatch();

  function handleValidSubmit(event, values) {
    event && event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    ApiService.forgotPassword(values).then(() => {
      AlertService.alert(SUCCESS_KEY, "Your request has been sent")
      extractButtonSpinner(buttonSpinnerId)
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forgot Password | Ardy</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-white p-4">
                        <h5 className="text-white">Welcome Back !</h5>
                        <p>Sign in to continue to Ardy.</p>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={mainLogoS}
                            alt="/"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <ActionButton
                            type="submit"
                            name={`Reset`}
                            className="shadow-sm ms-auto px-4 mt-3 btn btn-primary w-100"
                            spinnerId={buttonSpinnerId}
                          />
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to={`/login`} className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Ardy.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgetPasswordPage)
