import $ from 'jquery';
// import { INVALID_TIME_KEY, VALID_TIME_KEY } from '../Constants/mainKeys';
import * as moment from "moment";

// const INVALID_TIME_KEY = "Invalid time";
// const VALID_TIME_KEY = "Valid time";

class MainService {

  static triggerUploadClick(event) {
    let inputElem = $($(event.target).find('input:file'))[0];
    if (!inputElem) {
      inputElem = $($(event.target.parentElement).find('input:file'))[0];
    }
    $(inputElem).trigger('click');
  }

  // readFile(file, validFileTypes = null) {
  //   return new Promise((resolve, reject) => {
  //     let reader = new FileReader();
  //     const fileName = file.name;
  //     const lastDotIndex = fileName.lastIndexOf('.');
  //     const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
  //     const isValid = validFileTypes.find(data => data === fileExtention);
  //     !isValid && reject(true);
  //     reader.readAsDataURL(file);
  //     reader.onloadend = () => {
  //       resolve(reader.result);
  //     }
  //   });
  // }

  async readFile(file, validFileTypes = null, progressCallback) {
    const CHUNK_SIZE = 1024 * 1024 * 10000; // 10000 MB -> 10GB
    let offset = 0;
    const fileSize = file.size;
    const chunks = [];

    // Validate file extension
    const fileName = file.name;
    const lastDotIndex = fileName.lastIndexOf('.');
    const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : '';
    const isValid = validFileTypes.includes(fileExtention);
    if (!isValid) {
      throw new Error("Invalid file extension");
    }

    while (offset < fileSize) {
      const end = Math.min(offset + CHUNK_SIZE, fileSize);
      const chunk = await this.readChunk(file, offset, end, progressCallback);
      chunks.push(chunk);
      offset = end;
    }

    const videoBlob = new Blob(chunks, { type: file.type });
    const videoUrl = URL.createObjectURL(videoBlob);
    return videoUrl;
  }

  async readChunk(file, start, end, cb) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const blob = file.slice(start, end);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error); // Pass the error to reject()
      reader.readAsArrayBuffer(blob);
      if (cb) {
        reader.onprogress = function (data) {
          if (data.lengthComputable) {
            var progress = parseInt(((data.loaded / data.total) * 100), 10);
            cb(progress)
          }
        }
      }
    });
  }

  changeDateFormat(data) {
    if (!data) { return ''; }
    const newData = new Date(data);
    const year = newData.getUTCFullYear();
    const month = this.checkNumberLength(newData.getMonth() + 1);
    const day = this.checkNumberLength(newData.getDate());
    return `${year}-${month}-${day}`;
  }

  checkNumberLength(number) {
    return number && number.toString().length === 1 ? `0${number}` : number;
  }

  static isValidDate(date) {
    const dateFormat = 'YYYY-MM-DD';
    const toDateFormat = moment(new Date(date)).format(dateFormat);
    return moment(toDateFormat, dateFormat, true).isValid();
  }

  static isValidTime(time) {
    const timeFormat = 'HH:mm';
    return moment(time, timeFormat, true).isValid();
  }

  static isValidDateTime(date) {
    const dateFormat = 'mm-dd-yyyy hh:mm';
    const toDateFormat = moment(new Date(date)).format(dateFormat);
    return moment(toDateFormat, dateFormat, true).isValid();
  }

  static convertUTCDateToLocalDate(date) {
    if (!date) { return ""; }
    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();

    date.setHours(hours - offset);
    return date;
    // return moment(date).local().format('YYYY-MM-DD HH:mm:ss');
  }

  static convertToValidDateFormat(date) {
    return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
  }

  static validateDate(date) {
    var q = new Date();
    var today = new Date(q.getFullYear(), q.getMonth(), q.getDate(), q.getHours(), q.getMinutes(), q.getSeconds());
    var mydate = new Date(date);
    if (mydate >= today) {
      return true
    } else {
      return false
    }
  }

  // static checkOnlyTime = (startTime, endTime) => {
  //   var t = new Date(),
  //     d = t.getDate(),
  //     m = t.getMonth() + 1,
  //     y = t.getFullYear();
  //   var d1 = new Date(m + "/" + d + "/" + y + " " + startTime);
  //   var d2 = new Date(m + "/" + d + "/" + y + " " + endTime);
  //   var t1 = d1.getTime();
  //   var t2 = d2.getTime();
  //   if (t1 < t2)
  //     if (t1 < t2) {
  //       return VALID_TIME_KEY;
  //     } else {
  //       return INVALID_TIME_KEY;
  //     }
  // }

  static validateOnlyDate(date) {
    var q = new Date();
    var today = new Date(q.getFullYear(), q.getMonth(), q.getDate());
    var mydate = new Date(date);
    if (mydate >= today) {
      return true
    } else {
      return false
    }
  }

  static checkDates(firstDate, secondDate) {
    if (!firstDate || !secondDate) { return; }
    if (new Date(firstDate).getTime() < new Date(secondDate).getTime()) {
      return true;
    } else return false;
  }

  static checkTime(firstTime, secondTime) {
    if (firstTime > secondTime)
      alert("Time 1 is later than time 2");
    else
      alert("Time 2 is later than time 1");
  }

  static checkDatesMoreOrEqual(firstDate, secondDate) {
    if (!firstDate || !secondDate) { return; }
    if (new Date(firstDate).getTime() <= new Date(secondDate).getTime()) {
      return true;
    } else return false;
  }

  static isValidField(fieldValue, fieldName) {
    if (fieldName === "firstName" || fieldName === "lastName" || fieldName === "firstname" || fieldName === "lastname" || fieldName === "username") {
      // const idValid = /^([a-zA-Z]{1,15})([\s{0,1}]?)([-{0,1}]?)([a-zA-Z]{1,15})$/;
      // const idValid = /^(?:((([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-.\s])){1,}(['’,\-\.]){0,1}){2,}(([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-. ]))*(([ ]+){0,1}(((([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-\.\s])){1,})(['’\-,\.]){0,1}){2,}((([^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]'’,\-\.\s])){2,})?)*)$/;
      // const idValid = /^([a-zA-Z]{1,100})(\-?)([a-zA-Z]{1,100})$/;

      // only Cyrillic and English letters
      const idValid = /^[A-Za-z\u0400-\u04FF]{1,100}(\-?)[A-Za-z\u0400-\u04FF]{1,100}$/;
      return idValid.test(fieldValue.trim());
    }
    if (fieldName === "email" || fieldName === "organizationEmail") {
      // const idValid = /^[A-z,0-9,]{1,30}\d*@[A-z,0-9,]*.[A-z]*$/;
      // const idValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
      // const idValid = /^[a-zA-Z0-9_.?]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.?]+$/;
      const idValid = /^[a-zA-Z0-9_.?]+@[a-zA-Z]+\.[a-zA-Z]+$/;
      return idValid.test(fieldValue.trim());
    }
    if (fieldName === "password" || fieldName === "confirmPassword") {
      // const idValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/;
      const idValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]([^\s@]+){6,}$/;
      return idValid.test(fieldValue);
    }
  }

  static getDownloadUrl(url) {
    if (!url) { return; }
    if (url.includes("?")) {
      return `${url.split("?")[0]}/download`
    } else {
      return `${url}/download`
    }
  }

  static getUrlExtension() {
    // return "ru";
    return window.location.hostname.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
  }

  static scrollToInvalidFieldPosition() {
    setTimeout(() => {
      let invalidField = document.querySelector(".error-border");
      invalidField && window.scrollTo({
        top: invalidField?.getBoundingClientRect().top + document.documentElement.scrollTop - 120,
        behavior: "smooth"
      });
    }, 200);
  }


}

export default MainService;