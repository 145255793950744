import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { useSelector } from 'react-redux'
import AlertService from '../../../services/alertService'
import { ERROR_KEY, fieldValidation } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Col, Button, Label, Form, Input, NavItem, NavLink } from 'reactstrap'
import FormLanguage from '../../../components/FormLanguage/FormLanguage'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import ActionButton from '../../../components/Buttons/ActionButton'
import classnames from 'classnames'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

const buttonSpinnerId = uuid();
export default function Syllabus(props) {
  const dispatch = useDispatch()
  const { formLanguage } = useSelector(state => state.Languages);
  const [changes, setChanges] = useState(false);
  const [isInvalidBenefits, setIsInvalidBenefits] = useState(false);
  const [courseTypes, setCourseTypes] = useState([]);
  const [courseTypeId, setCourseTypeId] = useState(null);

  const [values, setValues] = useState({
    key: "Benefits",
    value: [""]
  });

  useEffect(() => {
    if (courseTypeId) {
      getBenefits(courseTypeId);
    }
  }, [formLanguage, courseTypeId])

  useEffect(() => {
    getCourseTypes();
  }, [formLanguage])

  const addBenefits = () => {
    let newBenefit = { ...values };
    const newField = "";
    let emptyField = newBenefit.value.find(item => !item.trim().length);
    if (typeof emptyField === "string") {
      setIsInvalidBenefits(true);
      return false;
    }
    newBenefit.value.push(newField)
    setValues(newBenefit);
  };

  const getCourseTypes = () => {
    if (!formLanguage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getCourseTypes(formLanguage.id).then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setCourseTypes(data);
        setCourseTypeId(data[0].id)
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const onBenefitsChange = (event, index, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    const newBenefits = { ...values };
    newBenefits.value[index] = event.target.value;
    setValues(newBenefits);
    setIsInvalidBenefits(false);
  }

  const removeBenefit = (index) => {
    let newBenefit = { ...values };
    newBenefit.value = newBenefit.value.filter((_, i) => i !== index);
    setValues(newBenefit);
    setChanges(true);
  };

  const getBenefits = () => {
    if (!formLanguage || !courseTypeId) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getBenefits(formLanguage.id, courseTypeId).then(response => {
      if (response && response.data) {
        setValues(response.data);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!formLanguage) { return false; }
    setButtonSpinner(buttonSpinnerId);
    values.languageId = formLanguage.id;
    values.courseTypeId = courseTypeId;
    ApiService.setBenefits(values).then(() => {
      setChanges(false);
      extractButtonSpinner(buttonSpinnerId);
      AlertService.alert("success", "Data saved")
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="Benefits"
        />
        <FormLanguage />
        <hr />
        <Form
          className='mb-3'
          onChange={() => setChanges(true)}
          onSubmit={onSubmit}
        >
          <Label>Choose course type</Label>
          <div className='tab-title-group tab-content'>
            <Tabs
              id="controlled-tab-example"
              activeKey={courseTypeId}
              onSelect={key => {
                if (+key !== +courseTypeId) {
                  setCourseTypeId(+key)
                }
              }}
              className="mb-3"
            >
             
              {
                courseTypes && courseTypes.length ?
                  courseTypes.map((item, index) => {
                    return <Tab key={index} eventKey={item.id} title={item.title}>
                      <Label>Benefits</Label>
                      <div className='border rounded p-2 mb-3'>
                        <small className='fw-500'>Key</small>
                        <Input
                          type="text"
                          className={`form-control mt-1 mb-2`}
                          placeholder="Ex. Benefits (key)"
                          value={values.key}
                          onChange={(event) => setValues(values => ({ ...values, key: event.target.value }))}
                        />
                        {
                          values.value && values.value.length ?
                            <Col lg={12}>
                              {
                                <div className="mb-1">
                                  {
                                    values.value.map((item, index) => {
                                      return <div key={index} className='mb-2 mb-1'>
                                        <div className='position-relative'>
                                          <small className='fw-500'>Value {index + 1}</small>
                                          <textarea
                                            rows="2"
                                            placeholder='Ex. Lorem Ipsum is simply dummy text of the printing and typesetting industry. (value)'
                                            className={`
                                form-control 
                                resize-none pe-4 
                                mt-1
                                ${(isInvalidBenefits && !item.trim().length) ? "error-border" : ""}
                              `}
                                            value={item}
                                            onChange={(event) => onBenefitsChange(event, index)}
                                          />
                                          {
                                            values.value.length > 1 ?
                                              <Button
                                                style={{ position: "absolute", top: "33px", right: "5px" }}
                                                color="danger"
                                                outline
                                                type='button'
                                                className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                                onClick={() => {
                                                  removeBenefit(index)
                                                }}
                                              >
                                                <i className="bx bx-x font-size-22"></i>
                                              </Button>
                                              : null
                                          }
                                        </div>
                                      </div>
                                    })
                                  }
                                </div>
                              }
                              <div className='d-flex justify-content-end'>
                                <Button
                                  color="primary"
                                  type='button'
                                  outline
                                  className="btn-sm d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    addBenefits()
                                  }}
                                >
                                  <span className='font-size-18 me-1'>+</span>
                                  <span className='font-size-12 me-1'>Add benefit</span>
                                </Button>
                              </div>
                            </Col>
                            : null
                        }

                      </div>
                    </Tab>
                  })
                  : null
              }
            </Tabs>
          </div>
          <div className='d-flex justify-content-end '>
            <ActionButton
              type="submit"
              name="Save"
              disabled={!changes}
              spinnerClass={`w_50`}
              className="btn btn-primary btn-block px-4"
              spinnerId={buttonSpinnerId}
            />
          </div>
        </Form>
      </div>
    </div>
  )
}
