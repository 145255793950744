import axios from "axios";
import AlertService from "./alertService";
import { API_URL_KEY, IDENTITY_API_URL_KEY } from "../constants/apiKeys";
import {
	DEFAULT_LANGUAGE_KEY,
	ERROR_KEY,
	LANGUAGE_KEY,
	OBJECT_KEY,
	REFRESH_TOKEN_KEY,
	TOKEN_KEY,
	USER_KEY,
} from "../constants/mainKeys";

const identityApi = axios.create({
	baseURL: IDENTITY_API_URL_KEY,
});

const api = axios.create({
	baseURL: API_URL_KEY,
	withCredentials: true,
});

api.interceptors.request.use(async config => {
	config.headers = {
		Authorization: localStorage.getItem("token")
			? localStorage.getItem("token")
			: "",
		language: localStorage.getItem(LANGUAGE_KEY),
	};
	return config;
});

api.interceptors.response.use(
	response => {
		if (response && response.data && response.data?.status === 500) {
			AlertService.alert("error", "Something went wrong");
		}

		if (response && response.data && response.data.status !== 200) {
			const currentError = response.data;

			//if typeof error === string
			// if (currentError && currentError.message && currentError.message.includes("TR_") && (!currentError.errors || (currentError.errors && !currentError.errors.length))) {
			if (
				currentError &&
				currentError.message &&
				(!currentError.errors ||
					(currentError.errors && !currentError.errors.length))
			) {
				return Promise.reject(currentError);
			}

			//if typeof error === Array
			if (
				currentError.errors &&
				currentError.errors.length &&
				!Object.keys(currentError.errors).length
			) {
				currentError.errors.forEach(err => {
					if (err.length && err[0] && err[0].key) {
						return Promise.reject(err[0].key);
					}
				});
			}

			//if typeof error === Object
			if (currentError.errors && Object.keys(currentError.errors).length) {
				return Promise.reject(
					currentError.errors[Object.keys(currentError.errors)[0]][0]?.key,
				);
			}
		}

		if (response && response.data) {
			if (response.data.respmess === "User is not Authenticated") {
				localStorage.removeItem("user");
				localStorage.removeItem("token");
				localStorage.removeItem("refreshToken");
				window.location.href = `/`;
			}
			return !response.data.respcode
				? response.data
				: Promise.reject({
					respmess: response.data.respmess,
					respcode: response.data.respcode,
				});
		}
	},
	error => {
		if (typeof error === "object" && JSON.stringify(error).includes("404")) {
			return Promise.reject("404 Not found");
		}
		if (error.response && error.response.status === 401) {
			const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
			const user = localStorage.getItem(USER_KEY);
			const token = localStorage.getItem(TOKEN_KEY);
			if (!refreshToken || !user || !token) {
				logout();
			} else {
				getUserDataByRefreshToken(refreshToken, user);
			}
		}
		if (error.response) {
			const currentError = error.response.data;

			//if typeof error === string
			if (
				currentError &&
				currentError.message &&
				currentError.message.includes("TR_") &&
				(!currentError.errors ||
					(currentError.errors && !currentError.errors.length))
			) {
				return Promise.reject(currentError);
			}

			//if typeof error === Array
			if (currentError.errors && currentError.errors.length) {
				currentError.errors.forEach(err => {
					if (err.length && err[0] && err[0].key) {
						AlertService.alert(ERROR_KEY, err[0].key);
					}
				});
			}

			//if typeof error === Object
			if (
				typeof currentError.errors === OBJECT_KEY &&
				Object.keys(currentError.errors).length
			) {
				return Promise.reject(
					currentError.errors[Object.keys(currentError.errors)[0]][0],
				);
			}
		} else {
			return Promise.reject();
		}
	},
);

const getUserDataByRefreshToken = (refreshToken, user) => {
	const formData = new FormData();
	formData.append(`refreshToken`, refreshToken);
	ApiService.getUserDataByRefreshToken(formData)
		.then(response => {
			if (response.data && response.data.accessToken) {
				const data = { ...response.data };
				localStorage.setItem(TOKEN_KEY, data?.accessToken);
				localStorage.setItem(REFRESH_TOKEN_KEY, data?.refreshToken);
				localStorage.setItem(USER_KEY, user);
			} else {
				logout();
			}
		})
		.catch(error => getFail(error));
};

const getFail = error => {
	if (error) {
		window.location.href = `/${localStorage.getItem(LANGUAGE_KEY)
			? localStorage.getItem(LANGUAGE_KEY)
			: DEFAULT_LANGUAGE_KEY
			}`;
		localStorage.clear();
	}
};

const logout = () => {
	window.location.href = `/${localStorage.getItem(LANGUAGE_KEY)
		? localStorage.getItem(LANGUAGE_KEY)
		: DEFAULT_LANGUAGE_KEY
		}/login`;
	localStorage.clear();
};

class ApiService {
	// RefreshToken
	static getUserDataByRefreshToken(data) {
		return identityApi.post(`/identity/renewToken`, data);
	}

	static currentUser() {
		return api.get(`/user/current`);
	}

	static forgotPassword(data) {
		return api.post(`/???`, data);
	}

	static getLanguages() {
		return api.get(`/language/getall`);
	}

	static userRegistration(data) {
		return api.post(`/???`, data);
	}

	static getMemberApplications(pageNumber, pageSize) {
		return api.get(
			`application/getMemberApplications?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}

	static getAllMemberApplications() {
		return api.get(`application/getAllMemberApplications`);
	}

	static getSpecialisationApplications(pageNumber, pageSize) {
		return api.get(
			`application/getSpecialisationApplications?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}

	static getAllSpecialisationApplications() {
		return api.get(`application/getAllSpecialisationApplications`);
	}

	static getCorporateCourseApplications(pageNumber, pageSize) {
		return api.get(
			`application/getCorporateCourseApplications?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getAllCorporateCourseApplications() {
		return api.get(`application/getAllCorporateCourseApplications`);
	}

	static getShortCourseApplications(pageNumber, pageSize, filterData) {
		return api.post(
			`application/GetCourseRegistrationApplications?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			filterData
		);
	}

	static getAllShortCourseApplications() {
		return api.get(`application/GetAllCourseRegistrationApplications`);
	}

	static deleteApplication(id) {
		return api.delete(`application/Delete/${id}`);
	}

	static createLecturer(data) {
		return api.post(`/lecturer/create`, data);
	}

	static updateLecturer(data) {
		return api.put(`/lecturer/update`, data);
	}

	static getLecturerDataById(lecturerId, languageId) {
		return api.get(`/lecturer/get/${lecturerId}/${languageId}`);
	}

	static getAllLecturers(languageId) {
		return api.get(`/lecturer/GetAllForAttachment/${languageId}`);
	}

	static getLecturers(languageId, pageNumber, pageSize) {
		return api.get(
			`/lecturer/getall/${languageId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}

	static deleteLecturer(lecturerId) {
		return api.delete(`/lecturer/delete/${lecturerId}`);
	}

	static getCourses(pageNumber, pageSize, filterData) {
		return api.post(
			`/course/getall?pageNumber=${pageNumber}&pageSize=${pageSize}`,
			filterData,
		);
	}

	static getCourseById(courseId, languageId) {
		return api.get(`/course/get/${courseId}/${languageId}`);
	}

	static createCourse(data) {
		return api.post(`/course/create`, data);
	}

	static updateCourse(data) {
		return api.put(`/course/update`, data);
	}

	static deleteCourse(courseId) {
		return api.delete(`/course/delete/${courseId}`);
	}

	static updateCourseMedia(data) {
		return api.put(`/course/media`, data);
	}

	static getSectionsByPageName(languageId, pageName) {
		return api.get(`/pagecontent/getByName/${languageId}/${pageName}`);
	}

	static getPageContentById(contentId, languageId) {
		return api.get(`/pagecontent/Get/${contentId}/${languageId}`);
	}

	static createContent(data) {
		return api.post(`/pagecontent/create`, data);
	}

	static updateContent(data) {
		return api.put(`/pagecontent/update`, data);
	}

	static deletePageSection(sectionId) {
		return api.delete(`/pagecontent/deleteSection/${sectionId}`);
	}

	static deletePageContent(contentId) {
		return api.delete(`/pagecontent/delete/${contentId}`);
	}

	static getSystemTranslations(pageNumber, pageSize, languageId, searchText) {
		return api.get(
			`/translation/getall/${languageId}?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchText}`,
		);
	}

	static getAllTranslations(language) {
		return api.get(`/translation/GetAllByLang/${language}`);
	}

	static updateTranslation(data) {
		return api.put(`/translation/update`, data);
	}

	static deleteTranslation(translationId) {
		return api.delete(`/translation/delete/${translationId}`);
	}

	static createTranslation(data) {
		return api.post(`/translation/create`, data);
	}

	static setSectionOrder(languageId, dropId, dragId) {
		return api.put(
			`/pagecontent/ChangeSectionDisplayOrder/${languageId}/${dropId}/${dragId}`,
		);
	}

	static setContentOrder(languageId, dropId, dragId) {
		return api.put(
			`/pagecontent/ChangeDisplayOrder/${languageId}/${dropId}/${dragId}`,
		);
	}

	static setFacultyOrder(languageId, dropId, dragId) {
		return api.put(
			`/faculty/changeDisplayOrder/${languageId}/${dropId}/${dragId}`,
		);
	}

	static getFaculties(languageId) {
		return api.get(`/faculty/getall/${languageId}`);
	}

	static createFaculty(data) {
		return api.post(`/faculty/create`, data);
	}

	static updateFaculty(data) {
		return api.put(`/faculty/update`, data);
	}

	static deleteFaculty(facultyId) {
		return api.delete(`/faculty/delete/${facultyId}`);
	}

	static getFacultyDataById(facultyId, languageId) {
		return api.get(`/faculty/get/${facultyId}/${languageId}`);
	}

	static getCourseTypeById(courseTypeId, languageId) {
		return api.get(`/coursetype/get/${courseTypeId}/${languageId}`);
	}

	static getCourseTypes(languageId) {
		return api.get(`/coursetype/getall/${languageId}`);
	}

	static setCourseTypeOrder(languageId, dropId, dragId) {
		return api.put(
			`/coursetype/changeDisplayOrder/${languageId}/${dropId}/${dragId}`,
		);
	}

	static deleteCourseType(courseTypeId) {
		return api.delete(`/coursetype/delete/${courseTypeId}`);
	}

	static createCourseType(data) {
		return api.post(`/coursetype/create`, data);
	}

	static updateCourseType(data) {
		return api.put(`/coursetype/update`, data);
	}

	static getSectionTypes() {
		return api.get(`/pagecontent/getSectionTypes`);
	}

	static createSection(data) {
		return api.post(`/pagecontent/createsection`, data);
	}

	static getButtonTypes() {
		return api.get(`/pagecontent/getButtonTypes`);
	}

	static getDashboardData() {
		return api.get(`/pageContent/GetOverallInfo`);
	}

	static setFaq(data) {
		return api.post(`/information/SetFaq`, data);
	}

	static getFaq(languageId) {
		return api.get(`/information/GetAdminFaq/${languageId}`);
	}

	static setBenefits(data) {
		return api.post(`/information/SetBenefits`, data);
	}

	static getBenefits(languageId, courseTypeId) {
		// return api.get(`/information/GetAdminBenefits/${languageId}`);
		return api.get(`/information/GetAdminBenefitsByCourseType/${courseTypeId}/${languageId}`);
	}

	static getNews(languageId, pageNumber, pageSize) {
		return api.get(
			`/news/getall/${languageId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}

	static deleteNews(newsId) {
		return api.delete(`/news/delete/${newsId}`);
	}

	static getNewsById(newsId, languageId) {
		return api.get(`/news/get/${newsId}/${languageId}`);
	}

	static updateNews(data) {
		return api.put(`/news/update`, data);
	}

	static createNews(data) {
		return api.post(`/news/create`, data);
	}

	static getCareers(languageId, pageNumber, pageSize) {
		return api.get(
			`/career/getall/${languageId}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}

	static deleteCareer(careerId) {
		return api.delete(`/career/delete/${careerId}`);
	}

	static createCareer(data) {
		return api.post(`/career/create`, data);
	}

	static updateCareer(data) {
		return api.put(`/career/update`, data);
	}

	static getCareerById(careerId, languageId) {
		return api.get(`/career/get/${careerId}/${languageId}`);
	}
}

export default ApiService;
