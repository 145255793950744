import React, { useCallback, useState, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { ERROR_KEY, SUCCESS_KEY } from '../../../constants/mainKeys'
import { addPageSpinner, removePageSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../services/alertService'
import { useDispatch } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { Table } from "react-bootstrap"
import FormLanguage from '../../../components/FormLanguage/FormLanguage'
import ApiService from '../../../services/apiService'
import uuid from 'react-uuid'
import NoData from '../../../components/NoData/NoData'
import ShowMoreText from "react-show-more-text"

const Careers = (props) => {

  const pageSize = 10;
  const { history } = props;
  const dispatch = useDispatch();
  const { language, formLanguage } = useSelector(state => state.Languages);
  const [careers, setCareers] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);

  useEffect(() => {
    if (formLanguage) {
      getCareers(1);
    }
    return () => {
      setCareers([]);
    }
  }, [formLanguage])

  const handlePageClick = (event) => {
    getCareers(event.selected + 1);
    setActivePageNumber(event.selected);
  };

  const getCareers = (currentPage) => {
    if (!formLanguage || !currentPage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getCareers(formLanguage.id, currentPage, pageSize).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setCareers(response.data);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteCareer = (careerId) => {
    if (!careerId) { return false; }
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId)
      ApiService.deleteCareer(careerId).then(response => {
        setCareers(careers.filter(item => item.id !== +careerId));
        AlertService.alert(SUCCESS_KEY, "Data saved");
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="Careers"
        />
        <FormLanguage />
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col sm={12} className='d-flex justify-content-end'>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                history.push(`/${language}/career-form`)
              }}
            >
              Create a career
            </Button>
          </Col>
        </Row>
        {
          careers && careers.length ?
            <Card>
              <CardBody>
                <Table responsive className="table mb-0 table-bordered">
                  <thead>
                    <tr>
                      <th className='no-wrap'>Title</th>
                      <th className='no-wrap'>Level</th>
                      <th className='no-wrap'>Schedule</th>
                      <th className='no-wrap'>Application deadline</th>
                      <th className='no-wrap'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {careers.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <span className='word-break-break-word max-line-2' title={item.title}> {item.title}</span>
                        </td>
                        <td>
                          <span className='word-break-break-word max-line-2' title={item.title}> {item?.level?.value}</span>
                        </td>
                        <td>
                          <span className='word-break-break-word max-line-2' title={item.title}> {item?.schedule?.value}</span>
                        </td>
                        <td>
                          <span className='word-break-break-word max-line-2' title={item.title}> {item?.applicationDeadline?.value}</span>
                        </td>

                        <td className='d-flex border-none'>
                          <Button
                            color="success"
                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                            title='Edit'
                            outline
                            onClick={() => {
                              props.history.push(`/${language}/career-form/${item.id}`)
                            }}
                          >
                            <i className='mdi mdi-pencil font-size-18'></i>
                          </Button>
                          <Button
                            color="danger"
                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                            title='Delete'
                            outline
                            onClick={() => deleteCareer(item.id)}
                          >
                            <i className='mdi mdi-delete font-size-18'></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {
                  pagination && pagination.TotalPages && pagination.TotalPages > 1 ?
                    <div className=' mt-4 d-flex justify-content-end w-100'>
                      <ReactPaginate
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pagination.TotalPages}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination flex-wrap"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        forcePage={activePageNumber}
                      />
                    </div>
                    : null
                }
              </CardBody>
            </Card>
            : <div>
              <NoData />
              <div className='my-4 d-flex justify-content-center flex-wrap'>
                <b className='d-flex text-center text-wrap'>
                  You don't have created Career yet
                </b>
                <b>
                  <Link to={`/${language}/career-form`} className="ms-2">
                    <span className='underline'>Create a Career</span>
                    <i className='bx bx-link-external ms-1 d-inline'></i>
                  </Link>
                </b>
              </div>
            </div>
        }

      </div>
    </div>
  )
}

export default withRouter(Careers)