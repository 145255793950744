import React, { useCallback, useState, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { ERROR_KEY, SUCCESS_KEY, fieldValidation } from '../../../constants/mainKeys'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../services/alertService'
import { useDispatch } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
  Form,
} from "reactstrap"
import uuid from 'react-uuid'
import ActionButton from '../../../components/Buttons/ActionButton'
import { useSelector } from 'react-redux'
import FormLanguage from '../../../components/FormLanguage/FormLanguage'
import ApiService from '../../../services/apiService'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import MainService from '../../../services/mainService'
import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor'

const buttonSpinnerId = uuid();

const CareerForm = (props) => {

  const dispatch = useDispatch();
  const { language, formLanguage } = useSelector(state => state.Languages);
  const { careerId } = useParams();
  const { history } = props;
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [changes, setChanges] = useState(false);

  const [values, setValues] = useState({
    title: "",
    applyLink: "",
    applicationDeadline: {
      key: "Application deadline",
      value: ""
    },
    level: {
      key: "Level",
      value: ""
    },
    schedule: {
      key: "Schedule",
      value: ""
    },
    description: null,
  });

  useEffect(() => {

    if (careerId) {
      getCareerById();
    } else {
      setValues((values) => ({
        ...values,
        description: ""
      }))
    }
  }, [formLanguage, careerId])

  const getCareerById = () => {
    if (!formLanguage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getCareerById(+careerId, formLanguage.id).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (!data.description) {
          data.description = "";
        }
        setValues(data);
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const onChange = (event, field, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false);
  }

  const onChangeKeyValue = (event, fieldPath, maxLength = Infinity) => {
    const newValue = event.target.value;
    if (maxLength && newValue.length >= maxLength) return;
    const newValues = { ...values };
    let targetObject = newValues;
    const fieldPathArray = fieldPath.split('.');
    for (let i = 0; i < fieldPathArray.length - 1; i++) {
      targetObject = targetObject[fieldPathArray[i]];
    }
    targetObject[fieldPathArray[fieldPathArray.length - 1]] = newValue;
    setIsInvalidSubmit(false);
    setValues(newValues);
  };

  const onEditorChange = (event, fieldPath, maxLength = Infinity) => {
    const newValue = event.editor.getData();
    if (maxLength && newValue.length >= maxLength) return;
    const newValues = { ...values };
    newValues[fieldPath] = newValue
    setIsInvalidSubmit(false);
    setValues(newValues);
    setChanges(true);
  };

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!formLanguage) { return false; }
    if (
      !values.title.trim().length
    ) {
      setIsInvalidSubmit(true);
      MainService.scrollToInvalidFieldPosition();
      return false;
    }
    if (careerId) values.id = +careerId;
    values.languageId = formLanguage.id;
    setButtonSpinner(buttonSpinnerId);
    (careerId ? ApiService.updateCareer(values) : ApiService.createCareer(values)).then(response => {
      AlertService.alert(SUCCESS_KEY, "Data saved")
      extractButtonSpinner(buttonSpinnerId);
      history.push(`/${language}/careers`)
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="Career Form"
        />
        <FormLanguage />
        <hr className='mt-0' />
        <Card>
          <CardBody>
            <Form
              onChange={() => setChanges(true)}
              onSubmit={onSubmit}
            >
              <Row>
                <Col md={6}>
                  <Label >Title*</Label>
                  <Input
                    type="text"
                    placeholder='Ex. Graphic Design'
                    className={`form-control  ${(isInvalidSubmit && !values.title.trim().length) ? "error-border" : ""}`}
                    value={values.title}
                    onChange={(event) => onChange(event, "title", fieldValidation.length_255)}
                  />
                </Col>
                <Col md={6}>
                  <Label >Apply link</Label>
                  <Input
                    type="text"
                    placeholder='Ex. https://docs.google.com/document...'
                    className={`form-control`}
                    value={values.applyLink}
                    onChange={(event) => onChange(event, "applyLink", fieldValidation.length_255)}
                  />
                </Col>

                <Col md={6} className='px-4 mt-3'>
                  <Row className='border rounded pb-3 pt-2'>
                    <Col sm={12}>
                      <Label>Level</Label>
                    </Col>
                    <Col lg={6}>
                      <Input
                        type="text"
                        className={`form-control mt-1`}
                        placeholder="Ex. Level (key)"
                        value={values.level.key}
                        onChange={(event) => onChangeKeyValue(event, "level.key", fieldValidation.length_100)}
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        type="text"
                        className={`form-control mt-1`}
                        placeholder="Ex. SENIOR (value)"
                        value={values.level.value}
                        onChange={(event) => onChangeKeyValue(event, "level.value", fieldValidation.length_100)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className='px-4 mt-3'>
                  <Row className='border rounded pb-3 pt-2'>
                    <Col sm={12}>
                      <Label>Schedule</Label>
                    </Col>
                    <Col lg={6}>
                      <Input
                        type="text"
                        className={`form-control mt-1`}
                        placeholder="Ex. Schedule (key)"
                        value={values.schedule.key}
                        onChange={(event) => onChangeKeyValue(event, "schedule.key", fieldValidation.length_100)}
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        type="text"
                        className={`form-control mt-1`}
                        placeholder="Ex. FULL TIME (value)"
                        value={values.schedule.value}
                        onChange={(event) => onChangeKeyValue(event, "schedule.value", fieldValidation.length_100)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className='px-4 mt-3'>
                  <Row className='border rounded pb-3 pt-2'>
                    <Col sm={12}>
                      <Label>Application Deadline</Label>
                    </Col>
                    <Col lg={6}>
                      <Input
                        type="text"
                        className={`form-control mt-1`}
                        placeholder="Ex. Application Deadline (key)"
                        value={values.applicationDeadline.key}
                        onChange={(event) => onChangeKeyValue(event, "applicationDeadline.key", fieldValidation.length_100)}
                      />
                    </Col>
                    <Col lg={6}>
                      <Input
                        type="text"
                        className={`form-control mt-1`}
                        placeholder="Ex. 24.01.2024 (value)"
                        value={values.applicationDeadline.value}
                        onChange={(event) => onChangeKeyValue(event, "applicationDeadline.value", fieldValidation.length_100)}
                      />
                    </Col>
                  </Row>
                </Col>



                <Col lg={12} className="mt-3">
                  <Label>Description</Label>
                  {
                    typeof values.description === "string" ?
                      <RichTextEditor
                        value={values.description}
                        blockClassName="custom-rich-text-editor small-rich-text-editor mt-1 rounded overflow-hidden"
                        lableClassName="fw-500 font-size-14"
                        onEditorChange={event => onEditorChange(event, "description")}
                      />
                      : null
                  }
                </Col>
              </Row>
              <div className='d-flex justify-content-end mt-2'>
                <ActionButton
                  type="submit"
                  name="Save"
                  disabled={!changes}
                  spinnerClass={`w_50`}
                  className="btn btn-primary btn-block px-4"
                  spinnerId={buttonSpinnerId}
                />
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div >
  )
}

export default CareerForm
