import { confirmAlert } from 'react-confirm-alert'
import closeSvg from "../assets/icons/alert-icons/close-white.svg"
import infoSvg from "../assets/icons/alert-icons/info.svg"
import successSvg from "../assets/icons/alert-icons/check.svg"
import warningSvg from "../assets/icons/alert-icons/warning.svg"
import errorSvg from "../assets/icons/alert-icons/remove.svg"

class AlertService {

  static alert = (type, error,) => {
    const respMessage = typeof error === 'object' ? error.message || error.respmess : error;
    if (!respMessage || (respMessage && respMessage.includes("TR_"))) { return; }
    var alertBlockWithCloseButton = document.createElement("div");
    alertBlockWithCloseButton.className = "cx-alert-block";
    var containerElement = document.createElement("div");
    containerElement.className = "mx-3 alert-block-wrapper";
    var icon = document.createElement("img");
    var closeIcon = document.createElement("img")
    closeIcon.className = 'close-icon';
    closeIcon.src = closeSvg;
    var alertMessage = document.createElement("p");
    alertMessage.className = "alertText";
    closeIcon.onclick = () => {
      AlertService.removeCurrentAlert(alertBlockWithCloseButton);
    }
    switch (type) {
      case 'info':
        alertBlockWithCloseButton.className = "cx-alert-block";
        icon.src = infoSvg;
        break;
      case 'success':
        alertBlockWithCloseButton.className = "cx-alert-block";
        icon.src = successSvg;
        break;
      case 'warning':
        alertBlockWithCloseButton.className = "cx-alert-block";
        icon.src = warningSvg;
        break;
      case 'error':
        alertBlockWithCloseButton.className = "cx-alert-block";
        icon.src = errorSvg;
        break;
      default:
        break
    }
    alertMessage.innerHTML = respMessage;
    containerElement.append(icon);
    containerElement.append(alertMessage);
    containerElement.append(closeIcon);
    alertBlockWithCloseButton.append(containerElement);
    document.getElementById("cx-alert-container") && document.getElementById("cx-alert-container").append(alertBlockWithCloseButton);

    setTimeout(() => {
      AlertService.removeCurrentAlert(alertBlockWithCloseButton)
    }, 5000);

  }

  static removeCurrentAlert = (element) => {
    element && element.remove();
  }

  static checkMessageType = (respcode) => {
    switch (respcode) {
      case 0:
        return 'success'
      case 1:
        return 'error'
      case 2:
        return 'warning'
      default:
        break;
    }
  }

  static alertConfirm = (title, message, yes, no) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              {title ? <strong className='font-size-18'>{title}</strong> : null}
              {message ? <p className='mt-4'>{message}</p> : null}
              <hr />
              <a href="#" className="btn btn-lg btn-success capitailze" onClick={() => { resolve(); onClose(); }}>{yes}</a>
              <a href="#" className="btn btn-lg btn-danger capitailze" onClick={() => { reject(); onClose(); }}>{no}</a>
            </div>
          );
        }
      })
    })
  }

}


export default AlertService;