import React, { useState, useEffect } from 'react'
import { Accordion } from 'react-bootstrap'
import { useLocation, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


const SidebarContent = (props) => {

  const dispatch = useDispatch();
  const { pathname } = useLocation()

  const [activeAccordionKey, setActiveAccordionKey] = useState(null);
  const { language } = useSelector(state => state.Languages);

  const handleAccordionToggle = (accordionKey) => {
    setActiveAccordionKey(accordionKey === activeAccordionKey ? null : accordionKey);
  };

  const setActiveClass = (name) => {
    if (pathname.includes(name)) return "mm-active";
    else return "";
  }

  const removeBodyClasses = () => {
    document.body.classList = '';
  }

  return (
    <section className="scroll-section left-modal-container" id="leftModal">
      <div className="mb-5 border-none custom-accordion-left-menu">

        <Accordion className='left-menu mt-5 pb-3' alwaysOpen defaultActiveKey={[0]}>

          <Accordion.Item title="Dashboard">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("dashboard")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/dashboard`);
              }}
            >
              <i className='bx bxs-dashboard'></i>
              <span>Dashboard</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Users">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("user")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/users`);
              }}
            >
              <i className='bx bx-user'></i>
              <span>Users</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Faculties">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("facult")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/faculties`);
              }}
            >
              <i className='bx bx-briefcase-alt-2'></i>
              <span>Faculties</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Course types">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("course-type")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/course-types`);
              }}
            >
              <i className='bx bx-hive'></i>
              <span>Course types</span>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item title="Courses">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("courses")} ${setActiveClass("course-form")} ${setActiveClass("course-media")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/courses`);
              }}
            >
              <i className='bx bx-notepad' ></i>
              <span>Courses</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="News">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("news")} ${setActiveClass("news-form")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/news`);
              }}
            >
              <i className='bx bx-news' ></i>
              <span>News</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="News">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("career")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/careers`);
              }}
            >
              <i className='bx bx-chart'></i>
              <span>Careers</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Pages">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("page")} ${setActiveClass("content")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/pages`);
              }}
            >
              <i className='bx bx-book-open'></i>
              <span>Pages</span>
            </Accordion.Header>
          </Accordion.Item>
          <Accordion.Item title="Translations">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("translation")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/translations`);
              }}
            >
              <i className='bx bx-world'></i>
              <span>Translations</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="FAQ">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("faq")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/faq`);
              }}
            >
              <i className='bx bx-question-mark'></i>
              <span>FAQ</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Benefits">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("benefits")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/benefits`);
              }}
            >
              <i className='bx bx-spa'></i>
              <span>Benefits</span>
            </Accordion.Header>
          </Accordion.Item>

          <li className='menu-title pb-0' style={{ listStyle: "none" }}>Applications</li>

          <Accordion.Item title="Member Applications">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("member-applications")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/member-applications`);
              }}
            >
              <i className='bx bx-user-pin'></i>
              <span>Member applications</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Specialization Applications">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("specialization-applications")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/specialization-applications`);
              }}
            >
              <i className='bx bx-user-pin'></i>
              <span>Specialization applications</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Corporate Course Applications">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("corporate-course-applications")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/corporate-course-applications`);
              }}
            >
              <i className='bx bx-user-pin'></i>
              <span>Corporate course applications</span>
            </Accordion.Header>
          </Accordion.Item>

          <Accordion.Item title="Short Course Applications">
            <Accordion.Header
              className={`without-arrow ${setActiveClass("short-course-applications")}`}
              onClick={() => {
                removeBodyClasses();
                props.history.push(`/${language}/short-course-applications`);
              }}
            >
              <i className='bx bx-user-pin'></i>
              <span>Short course applications</span>
            </Accordion.Header>
          </Accordion.Item>


        </Accordion>

      </div>
    </section>
  )
}

export default withRouter((SidebarContent))