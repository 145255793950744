import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Label } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from "react-bootstrap"
import AlertService from '../../../services/alertService'
import ApiService from '../../../services/apiService'
import { useCallback } from 'react'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/spinner/actions'
import { ERROR_KEY, PAGES_KEY, SPINNER_COLOR, SUCCESS_KEY } from '../../../constants/mainKeys'
import uuid from 'react-uuid'
import NoData from '../../../components/NoData/NoData'
import FormLanguage from '../../../components/FormLanguage/FormLanguage'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import Parser from 'html-react-parser'
import ActionButton from '../../../components/Buttons/ActionButton'
import Accordion from "react-bootstrap/Accordion"
import PuffLoader from "react-spinners/PuffLoader"
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import ShowMoreText from "react-show-more-text"


const buttonSpinnerId = uuid();

export default function Pages(props) {

  const dispatch = useDispatch();
  const { language, formLanguage } = useSelector(state => state.Languages);
  const [dragSectionId, setDragSectionId] = useState(null);
  const [dragContentId, setDragContentId] = useState(null);
  const [pageSections, setPageSections] = useState([]);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [sectionTypes, setSectionTypes] = useState([]);
  const [isShowSectionForm, setIsShowSectionForm] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [sectionActiveKey, setSectionActiveKey] = useState("");
  const [accordionSpinnerName, setAccordionSpinnerName] = useState(true);
  const [values, setValues] = useState({
    pageName: "",
    sectionType: null
  })

  useEffect(() => {
    getSectionTypes();
  }, [formLanguage])

  const getSectionTypes = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getSectionTypes().then(response => {
      if (response && response.data) {
        setSectionTypes(response.data)
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const onSelectOptionChangeType = (item, name) => {
    if (!item) { return false; }
    setValues(() => ({
      ...values,
      [name]: item.value
    }))
    setIsInvalidSubmit(false);
  }

  const onSelectOptionChange = (item, name) => {
    if (!item) { return false; }
    setValues(() => ({
      ...values,
      [name]: item.label
    }))
    setIsInvalidSubmit(false);
  }

  const getSectionByPageName = (pageName) => {
    if (!formLanguage) { return false; }
    if (pageName === activeKey) {
      setActiveKey("");
      return false;
    }
    setAccordionSpinnerName(pageName);
    setActiveKey(pageName);
    ApiService.getSectionsByPageName(formLanguage.id, pageName).then(response => {
      if (response && response.data) {
        // setPageSections([...pageSections, response.data]);
        setPageSections(response.data);
      } else {
        setPageSections([]);
      }
      setAccordionSpinnerName(null);
    }).catch(error => getFail(error))
  }

  const deletePageSection = (sectionId) => {
    if (!sectionId) { return false; }
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId)
      ApiService.deletePageSection(sectionId).then(response => {
        setPageSections(pageSections.filter(item => item.id !== +sectionId));
        AlertService.alert(SUCCESS_KEY, "Data saved");
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const deletePageContent = (contentId, pageIndex, sectonIndex) => {
    if (!contentId) { return false; }
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId)
      ApiService.deletePageContent(contentId).then(response => {
        const newPageSections = [...pageSections];
        newPageSections[pageIndex].pageContents.splice(sectonIndex, 1);
        setPageSections(newPageSections);
        AlertService.alert(SUCCESS_KEY, "Data saved");
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const dragContent = (contentId) => {
    contentId && setDragContentId(contentId);
  }

  const drag = (sectionId) => {
    sectionId && setDragSectionId(sectionId);
  }

  const drop = (dropSectionId, pageName) => {
    if (!dragSectionId || !formLanguage || dropSectionId === dragSectionId) { return false; }
    setAccordionSpinnerName(pageName);
    ApiService.setSectionOrder(formLanguage.id, dropSectionId, dragSectionId).then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setPageSections(data);
      }
      setDragSectionId(null);
      setAccordionSpinnerName(null);
      AlertService.alert(SUCCESS_KEY, "Data saved");
    }).catch(error => getFail(error));
  }

  const dropContent = (dropContentId, pageName) => {
    if (!dragContentId || !formLanguage || dropContentId === dragContentId) { return false; }
    setAccordionSpinnerName(pageName);
    ApiService.setContentOrder(formLanguage.id, dropContentId, dragContentId).then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setPageSections(data);
      }
      setDragSectionId(null);
      setAccordionSpinnerName(null);
      AlertService.alert(SUCCESS_KEY, "Data saved");
    }).catch(error => getFail(error));
  }

  const addSection = () => {
    if (!formLanguage) { return false };
    if (!values.pageName || !values.sectionType) {
      setIsInvalidSubmit(true);
      return false;
    }
    values.languageId = formLanguage.id;
    setButtonSpinner(buttonSpinnerId);
    ApiService.createSection(values).then(response => {
      if (response && response.data) {
        setPageSections([...pageSections, response.data])
      }
      AlertService.alert(SUCCESS_KEY, "Data saved");
      extractButtonSpinner(buttonSpinnerId);
      cancel();
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const cancel = () => {
    setIsShowSectionForm(false);
    setAccordionSpinnerName(null);
    setActiveKey("");
    setValues({
      pageName: "",
      sectionType: null
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
    setAccordionSpinnerName(null);
    cancel();
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="Pages sections"
        />
        <FormLanguage />
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col>
            <h4 className="mt-0 font-size-16">
              Pages and Sections Management
            </h4>
            <ShowMoreText
              lines={2}
              more="Read more"
              less="Less"
              className="mb-3 mt-0 more-less-text"
              anchorClass="underline cursor-pointer pt-0"
              expanded={false}
              truncatedEndingComponent={<span>...<br /></span>}
            >
              <div className='form-info-text'>
                <p className='mb-1'>Welcome to the Pages and Sections Management page, where you can create and organize content for different pages. This page provides you with the tools to create, edit, and delete page sections and their associated content. Here's an overview of the page's features</p>
                <p className='mb-1'> <span className='fw-500'>How to Create a Page Section and Section Contents ? </span>
                  To get started, you can create page sections and add content to them. Each page section can have a different purpose, and you can customize their appearance and content.
                  This is a powerful way to structure your website or application's content effectively.
                </p>
                <p className='mb-1'> <span className='fw-500'>Page and Section Selection: </span>
                  Begin by choosing a specific page where you want to create or manage sections. Click on the page's name to expand or collapse the sections associated with it.
                </p>
                <p className='mb-1'> <span className='fw-500'>Section Management: </span>
                  For each page, you can add, edit, or delete sections.
                  To add a section, click the "+ Add section for this page" button, provide details such as section type, and customize its appearance.
                  To edit or delete a section, use the respective buttons provided.
                </p>
                <p className='mb-1'> <span className='fw-500'>Section Types and Image Previews: </span>
                  When adding a new section, you can select a section type from a predefined list. Each section type may have a different purpose and visual style.
                  Image previews of section types are available to help you choose the right one.
                </p>
                <p className='mb-1'> <span className='fw-500'>Content Management: </span>
                  For each section, you can add content. Content can include text, images, videos, and background colors.
                  Use the "+ Add content for this section" button to create and customize content for the selected section.
                </p>
                <p className='mb-1'> <span className='fw-500'>Content Display Ordering: </span>
                  You can easily reorder sections and content by using the drag-and-drop functionality. This allows you to arrange your content as per your preferences.
                </p>
                <p className='mb-1'> <span className='fw-500'>Language: </span>
                  You can choose the language in which you want to create sections and content using the "Form Languages" component at the top. This ensures your content is accessible to your audience.
                </p>

              </div>
            </ShowMoreText>
          </Col>

          {
            isShowSectionForm ?
              <Col sm={12} className='mb-3'>
                <Row>
                  <Col md={6}>
                    <Label className='fw-500 font-size-14'>Choose the page*</Label>
                    <ReactSelectOption
                      value={values.pageName}
                      selectedValue={{ label: values.pageName, value: values.pageName }}
                      className={`rounded`}
                      isDisabled={true}
                      isInvalidField={isInvalidSubmit && !values.pageName}
                      items={PAGES_KEY.map((data, index) => ({ label: data, value: index }))}
                      onChange={item => onSelectOptionChange(item, "pageName")}
                    />
                  </Col>
                  {
                    sectionTypes && sectionTypes.length ?
                      <Col md={6}>
                        <div>
                          <Label className='fw-500 font-size-14'>Choose the section type*</Label>
                          <ReactSelectOption
                            value={values.type}
                            className={`rounded`}
                            isInvalidField={isInvalidSubmit && !values.sectionType}
                            selectedValue={(() => {
                              const selectedItem = { ...sectionTypes.find(data => data.type === values.type) };
                              if (Object.keys(selectedItem).length) {
                                selectedItem.value = selectedItem.id;
                                selectedItem.label = selectedItem.name;
                                return selectedItem;
                              }
                            })()}
                            items={sectionTypes.map(data => ({ ...data, label: data.name, value: data.type }))}
                            onChange={item => onSelectOptionChangeType(item, "sectionType")}
                          />
                        </div>
                        {
                          values.sectionType ?
                            sectionTypes.map((item, index) => {
                              if (item.type === values.sectionType) {
                                return <div
                                  key={index}
                                  className='img-thumbnail mt-1'
                                  style={{
                                    backgroundImage: `url(${item.imagePath})`,
                                    height: "200px",
                                    width: "100%",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center"
                                  }}
                                />
                              }
                            })
                            : null
                        }
                      </Col>
                      : null
                  }
                </Row>
              </Col>
              : null
          }

          <Col sm={12} className='d-flex justify-content-end'>
            {
              isShowSectionForm ?
                <div className='d-flex'>
                  <Button
                    color="secondary"
                    className="me-2"
                    title=''
                    outline
                    onClick={cancel}
                  >
                    Cancel
                  </Button>
                  <ActionButton
                    type="button"
                    name="Save"
                    spinnerClass={`w_50`}
                    className="btn btn-primary btn-block px-4"
                    spinnerId={buttonSpinnerId}
                    onClick={addSection}
                  />
                </div>
                : null
            }
          </Col>
        </Row>
        <hr />

        <h4 className="mt-0 font-size-16 mb-3">
          Pages
        </h4>
        <Accordion accessKey={activeKey} className='mb-5'>
          {
            PAGES_KEY.map((item, index) => {
              return <Accordion.Item
                key={index}
                eventKey={item}
                className='mt-1'
                onClick={() => {
                  getSectionByPageName(item)
                }}>
                <Accordion.Header>{item}</Accordion.Header>
                <Accordion.Body
                  onClick={(event) => event && event.stopPropagation()}
                  className='position-relative'
                  style={{ minHeight: "150px" }}
                >
                  {
                    accordionSpinnerName === item ?
                      <div className='position-absolute absolute-center d-flex justify-content-center align-items-center h-100 w-100 bg-white'>
                        <PuffLoader size={60} color={SPINNER_COLOR} />
                      </div>
                      : pageSections && pageSections.length && sectionTypes && sectionTypes.length && !accordionSpinnerName ?
                        <Accordion accessKey={sectionActiveKey}>
                          <h4 className="mt-0 font-size-16 mb-3">
                            Sections
                          </h4>
                          {
                            pageSections.map((pageSection, pageIndex) => {
                              return <div
                                key={pageIndex}
                                className="d-flex align-items-center cursor-pointer justify-content-center px-1"
                                draggable={true}
                                onDrop={() => drop(pageSection.id, item)}
                                onDragOver={event => event.preventDefault()}
                                onDragStart={() => drag(pageSection.id)}
                              >
                                <Accordion.Item
                                  eventKey={pageIndex}
                                  className="w-100 mt-1"
                                  onClick={(event) => {
                                    event && event.stopPropagation();
                                  }}
                                >
                                  <Accordion.Header>
                                    <b className='me-2'>{pageSection.displayOrder}</b>
                                    {
                                      sectionTypes.map((sectionType, sectionIndex) => {
                                        if (sectionType.type === pageSection.sectionType) {
                                          return <span key={sectionIndex}>{sectionType.name}</span>
                                        }
                                      })
                                    }
                                  </Accordion.Header>
                                  <Accordion.Body
                                    className='position-relative cursor-default'
                                    draggable={false}
                                  >
                                    {
                                      pageSection.pageContents && pageSection.pageContents.length ?
                                        <div>
                                          <Table responsive className="table mb-0 table-bordered">
                                            <thead>
                                              <tr>
                                                <th className='no-wrap'>Order number</th>
                                                <th className='no-wrap'>Title</th>
                                                <th className='no-wrap'>Description</th>
                                                <th className='no-wrap'>Image(s)</th>
                                                <th className='no-wrap'>Video</th>
                                                <th className='no-wrap'>Background color</th>
                                                <th className='no-wrap'>Actions</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {pageSection.pageContents.map((_item, _index) => (
                                                <tr key={_index}
                                                  className='cursor-pointer'
                                                  draggable={true}
                                                  onDrop={() => dropContent(_item.id)}
                                                  onDragOver={event => {
                                                    event.preventDefault();
                                                  }}
                                                  onDragStart={() => dragContent(_item.id)}
                                                >
                                                  <td><b className='font-size-20'>{_item.displayOrder}</b></td>
                                                  <td>
                                                    <span className='max-line-2'>{_item.title && Parser(_item.title)}</span>
                                                  </td>
                                                  <td>
                                                    <span className='max-line-3 word-break-break-word'>{_item.description && Parser(_item.description)}</span>
                                                  </td>
                                                  <td>
                                                    {
                                                      _item.imagePaths && _item.imagePaths.length ?
                                                        <img
                                                          src={_item.imagePaths[0]}
                                                          alt="/"
                                                          className='rounded'
                                                          style={{
                                                            height: "auto",
                                                            maxHeight: "100px",
                                                            maxWidth: "200px"
                                                          }}
                                                        />
                                                        : <small><i>There is/are no uploaded image(s) for this content.</i></small>
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      _item.videoPath ?
                                                        <video
                                                          src={_item.videoPath}
                                                          alt="/"
                                                          style={{
                                                            height: "auto",
                                                            maxHeight: "100px",
                                                            maxWidth: "200px"
                                                          }}
                                                          className='rounded'
                                                          controls
                                                        />
                                                        : <small><i>No video</i></small>
                                                    }
                                                  </td>
                                                  <td>
                                                    {
                                                      _item.color ?
                                                        <Auxiliary>
                                                          <div
                                                            className='border rounded overflow-hidden me-1 p-1'
                                                            style={{ height: 30, width: 60, backgroundColor: _item.color }}
                                                          />
                                                          <small><i>{_item.color}</i></small>
                                                        </Auxiliary>
                                                        : null
                                                    }

                                                  </td>
                                                  <td className='d-flex border-none'>
                                                    <Button
                                                      color="success"
                                                      className="m-1 btn-sm d-flex justify-content-center align-items-center"
                                                      title='Edit'
                                                      outline
                                                      onClick={() => {
                                                        props.history.push(`/${language}/content-form/${item}/${pageSection.id}/${_item.id}`)
                                                      }}
                                                    >
                                                      <i className='mdi mdi-pencil font-size-18'></i>
                                                    </Button>
                                                    <Button
                                                      color="danger"
                                                      className="m-1 btn-sm d-flex justify-content-center align-items-center"
                                                      title='Delete'
                                                      outline
                                                      onClick={() => deletePageContent(_item.id, pageIndex, _index)}
                                                    >
                                                      <i className='mdi mdi-delete font-size-18'></i>
                                                    </Button>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </Table>

                                        </div>
                                        :
                                        <div>
                                          <NoData height={200} />
                                          <div className='my-4 d-flex justify-content-center flex-wrap'>
                                            <b className='d-flex text-center text-wrap'>
                                              You don't have created content for this section yet
                                            </b>
                                          </div>
                                        </div>
                                    }
                                    <div className='d-flex justify-content-end mt-2'>
                                      <Button
                                        color="primary"
                                        outline
                                        size='sm'
                                        onClick={() => {
                                          props.history.push(`/${language}/content-form/${item}/${pageSection.id}`)
                                        }}
                                      >
                                        + Add content for this section
                                        <i className='bx bx-link-external ms-1 d-inline'></i>
                                      </Button>
                                    </div>

                                  </Accordion.Body>
                                </Accordion.Item>
                                <Button
                                  color="danger"
                                  outline
                                  type='button'
                                  className="btn-sm p-2 ms-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    deletePageSection(pageSection.id)
                                  }}
                                >
                                  <i className="bx bx-trash font-size-22"></i>
                                </Button>
                              </div>

                            })
                          }
                        </Accordion>
                        : <div>
                          <NoData height={200} />
                          <div className='my-4 d-flex justify-content-center flex-wrap'>
                            <b className='d-flex text-center text-wrap'>
                              You don't have created sections yet for this page
                            </b>
                          </div>
                        </div>
                  }
                  <div className='d-flex justify-content-end mt-2'>
                    <Button
                      color="primary"
                      outline
                      size='sm'
                      className='mt-2'
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        setValues(() => ({
                          ...values,
                          pageName: item
                        }))
                        setIsShowSectionForm(true);
                      }}
                    >
                      + Add section for this page
                    </Button>
                  </div>

                </Accordion.Body>
              </Accordion.Item>
            })
          }


        </Accordion>



      </div>
    </div >
  )
}
