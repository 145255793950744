import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { Table } from "react-bootstrap"
import AlertService from '../../../services/alertService'
import { ERROR_KEY, SUCCESS_KEY } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Row, Col, Button, Card, CardBody } from 'reactstrap'
import NoData from '../../../components/NoData/NoData'
import FormLanguage from '../../../components/FormLanguage/FormLanguage'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import { Link } from "react-router-dom"
import ShowMoreText from "react-show-more-text"

export default function Faculties(props) {

  const dispatch = useDispatch()
  const { history } = props;
  const { language, formLanguage } = useSelector(state => state.Languages);
  const [faculties, setFaculties] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [dragItemId, setDragItemId] = useState(null);

  useEffect(() => {
    getFaculties();
  }, [formLanguage])

  const getFaculties = () => {
    if (!formLanguage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getFaculties(formLanguage.id).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setFaculties(response.data);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteFaculty = (facultyId) => {
    if (!facultyId) { return false; }
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId)
      ApiService.deleteFaculty(facultyId).then(response => {
        setFaculties(faculties.filter(item => item.id !== +facultyId));
        AlertService.alert(SUCCESS_KEY, "Data saved");
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const drag = (sectionId) => {
    sectionId && setDragItemId(sectionId)
  }

  const drop = (dropItemId) => {
    if (!dragItemId || !formLanguage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.setFacultyOrder(formLanguage.id, dropItemId, dragItemId).then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setFaculties(data);
      }
      setDragItemId(null);
      extractPageSpinner(spinnerId);
      AlertService.alert(SUCCESS_KEY, "Data saved");
    }).catch(error => getFail(error, spinnerId));
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
    setDragItemId(null);
  }


  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="Faculties"
        />
        <FormLanguage />
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col>
            <h4 className="mt-0 font-size-16">
              Welcome to faculties page
            </h4>
            <ShowMoreText
              lines={2}
              more="Read more"
              less="Less"
              className="mb-3 mt-0 more-less-text"
              anchorClass="underline cursor-pointer pt-0"
              expanded={false}
              truncatedEndingComponent={<span>...<br /></span>}
            >
              <div className='form-info-text'>
                <p className='mb-1'>Here you can manage and organize your educational faculties. Faculties represent different academic departments or divisions within your educational institution. This page provides you with the tools to create, edit, and arrange faculties as needed. Here's a brief overview of the page's features.</p>
                <p className='mb-1'> <span className='fw-500'>Create a Faculty: </span>
                  You can create a new faculty by clicking the "Create a faculty" button.
                  This allows you to add a new department or academic division to your institution.
                </p>
                <p className='mb-1'> <span className='fw-500'>Faculty List: </span>
                  The list displays the existing faculties, including their order number, images, logos, titles, descriptions, and theme colors.
                  You can see the order number that determines the position of each faculty.
                  Images and logos associated with each faculty are displayed. If no image or logo is provided, it is indicated.
                  The title and description of each faculty give you a quick overview of its purpose.
                  The theme color of each faculty is displayed for easy identification.
                </p>
                <p className='mb-1'> <span className='fw-500'>Edit and Delete Faculty: </span>
                  You can edit a faculty's details by clicking the "Edit" button (pencil icon).
                  To delete a faculty, click the "Delete" button (trash can icon).
                  Deleting a faculty will permanently remove it, so be cautious.
                </p>
                <p className='mb-1'> <span className='fw-500'>Drag and Drop Reordering: </span>
                  The list of faculties is draggable, allowing you to change their order.
                  To change the order of faculties, simply drag and drop them as needed.
                  The order number is automatically updated to reflect the new order.
                </p>
                <p className='mb-1'> <span className='fw-500'>Language: </span>
                  Make sure to select the appropriate language for your faculties using the "Languages" component at the top.
                </p>
              </div>
            </ShowMoreText>
          </Col>
          <Col sm={12} className='d-flex justify-content-end'>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                history.push(`/${language}/faculty-form`)
              }}
            >
              Create a faculty
            </Button>
          </Col>
        </Row>
        <hr />
        {
          faculties && faculties.length ?
            <Card>
              <CardBody>

                <Table responsive className="table mb-0 table-bordered">
                  <thead>
                    <tr>
                      <th className='no-wrap'>Order number</th>
                      <th className='no-wrap'>Image</th>
                      <th className='no-wrap'>Logo</th>
                      <th className='no-wrap'>Title</th>
                      <th className='no-wrap'>Description</th>
                      <th className='no-wrap'>Color</th>
                    </tr>
                  </thead>
                  <tbody>
                    {faculties.map((item, index) => (
                      <tr
                        key={index}
                        className='cursor-pointer'
                        draggable={true}
                        onDrop={() => drop(item.id)}
                        onDragOver={event => event.preventDefault()}
                        onDragStart={() => drag(item.id)}
                      >
                        <td><b className='font-size-20'>{item.displayOrder}</b></td>
                        {
                          item.imagePath ?
                            <td >
                              <div
                                className="avatar-md"
                                style={{
                                  backgroundImage: `url(${item.imagePath})`,
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                }}
                              />
                            </td>
                            :
                            <td>
                              <span className='no-wrap'>No image</span>
                            </td>
                        }
                        {
                          item.logoPath ?
                            <td >
                              <div
                                className="avatar-md"
                                style={{
                                  backgroundImage: `url(${item.logoPath})`,
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                }}
                              />
                            </td>
                            :
                            <td>
                              <span className='no-wrap'>No logo</span>
                            </td>
                        }
                        <td title={item.title}><span className='max-line-2 word-break-break-word text-nowrap'>{item.title}</span></td>
                        <td title={item.description}><span className='max-line-2 word-break-break-word'>{item.description}</span></td>
                        <td>
                          <div
                            className='border rounded overflow-hidden me-1 p-1'
                            style={{ height: 30, width: 60, backgroundColor: item.color }}
                          />
                          <small><i>{item.color}</i></small>
                        </td>
                        <td className='d-flex border-none'>
                          <Button
                            color="success"
                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                            title='Edit'
                            outline
                            onClick={() => {
                              props.history.push(`/${language}/faculty-form/${item.id}`)
                            }}
                          >
                            <i className='mdi mdi-pencil font-size-18'></i>
                          </Button>
                          <Button
                            color="danger"
                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                            title='Delete'
                            outline
                            onClick={() => deleteFaculty(item.id)}
                          >
                            <i className='mdi mdi-delete font-size-18'></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            : <div>
              <NoData />
              <div className='my-4 d-flex justify-content-center flex-wrap'>
                <b className='d-flex text-center text-wrap'>
                  You don't have created faculties yet
                </b>
                <b>
                  <Link to={`/${language}/faculty-form`} className="ms-2">
                    <span className='underline'>Create a faculty</span>
                    <i className='bx bx-link-external ms-1 d-inline'></i>
                  </Link>
                </b>
              </div>
            </div>
        }

      </div>
    </div>
  )
}
