import React, { useEffect, useState, useRef } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { SPINNER_COLOR } from "../../constants/mainKeys";

function PageSpinner(props) {

  let color = SPINNER_COLOR;

  return (
    props.spinner && props.spinner.length ?
      <div className="spinner-layer">
        <div className="sweet-loading">
          <PuffLoader size={80} color={color}/>
        </div>
      </div>
      : null
  );
}

export default PageSpinner;