import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom"
import Dashboard from '../../pages/authPages/dashboard/Dashboard'
import Logout from '../../pages/unauthPages/Logout'
import Profile from "../../pages/authPages/profile/Profile"
import { DEFAULT_LANGUAGE_KEY } from '../../constants/mainKeys'
import { useSelector } from 'react-redux'
import Courses from '../../pages/authPages/course/Courses'
import CourseForm from '../../pages/authPages/course/CourseForm'
import Lecturers from '../../pages/authPages/lecturer/Lecturers'
import LecturerForm from '../../pages/authPages/lecturer/LecturerForm'
import CourseMedia from '../../pages/authPages/course/CourseMedia'
import PageForm from '../../pages/authPages/page-content/PageForm'
import Pages from '../../pages/authPages/page-content/Pages'
import Translations from '../../pages/authPages/translation/Translations'
import AuthenticationByRefreshToken from '../../pages/authenticationByRefreshToken/AuthenticationByRefreshToken'
import Faculties from '../../pages/authPages/faculties/Faculties'
import FacultyForm from '../../pages/authPages/faculties/FacultiesForm'
import CourseTypes from '../../pages/authPages/courseTypes/CourseTypes'
import CourseTypeForm from '../../pages/authPages/courseTypes/CourseTypeForm'
import Faqs from '../../pages/authPages/faq/Faqs'
import Benefits from '../../pages/authPages/benefits/Benefits'
import News from '../../pages/authPages/news/News'
import NewsForm from '../../pages/authPages/news/NewsForm'
import CareerForm from '../../pages/authPages/career/CareerForm'
import Careers from '../../pages/authPages/career/Careers'
import MemberApplications from '../../pages/authPages/memberApplications/MemberApplications'
import SpecializationApplications from '../../pages/authPages/memberApplications/SpecializationApplications'
import CorporateCourseApplications from '../../pages/authPages/memberApplications/CorporateCourseApplications'
import ShortCourseApplications from '../../pages/authPages/memberApplications/ShortCourseApplications'


export default function AuthMiddleware() {

  const { language } = useSelector(state => state.Languages);

  return (
    <Switch>
      <Route path="/:language/dashboard" exact component={Dashboard} />
      <Route path="/:language/profile" exact component={Profile} />
      <Route path="/:language/courses" exact component={Courses} />
      <Route path="/:language/course-form" exact component={CourseForm} />
      <Route path="/:language/course-form/:courseId" exact component={CourseForm} />
      <Route path="/:language/course-media/:courseId" exact component={CourseMedia} />
      <Route path="/:language/news" exact component={News} />
      <Route path="/:language/news-form" exact component={NewsForm} />
      <Route path="/:language/news-form/:newsId" exact component={NewsForm} />
      <Route path="/:language/careers" exact component={Careers} />
      <Route path="/:language/career-form" exact component={CareerForm} />
      <Route path="/:language/career-form/:careerId" exact component={CareerForm} />
      <Route path="/:language/member-applications" exact component={MemberApplications} />
      <Route path="/:language/specialization-applications" exact component={SpecializationApplications} />
      <Route path="/:language/corporate-course-applications" exact component={CorporateCourseApplications} />
      <Route path="/:language/short-course-applications" exact component={ShortCourseApplications} />
      <Route path="/:language/users" exact component={Lecturers} />
      <Route path="/:language/user-form" exact component={LecturerForm} />
      <Route path="/:language/user-form/:lecturerId" exact component={LecturerForm} />
      <Route path="/:language/translations" exact component={Translations} />
      <Route path="/:language/faculties" exact component={Faculties} />
      <Route path="/:language/faculty-form" exact component={FacultyForm} />
      <Route path="/:language/faculty-form/:facultyId" exact component={FacultyForm} />
      <Route path="/:language/course-types" exact component={CourseTypes} />
      <Route path="/:language/course-type-form" exact component={CourseTypeForm} />
      <Route path="/:language/course-type-form/:courseTypeId" exact component={CourseTypeForm} />
      <Route path="/:language/faq" exact component={Faqs} />
      <Route path="/:language/benefits" exact component={Benefits} />
      <Route path="/:language/pages" exact component={Pages} />
      <Route path="/:language/content-form" exact component={PageForm} />
      <Route path="/:language/content-form/:pageName/:sectionId" exact component={PageForm} />
      <Route path="/:language/content-form/:pageName/:sectionId/:contentId" exact component={PageForm} />

      {/* AuthenticationByRefreshToken */}
      <Route path="/:language/authentication/:refreshToken" exact component={AuthenticationByRefreshToken} />

      <Route path="/logout" exact component={Logout} />
      <Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}/dashboard`} />
    </Switch>
  )
}
