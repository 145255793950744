import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_TRANSLATIONS_REQUEST, GET_TRANSLATIONS_SUCCESS } from './actionTypes';
import { addPageSpinner, removePageSpinner, setIsShowLanguageSpinner } from '../../store/spinner/actions';
import AlertService from '../../services/alertService';
import ApiService from '../../services/apiService';
import { ERROR_KEY } from '../../constants/mainKeys';


export default function* watcher() {
  yield takeLatest(GET_TRANSLATIONS_REQUEST, getTranslations);
}

function* getTranslations(action) {
  try {
    const language = action.payload;
    yield put(setIsShowLanguageSpinner(true));
    const allTranslations = yield call(ApiService.getAllTranslations, language);
    if (allTranslations) {
      yield put({
        type: GET_TRANSLATIONS_SUCCESS,
        payload: [...allTranslations.data]
      });
    }
    yield put(setIsShowLanguageSpinner(false));
  } catch (error) {
    yield put(setIsShowLanguageSpinner(false));
    error && AlertService.alert((AlertService.checkMessageType(error?.respcode) || ERROR_KEY), error);
  }
}


