import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { useSelector } from 'react-redux'
import { Table } from "react-bootstrap"
import AlertService from '../../../services/alertService'
import { ERROR_KEY, SUCCESS_KEY } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Row, Col, Button, Card, CardBody } from 'reactstrap'
import NoData from '../../../components/NoData/NoData'
import FormLanguage from '../../../components/FormLanguage/FormLanguage'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import { Link } from "react-router-dom"
import ShowMoreText from "react-show-more-text"

export default function CourseTypes(props) {

  const dispatch = useDispatch()
  const { history } = props;
  const { language, formLanguage } = useSelector(state => state.Languages);
  const [courseTypes, setCourseTypes] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [dragItemId, setDragItemId] = useState(null);

  useEffect(() => {
    getCourseTypes();
  }, [formLanguage])

  const getCourseTypes = () => {
    if (!formLanguage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getCourseTypes(formLanguage.id).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setCourseTypes(response.data);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteCourseType = (courseTypeId) => {
    if (!courseTypeId) { return false; }
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId)
      ApiService.deleteCourseType(courseTypeId).then(response => {
        setCourseTypes(courseTypes.filter(item => item.id !== +courseTypeId));
        AlertService.alert(SUCCESS_KEY, "Data saved");
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const drag = (sectionId) => {
    sectionId && setDragItemId(sectionId)
  }

  const drop = (dropItemId) => {
    if (!dragItemId || !formLanguage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.setCourseTypeOrder(formLanguage.id, dropItemId, dragItemId).then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setCourseTypes(data);
      }
      setDragItemId(null);
      extractPageSpinner(spinnerId);
      AlertService.alert(SUCCESS_KEY, "Data saved");
    }).catch(error => getFail(error, spinnerId));
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
    setDragItemId(null);
  }


  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="Course types"
        />
        <FormLanguage />
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col>
            <h4 className="mt-0 font-size-16">
              Welcome to Course Types page
            </h4>
            <ShowMoreText
              lines={2}
              more="Read more"
              less="Less"
              className="mb-3 mt-0 more-less-text"
              anchorClass="underline cursor-pointer pt-0"
              expanded={false}
              truncatedEndingComponent={<span>...<br /></span>}
            >
              <div className='form-info-text'>
                <p className='mb-1'>Here you can manage and organize different types of courses. This page provides you with the tools to create, edit, and delete course types tailored to your organization's needs. Here's an overview of the page's features.</p>
                <p className='mb-1'> <span className='fw-500'>How to Create a Course Type? </span>
                  To get started, click on the "Create a course type" button to define a new course type with a unique title, description, and visual characteristics.
                  The information you provide here will help you categorize and differentiate courses effectively.
                </p>
                <p className='mb-1'> <span className='fw-500'>Course Types Overview: </span>
                  View a list of all existing course types, along with their display order numbers, images, titles, descriptions, and theme colors. You can also see options to edit or delete each course type.
                </p>
                <p className='mb-1'> <span className='fw-500'>Drag and Drop Reordering: </span>
                  Use the drag-and-drop feature to change the order of course types in the list. This allows you to rearrange them to your preference.
                  Simply drag and drop a course type to reposition it.
                </p>
                <p className='mb-1'> <span className='fw-500'>Create a New Course Type: </span>
                  If you haven't created any course types yet, you'll find a message encouraging you to create your first one. Click the "Create a course type" link to begin the process.
                </p>
                <p className='mb-1'> <span className='fw-500'>Language: </span>
                  Choose the language in which you want to create the course type using the "Language" component at the top. Select the appropriate language to meet your audience's needs.
                </p>
                <p className='mb-1'> <span className='fw-500'>Course Type Management: </span>
                  Each course type is displayed with its title, description, image (if provided), and theme color.
                  You can perform actions such as editing or deleting course types as needed.
                </p>
                <p className='mb-1'> <span className='fw-500'>Edit and Delete: </span>
                  Click on the "Edit" button to modify the details of a course type.
                  To delete a course type, click on the "Delete" button. You will be prompted to confirm this action.
                </p>
               
              </div>
            </ShowMoreText>
          </Col>
          <Col sm={12} className='d-flex justify-content-end'>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                history.push(`/${language}/course-type-form`)
              }}
            >
              Create a course type
            </Button>
          </Col>
        </Row>
        <hr />
        {
          courseTypes && courseTypes.length ?
            <Card>
              <CardBody>

                <Table responsive className="table mb-0 table-bordered">
                  <thead>
                    <tr>
                      <th className='no-wrap'>Order number</th>
                      <th className='no-wrap'>Image</th>
                      <th className='no-wrap'>Title</th>
                      <th className='no-wrap'>Description</th>
                      <th className='no-wrap'>Color</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courseTypes.map((item, index) => (
                      <tr
                        key={index}
                        className='cursor-pointer'
                        draggable={true}
                        onDrop={() => drop(item.id)}
                        onDragOver={event => event.preventDefault()}
                        onDragStart={() => drag(item.id)}
                      >
                        <td><b className='font-size-20'>{item.displayOrder}</b></td>
                        {
                          item.imagePath ?
                            <td >
                              <div
                                className="avatar-md"
                                style={{
                                  backgroundImage: `url(${item.imagePath})`,
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                }}
                              />
                            </td>
                            :
                            <td>
                              <span className='no-wrap'>No image</span>
                            </td>
                        }
                        <td title={item.title}><span className='max-line-2 word-break-break-word text-nowrap'>{item.title}</span></td>
                        <td title={item.description}><span className='max-line-2 word-break-break-word'>{item.description}</span></td>
                        <td>
                          <div
                            className='border rounded overflow-hidden me-1 p-1'
                            style={{ height: 30, width: 60, backgroundColor: item.color }}
                          />
                          <small><i>{item.color}</i></small>
                        </td>
                        <td className='d-flex border-none'>
                          <Button
                            color="success"
                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                            title='Edit'
                            outline
                            onClick={() => {
                              props.history.push(`/${language}/course-type-form/${item.id}`)
                            }}
                          >
                            <i className='mdi mdi-pencil font-size-18'></i>
                          </Button>
                          <Button
                            color="danger"
                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                            title='Delete'
                            outline
                            onClick={() => deleteCourseType(item.id)}
                          >
                            <i className='mdi mdi-delete font-size-18'></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            : <div>
              <NoData />
              <div className='my-4 d-flex justify-content-center flex-wrap'>
                <b className='d-flex text-center text-wrap'>
                  You don't have created course types yet
                </b>
                <b>
                  <Link to={`/${language}/course-type-form`} className="ms-2">
                    <span className='underline'>Create a course type</span>
                    <i className='bx bx-link-external ms-1 d-inline'></i>
                  </Link>
                </b>
              </div>
            </div>
        }

      </div>
    </div>
  )
}
