// -------------------------------------------------------------------------------------
export const IDENTITY_API_URL_KEY = "https://identity.ardy.am";
export const API_URL_KEY = "https://api.ardy.am";
// export const API_URL_KEY = "https://e31f-87-241-138-217.ngrok-free.app";
// -------------------------------------------------------------------------------------

// // -------------------------------------------------------------------------------------
// export const IDENTITY_API_URL_KEY = "https://identity.ardy.am";
// export const API_URL_KEY = "https://93d7-87-241-138-217.ngrok-free.app";
// // -------------------------------------------------------------------------------------

export const ARDY_COURSE_URL_KEY = "https://ardy.am/am/course/";
