import React, { useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import MainService from '../../../services/mainService'
import ActionButton from '../../../components/Buttons/ActionButton'
import AlertService from '../../../services/alertService'
import { ERROR_KEY, IMAGE_KEY, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY, VALID_VIDEO_TYPES_KEY, VIDEO_KEY, fieldValidation } from '../../../constants/mainKeys'
import uuid from 'react-uuid'
import NoData from '../../../components/NoData/NoData'
import { Card, CardBody, Row, Col, Form, Button, CardTitle, CardSubtitle, Label } from 'reactstrap'
import { ProgressBar } from 'react-bootstrap'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/spinner/actions'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import ApiService from '../../../services/apiService'
import { useEffect } from 'react'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import ShowMoreText from "react-show-more-text"

const buttonSpinnerId = uuid();

export default function CourseMedia(props) {

  const dispatch = useDispatch()
  const mainService = new MainService();
  const { language, languages, formLanguage } = useSelector(state => state.Languages);
  const { buttonSpinners } = useSelector(state => state.Spinners);
  const [changes, setChanges] = useState(false);
  const { courseId } = useParams();
  const { history } = props;
  const [mainImage, setMainImage] = useState(null);
  const [uploadedMainImage, setUploadedMainImage] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [uploadedCertificate, setUploadedCertificate] = useState(null);
  const [mainVideo, setMainVideo] = useState(null);
  const [uploadedMainVideo, setUploadedMainVideo] = useState(null);
  const [fileReadPercent, setFileReadPercent] = useState(0);
  const [courseData, setCourseData] = useState(null);
  const [imageNameToBeRemoved, setImageNameToBeRemoved] = useState("");
  const [certificateNameToBeRemoved, setCertificateNameToBeRemoved] = useState("");
  const [videoNameToBeRemoved, setVideoNameToBeRemoved] = useState("");
  const [isShowInsertLink, setIsShowInsertLink] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  useEffect(() => {
    if (!courseId) history.push(`/${language}/courses`);
    getCourseById();
  }, [formLanguage])

  const getCourseById = () => {
    if (!formLanguage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getCourseById(+courseId, formLanguage.id).then(response => {
      if (response && response.data) {
        const data = { ...response.data }
        setCourseData(data);
        if (data.videoLink) {
          setIsShowInsertLink(true);
          setVideoLink(data.videoLink);
        }else{
          setVideoLink("");
        }
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const uploadFile = async (event, fileType, cbForRead, cbForFile) => {
    const file = event.target.files[0];
    let validFileTypes = null;
    switch (fileType) {
      case IMAGE_KEY:
        validFileTypes = VALID_IMAGE_TYPES_KEY;
        break;
      case VIDEO_KEY:
        validFileTypes = VALID_VIDEO_TYPES_KEY;
        break;
    }

    if (file) {
      mainService.readFile(file, validFileTypes, setFileReadPercent).then(uploadFile => {
        if (uploadFile) {
          cbForRead(uploadFile);
          cbForFile(file);
        }
      }).catch(error => {
        error && AlertService.alert("error", "Invalid file format");
        cbForRead(null);
        cbForFile(null);
        setFileReadPercent(0);
      });
    }
  }

  const removeFieldFromPaths = (filePath, fieldName, cb) => {
    const parts = filePath.split('/');
    const filename = parts[parts.length - 1];
    cb(filename);
    setCourseData((values) => ({
      ...values,
      [fieldName]: null
    }))
  }

  const onChange = (event, cb, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (isShowInsertLink && !videoLink.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    }
    const formData = new FormData();
    formData.append("id", +courseId);
    formData.append("image", mainImage);
    formData.append("certificate", certificate);
    formData.append("video", mainVideo);
    formData.append('videoLink', videoLink);
    certificateNameToBeRemoved && formData.append("certificateNameToBeRemoved", certificateNameToBeRemoved)
    imageNameToBeRemoved && formData.append("imageNameToBeRemoved", imageNameToBeRemoved)
    videoNameToBeRemoved && formData.append("videoNameToBeRemoved", videoNameToBeRemoved)
    setButtonSpinner(buttonSpinnerId);
    ApiService.updateCourseMedia(formData).then(response => {
      AlertService.alert(SUCCESS_KEY, "Data saved");
      extractButtonSpinner(buttonSpinnerId)
      history.push(`/${language}/courses`);
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
    history.push(`/${language}/courses`)
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem={"Course media"}
        />
        <hr className='mt-0' />
        <ShowMoreText
          lines={2}
          more="Read more"
          less="Less"
          className="mb-3 mt-0 more-less-text"
          anchorClass="underline cursor-pointer pt-0"
          expanded={false}
          truncatedEndingComponent={<span>...<br /></span>}
        >
          <div className='form-info-text'>
            <p className='mb-1'>Welcome to the Course Media Management page! This is where you can update, modify, and enhance the media resources associated with your courses. You have full control over the visual and multimedia elements that make your courses engaging and informative.</p>
            <p className='mb-1'> <span className='fw-500'>Main Image: </span>The "Main Image" section allows you to upload or replace the main course image. You can upload images in formats such as .jpg, .jpeg, .png, or .svg to represent your course effectively.</p>
            <p className='mb-1'> <span className='fw-500'>Certificate: </span>In the "Certificate" section, you can upload or change the course certificate. This is an important document that certifies the course completion, and you can upload images in the supported formats.</p>
            <p className='mb-1'> <span className='fw-500'>Video: </span>Manage course videos in the "Video" section. You can either upload a video file in .mp4 format or use an external video link, such as YouTube, by enabling the "Use insert link" option.</p>
            <p className='mb-1'> <span className='fw-500'>External Video Link: </span>If you choose to use an external video link, simply paste the link to your video. This can be especially useful when you have course content hosted on platforms like YouTube.</p>
            <p className='mb-1'> <span className='fw-500'>Save Changes: </span>Be sure to click the "Save" button at the bottom of the page once you've made your updates. This will save all the changes you've made to the course media.</p>
            <p className='mb-1'> <span className='fw-500'>Progress Bar: </span>You'll notice a progress bar when uploading media files. It will indicate the status of the file upload, so you can track the process.</p>
            <p className='mb-1'> <span className='fw-500'>File Formats: </span>Remember to adhere to the supported file formats mentioned for each media type to ensure a smooth upload process.</p>
            <p className='mb-1'>Enhance the visual and multimedia aspects of your courses with ease on this Course Media Management page. Take full advantage of this feature to create a richer learning experience for your students!</p>

          </div>
        </ShowMoreText>
        <Card>
          <CardBody>
            <CardTitle>Course Media Management</CardTitle>
            <CardSubtitle className="mb-3">


            </CardSubtitle>
            <Form
              onChange={() => setChanges(true)}
              onSubmit={onSubmit}
            >
              <Row>
                <Col sm={6} md={4} className='d-flex flex-column mt-2'>
                  <label className='text-center mb-0'>Main image</label>
                  {
                    uploadedMainImage || (courseData && courseData.imagePath) ?
                      <div className='p-2 mt-1 text-center'>
                        {
                          uploadedMainImage || (courseData && courseData.imagePath) ?
                            <div className='position-relative d-inline-block'>
                              <img
                                src={uploadedMainImage ? uploadedMainImage : courseData && courseData.imagePath ? courseData.imagePath : ""}
                                alt="/"
                                style={{ height: "200px", maxWidth: "100%" }}
                                className="rounded"
                              />
                              <Button
                                style={{ position: "absolute", top: 5, right: 5 }}
                                color="danger"
                                className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  setChanges(true);
                                  if (uploadedMainImage) {
                                    setUploadedMainImage(null);
                                    setMainImage(null);
                                  } else {
                                    removeFieldFromPaths(courseData.imagePath, "imagePath", setImageNameToBeRemoved)
                                  }
                                }}
                              >
                                <i className="bx bx-x font-size-22"></i>
                              </Button>
                            </div>
                            : null
                        }
                        {
                          courseData && courseData.imagePath ?
                            <br />
                            : null
                        }
                      </div>
                      : <NoData width={200} height={200} />
                  }
                  {
                    !uploadedMainImage && (!courseData || (courseData && !courseData.imagePath)) ?
                      <div className='d-flex justify-content-center mt-auto'>
                        <Button
                          type="button"
                          color={`${!uploadedMainImage ? "primary" : "danger"}`}
                          className='px-4 mt-2'
                          onClick={(event) => {
                            if (!uploadedMainImage) {
                              MainService.triggerUploadClick(event);
                            }
                          }}
                        >
                          Upload main image
                          <input
                            type="file"
                            id='mainImage'
                            className="d-none"
                            hidden
                            onChange={(event) => uploadFile(event, "image", setUploadedMainImage, setMainImage)}
                          />
                        </Button>
                      </div>
                      : null
                  }
                  <div className='d-flex justify-content-center mt-1'>
                    <small><i>File formats are: .jpg, .jpeg, .png, .svg</i></small>
                  </div>
                </Col>
                <Col sm={6} md={4} className='d-flex flex-column mt-2'>
                  <label className='text-center mb-0'>Certificate</label>
                  {
                    uploadedCertificate || (courseData && courseData.certificatePath) ?
                      <div className='p-2 mt-1 text-center'>
                        {
                          uploadedCertificate || (courseData && courseData.certificatePath) ?
                            <div className='position-relative d-inline-block'>
                              <img
                                src={uploadedCertificate ? uploadedCertificate : courseData && courseData.certificatePath ? courseData.certificatePath : ""}
                                alt="/"
                                style={{ height: "200px", maxWidth: "100%" }}
                                className="rounded"
                              />
                              <Button
                                style={{ position: "absolute", top: 5, right: 5 }}
                                color="danger"
                                className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  setChanges(true);
                                  if (uploadedCertificate) {
                                    setUploadedCertificate(null);
                                    setCertificate(null);
                                  } else {
                                    removeFieldFromPaths(courseData.certificatePath, "certificatePath", setCertificateNameToBeRemoved)
                                  }
                                }}
                              >
                                <i className="bx bx-x font-size-22"></i>
                              </Button>
                            </div>
                            : null
                        }
                        {
                          courseData && courseData.imagePath ?
                            <br />
                            : null
                        }
                      </div>
                      : <NoData width={200} height={200} />
                  }
                  {
                    !uploadedCertificate && (!courseData || (courseData && !courseData.certificatePath)) ?
                      <div className='d-flex justify-content-center mt-auto'>
                        <Button
                          type="button"
                          color={`${!uploadedCertificate ? "primary" : "danger"}`}
                          className='px-4 mt-2'
                          onClick={(event) => {
                            if (!uploadedCertificate) {
                              MainService.triggerUploadClick(event);
                            }
                          }}
                        >
                          Upload certificate
                          <input
                            type="file"
                            id='certificate'
                            className="d-none"
                            hidden
                            onChange={(event) => uploadFile(event, "image", setUploadedCertificate, setCertificate)}
                          />
                        </Button>
                      </div>
                      : null
                  }
                  <div className='d-flex justify-content-center mt-1'>
                    <small><i>File formats are: .jpg, .jpeg, .png, .svg</i></small>
                  </div>
                </Col>
                <Col sm={6} md={4} className='d-flex flex-column mt-2'>
                  <label className='text-center mb-0'>Video</label>

                  <div className="form-check d-flex justify-content-center gap-2 font-size-14">
                    <input
                      id="insertLink"
                      type="checkbox"
                      className="form-check-input"
                      checked={isShowInsertLink}
                      onChange={event => {
                        if (event.target.checked && courseData && courseData.videoPath) {
                          removeFieldFromPaths(courseData.videoPath, "videoPath", setVideoNameToBeRemoved);
                        } else {
                          setVideoLink("")
                        }
                        setIsShowInsertLink(event.target.checked);
                      }}
                    />
                    <label
                      className="form-check-label mb-3"
                      htmlFor="insertLink"
                    >
                      Use insert link
                    </label>
                  </div>

                  {
                    isShowInsertLink ?
                      <div className="">
                        <textarea
                          name="videoLink"
                          rows="2"
                          placeholder="Ex. https://www.youtube.com/watch?v=LXb3EKWsInQ"
                          className={`form-control resize-none  ${isInvalidSubmit && isShowInsertLink && !videoLink.trim().length ? "error-border" : ""}`}
                          value={videoLink}
                          onChange={(event) => onChange(event, setVideoLink, fieldValidation.length_255)}
                        />
                      </div>
                      :
                      <Auxiliary>
                        {
                          uploadedMainVideo || (courseData && courseData.videoPath) ?
                            <div className='p-2 mt-1 text-center'>
                              {
                                uploadedMainVideo || (courseData && courseData.videoPath) ?
                                  <div className='position-relative d-inline-block'>
                                    <video
                                      src={uploadedMainVideo ? uploadedMainVideo : courseData && courseData.videoPath ? courseData.videoPath : ""}
                                      style={{ height: "200px", maxWidth: "100%" }}
                                      controls
                                      className="rounded"
                                    />
                                    <Button
                                      style={{ position: "absolute", top: 5, right: 5 }}
                                      color="danger"
                                      className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                      onClick={() => {
                                        setChanges(true);
                                        if (uploadedMainVideo) {
                                          setUploadedMainVideo(null);
                                          setMainVideo(null);
                                        } else {
                                          removeFieldFromPaths(courseData.videoPath, "videoPath", setVideoNameToBeRemoved)
                                        }
                                      }}
                                    >
                                      <i className="bx bx-x font-size-22"></i>
                                    </Button>
                                  </div>
                                  : null
                              }
                              {
                                courseData && courseData.videoPath ?
                                  <br />
                                  : null
                              }
                            </div>
                            : <NoData width={200} height={200} />
                        }
                        {
                          !uploadedMainVideo && (!courseData || (courseData && !courseData.videoPath)) ?
                            <div className='d-flex justify-content-center mt-auto'>
                              <Button
                                type="button"
                                color={`${!uploadedMainVideo ? "primary" : "danger"}`}
                                className='px-4 mt-2'
                                onClick={(event) => {
                                  if (!uploadedMainVideo) {
                                    MainService.triggerUploadClick(event);
                                  }
                                }}
                              >
                                Upload video
                                <input
                                  type="file"
                                  id='mainVideo'
                                  className="d-none"
                                  hidden
                                  onChange={(event) => uploadFile(event, "video", setUploadedMainVideo, setMainVideo)}
                                />
                              </Button>
                            </div>
                            : null
                        }
                        <div className='d-flex justify-content-center mt-1'>
                          <small><i>File formats is: .mp4</i></small>
                        </div>
                      </Auxiliary>
                  }

                </Col>
                <Col sm={12} className='mt-3'>
                  {
                    fileReadPercent > 0 && fileReadPercent < 100 ?
                      <ProgressBar now={fileReadPercent} />
                      : null
                  }
                </Col>
                <Col sm={12}>
                  <hr />
                </Col>
              </Row>
              <div className='d-flex justify-content-end'>
                <ActionButton
                  type="submit"
                  name="Save"
                  disabled={!changes}
                  spinnerClass={`w_50`}
                  className="btn btn-primary btn-block px-4"
                  spinnerId={buttonSpinnerId}
                />
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
