import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ReactPaginate from 'react-paginate'
import { Table } from "react-bootstrap"
import AlertService from '../../../services/alertService'
import { ERROR_KEY, SUCCESS_KEY } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Button, Card, CardBody } from 'reactstrap'
import NoData from '../../../components/NoData/NoData'
import { addPageSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import { CSVLink } from 'react-csv'
import MainService from '../../../services/mainService'
import moment from 'moment'

export default function MemberApplications(props) {

  const dispatch = useDispatch()
  const [memberApplications, setMemberApplications] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    getMemberApplications(1, 10);
    getAllMemberApplications();
    return () => {
      setMemberApplications([]);
    }
  }, [])

  const handlePageClick = (event) => {
    getMemberApplications(event.selected + 1);
    setActivePageNumber(event.selected);
  };

  const getMemberApplications = (currentPage, pageSize = 10) => {
    if (!currentPage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getMemberApplications(currentPage, pageSize).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setMemberApplications(response.data);
      }

      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const getAllMemberApplications = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getAllMemberApplications().then(response => {
      if (response && response.data) {
        let data = [...response.data];
        const filteredData = data.map(item => ({
          name: item.name || '',
          email: item.email || '',
          createDate: item.createDate ? moment(MainService.convertUTCDateToLocalDate(new Date(item.createDate))).format("ll HH:mm") : '',
        }));
        const keys = Object.keys(filteredData[0]); // Assuming all items have the same structure
        const stringKeys = keys.filter(key => typeof filteredData[0][key] === 'string');
        const formattedKeys = stringKeys.map(camelCaseToWords);
        const _csvData = [formattedKeys];
        filteredData.forEach(item => {
          const values = stringKeys.map(key => item[key]);
          _csvData.push(values);
        });
        setCsvData(_csvData);
      }

      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const camelCaseToWords = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\b\w/g, function (char) { return char.toUpperCase(); });
  }

  const deleteApplication = (id) => {
    if (!id) { return false; }
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId)
      ApiService.deleteApplication(id).then(response => {
        setMemberApplications(memberApplications.filter(item => item.id !== +id));
        setCsvData(csvData.filter(item => item.id !== +id))
        AlertService.alert(SUCCESS_KEY, "Data saved");
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
  }


  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="Member Applications"
        />
        <hr className='mt-0' />

        {
          memberApplications && memberApplications.length ?
            <Card className='border-none'>
              <CardBody className='pt-0'>
                {
                  csvData && csvData.length ?
                    <div className='d-flex justify-content-end mb-3'>
                      <CSVLink data={csvData} filename={`member_applications_${Date.now()}.csv`}>
                        <Button
                          color="primary"
                          className="m-1 d-flex justify-content-center align-items-center"
                          title='Download CSV'
                          onClick={() => { }}
                        >
                          Download CSV
                        </Button>
                      </CSVLink>
                    </div>
                    : null
                }
                <Table responsive className="table mb-0 table-bordered">
                  <thead>
                    <tr>
                      <th className='no-wrap'>Name</th>
                      <th className='no-wrap'>Email</th>
                      <th className='no-wrap'>Create Date</th>
                      <th className='no-wrap'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {memberApplications.map((item, index) => (
                      <tr key={index}>

                        <td><span className='max-line-10 word-break-break-word' title={item.name}>{item.name}</span></td>
                        <td><span className='max-line-10 word-break-break-word' title={item.email}>{item.email}</span></td>
                        <td>
                          <span className='text-nowrap'>
                            {
                              item.createDate ?
                                moment(MainService.convertUTCDateToLocalDate(new Date(item.createDate))).format("ll HH:mm")
                                : null
                            }
                          </span>
                        </td>
                        <td>
                          <div className='d-flex'>
                            {/* <DownloadCSV value={item} /> */}
                            <Button
                              color="danger"
                              className="m-1 btn-sm d-flex justify-content-center align-items-center"
                              title='Delete'
                              outline
                              onClick={() => deleteApplication(item.id)}
                            >
                              <i className='mdi mdi-delete font-size-18'></i>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {
                  pagination && pagination.TotalPages && pagination.TotalPages > 1 ?
                    <div className=' mt-4 d-flex justify-content-end w-100'>
                      <ReactPaginate
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pagination.TotalPages}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination flex-wrap"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        forcePage={activePageNumber}
                      />
                    </div>
                    : null
                }
              </CardBody>
            </Card>
            : <div>
              <NoData />
            </div>
        }

      </div>
    </div>
  )
}
