import React from 'react';
import { useSelector } from 'react-redux';
import DefaultButtonSpinner from '../Spinners/DefaultButtonSpinner';
import Auxiliary from './../../hoc/auxiliary/auxiliary';

const ActionButton = (props) => {
  const { buttonSpinners } = useSelector(state => state.Spinners);
  const currentSpinner = buttonSpinners.find(spinner => spinner === props.spinnerId);
  return <button
    type={props.type}
    color={props.color}
    disabled={currentSpinner || props.disabled}
    className={`d-flex justify-content-center align-items-center ${props.className}`}
    onClick={props.onClick}
  >
    {
      currentSpinner ?
        <Auxiliary>
          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}Loading
        </Auxiliary> :
        props.name ? props.name : props.children
    }
  </button>
};

export default ActionButton;  