import React, { useCallback, useState, useEffect, useId } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { withTranslation } from "react-i18next"
import { ERROR_KEY } from '../../../constants/mainKeys'
import { addPageSpinner, removePageSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../services/alertService'
import { useDispatch } from 'react-redux'
import { Card, CardBody, CardTitle, Progress, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import { useSelector } from 'react-redux'
import TranslationService from './../../../services/translationService';
import uuid from 'react-uuid'
import ApiService from './../../../services/apiService';

const Dashboard = (props) => {

  const dispatch = useDispatch();
  const { language, languages, formLanguage } = useSelector(state => state.Languages);
  const { translations } = useSelector(state => state.Translations);
  const [translationService, setTranslationService] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getDashboardData();
  }, [])

  const getDashboardData = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getDashboardData().then(response => {
      if (response && response.data) {
        setDashboardData(response.data)
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="Dashboard"
        />
        <hr />
        <Row>
          <Col md={6}>
            <Row>
              <Col xs={12}>
                {
                  dashboardData && dashboardData.courses && dashboardData.courses.length ?
                    <Card>
                      <div className="bg-primary bg-soft">
                        <Row>
                          <Col>
                            <div className="text-primary p-3">
                              <h5 className="text-primary">Courses</h5>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <CardBody>
                        <div className="text-center">
                          <div className="mb-4">
                            <i className='bx bx-book text-primary display-4'></i>
                          </div>
                        </div>
                        <div className="table-responsive mt-4">
                          <table className="table align-middle table-nowrap">
                            <tbody>
                              {
                                dashboardData.courses.map((item, index) => {
                                  return <tr key={index}>
                                    <td style={{ width: "30%" }}>
                                      <p className="mb-0">{item.key}</p>
                                    </td>
                                    <td style={{ width: "25%" }}>
                                      <h5 className="mb-0">{item.value}</h5>
                                    </td>
                                  </tr>
                                })
                              }
                            </tbody>
                          </table>
                          <div className="text-center mt-4">
                            <Link
                              to={`/${language}/courses`}
                              className="btn btn-primary btn-sm ps-3"
                            >
                              View More <i className="mdi mdi-arrow-right ms-1" />
                            </Link>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    : null
                }
              </Col>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Users</CardTitle>
                    <Row>
                      <Col sm="6">
                        <p className="text-muted mb-2">Number of users you have created</p>
                        <h3>{dashboardData && dashboardData.numberOfLecturers ? dashboardData.numberOfLecturers : 0}</h3>

                        <div className="mt-4">
                          <Link
                            to={`/${language}/users`}
                            className="btn btn-primary btn-sm ps-3"
                          >
                            View More <i className="mdi mdi-arrow-right ms-1"></i>
                          </Link>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mt-4 mt-sm-0">
                          <i className='bx bx-user text-primary display-5' style={{ fontSize: "80px" }} ></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="text-muted mb-0 mt-2">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle>Translations</CardTitle>
                    <div className="text-center">
                      <div className="avatar-sm mx-auto mb-5">
                        <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                          <i className="bx bx-text text-primary"></i>
                        </span>
                      </div>
                      <p className="font-16 text-muted mb-2"></p>
                      <h5>
                        Number of translations you have created
                        <span className="font-16 ms-2">{dashboardData && dashboardData.numberOfTranslations ? dashboardData.numberOfTranslations : 0}</span>
                      </h5>
                      <p className="text-muted">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      </p>
                      <Link
                        to={`/${language}/translations`}
                        className="btn btn-primary btn-sm ps-3"
                      >
                        View More <i className="mdi mdi-arrow-right ms-1" />
                      </Link>
                    </div>

                  </CardBody>
                </Card>
              </Col>
              <Col xs={12}>
                <Card className="overflow-hidden">
                  <CardBody>
                    <CardTitle>Faculties</CardTitle>
                    <Row>
                      <Col sm="8">
                        <div className="pt-2">
                          <Row>
                            <Col>
                              <p className="text-muted mb-0">Number of faculties you have created
                                <b className='font-size-18 ms-2'>{dashboardData && dashboardData.numberOfFaculties ? dashboardData.numberOfFaculties : 0}</b>
                              </p>
                            </Col>
                          </Row>
                          <div className="mt-4">
                            <Link
                              to={`/${language}/faculties`}
                              className="btn btn-primary btn-sm ps-3"
                            >
                              View More <i className="mdi mdi-arrow-right ms-1" />
                            </Link>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12}>
                        <p className="text-muted mt-2">
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12}>
                <Card className="overflow-hidden">
                  <CardBody>
                    <CardTitle>Course types</CardTitle>
                    <Row>
                      <Col sm="8">
                        <div className="pt-2">
                          <Row>
                            <Col>
                              <p className="text-muted mb-0">Number of course types you have created
                                <b className='font-size-18 ms-2'>{dashboardData && dashboardData.numberOfCourseTypes ? dashboardData.numberOfCourseTypes : 0}</b>
                              </p>
                            </Col>
                          </Row>
                          <div className="mt-4">
                            <Link
                              to={`/${language}/course-types`}
                              className="btn btn-primary btn-sm ps-3"
                            >
                              View More <i className="mdi mdi-arrow-right ms-1" />
                            </Link>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12}>
                        <p className="text-muted mt-2">
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default withTranslation()(Dashboard)