import React, { useCallback, useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
	ERROR_KEY,
	OBJECT_KEY,
	SUCCESS_KEY,
	VALID_IMAGE_TYPES_KEY,
	VALID_VIDEO_TYPES_KEY,
	fieldValidation,
} from "../../../constants/mainKeys";
import {
	addButtonSpinner,
	addPageSpinner,
	removeButtonSpinner,
	removePageSpinner,
} from "../../../store/spinner/actions";
import AlertService from "../../../services/alertService";
import { useDispatch } from "react-redux";
import {
	Card,
	CardBody,
	Col,
	Row,
	Label,
	Input,
	Form,
	Button,
} from "reactstrap";
import uuid from "react-uuid";
import ActionButton from "../../../components/Buttons/ActionButton";
import { useSelector } from "react-redux";
import FormLanguage from "../../../components/FormLanguage/FormLanguage";
import ApiService from "../../../services/apiService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import MainService from "../../../services/mainService";
import RichTextEditor from "../../../components/RichTextEditor/RichTextEditor";
import { ProgressBar } from "react-bootstrap";

const buttonSpinnerId = uuid();

const NewsForm = props => {
	const dispatch = useDispatch();
	const mainService = new MainService();
	const { language, formLanguage } = useSelector(state => state.Languages);
	const { newsId } = useParams();
	const { history } = props;
	const [isInvalidFileFormat, setIsInvalidFileFormat] = useState(false);
	const [uploadedVideo, setUploadedVideo] = useState(null);
	const [fileReadPercent, setFileReadPercent] = useState(0);
	const [fileReadPercentName, setFileReadPercentName] = useState("");
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [changes, setChanges] = useState(false);

	const [values, setValues] = useState({
		title: "",
		images: [],
		shortDescription: null,
		description: null,
		imageNamesToRemove: [],
		imagePaths: [],
	});

	useEffect(() => {
		if (newsId) {
			getNewsById();
		} else {
			setValues(values => ({
				...values,
				shortDescription: "",
				description: "",
			}));
		}
	}, [formLanguage, newsId]);

	const uploadFile = async (event, name) => {
		const newValues = { ...values };
		setIsInvalidFileFormat(false);
		setFileReadPercentName(name);
		if (event.target.files) {
			for (let i in event.target.files) {
				const file = event.target.files[i];
				if (file && typeof file === OBJECT_KEY) {
					const fileName = file.name;
					await mainService
						.readFile(
							file,
							name === "image" ? VALID_IMAGE_TYPES_KEY : VALID_VIDEO_TYPES_KEY,
							setFileReadPercent,
						)
						.then(uploadedFile => {
							if (name === "image") {
								newValues.images.push({
									fileName,
									file,
									uploadedFile,
								});
							} else {
								newValues.video = file;
								setUploadedVideo(uploadedFile);
							}
						})
						.catch(error => {
							error && AlertService.alert("error", "Invalid file format");
							setIsInvalidFileFormat(true);
							setFileReadPercent(0);
							setFileReadPercentName("");
						});
				}
			}
		}
		setValues(newValues);
	};

	const removeImage = imageIndex => {
		const newImages = [...values.images];
		newImages.splice(imageIndex, 1);
		setValues(values => ({
			...values,
			images: newImages,
		}));
	};

	const removeImageFromImagePaths = imageIndex => {
		const newImagePaths = [...values.imagePaths];
		const newImageNamesToRemove = [...values.imageNamesToRemove];
		const parts = newImagePaths[imageIndex].split("/");
		const filename = parts[parts.length - 1];
		newImagePaths.splice(imageIndex, 1);
		newImageNamesToRemove.push(filename);
		setValues(values => ({
			...values,
			imagePaths: newImagePaths,
			imageNamesToRemove: newImageNamesToRemove,
		}));
	};

	const getNewsById = () => {
		if (!formLanguage) {
			return false;
		}
		const spinnerId = uuid();
		setPageSpinner(spinnerId);
		ApiService.getNewsById(+newsId, formLanguage.id)
			.then(response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (!data.description) {
						data.description = "";
					}
					if (!data.shortDescription) {
						data.shortDescription = "";
					}
					setValues({ ...values, ...data });
				}
				extractPageSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const onChange = (event, field, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		setValues(values => ({
			...values,
			[field]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const onEditorChange = (event, fieldPath, maxLength = Infinity) => {
		const newValue = event.editor.getData();
		if (maxLength && newValue.length >= maxLength) return;
		const newValues = { ...values };
		let targetObject = newValues;
		const fieldPathArray = fieldPath.split(".");
		for (let i = 0; i < fieldPathArray.length - 1; i++) {
			targetObject = targetObject[fieldPathArray[i]];
		}
		targetObject[fieldPathArray[fieldPathArray.length - 1]] = newValue;
		setIsInvalidSubmit(false);
		setValues(newValues);
		setChanges(true);
	};

	const onSubmit = event => {
		event && event.preventDefault();
		if (!formLanguage) {
			return false;
		}
		if (!values.title.trim().length || !values.description.trim().length) {
			setIsInvalidSubmit(true);
			MainService.scrollToInvalidFieldPosition();
			return false;
		}
		if (newsId) values.id = +newsId;
		values.languageId = formLanguage.id;
		const formData = new FormData();

		Object.entries(values).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				value.forEach((item, i) => {
					if (item.hasOwnProperty("file")) {
						formData.append(key, item.file);
					} else {
						formData.append(key, item);
					}
				});
			} else {
				formData.append(key, typeof value === "boolean" || value ? value : "");
			}
		});
		setButtonSpinner(buttonSpinnerId);
		(newsId ? ApiService.updateNews(formData) : ApiService.createNews(formData))
			.then(response => {
				AlertService.alert(SUCCESS_KEY, "Data saved");
				extractButtonSpinner(buttonSpinnerId);
				history.push(`/${language}/news`);
			})
			.catch(error => getFail(error, buttonSpinnerId));
	};

	const setButtonSpinner = useCallback(spinner => {
		dispatch(addButtonSpinner(spinner));
	}, []);

	const setPageSpinner = useCallback(spinner => {
		dispatch(addPageSpinner(spinner));
	}, []);

	const extractButtonSpinner = useCallback(spinner => {
		dispatch(removeButtonSpinner(spinner));
	}, []);

	const extractPageSpinner = useCallback(spinner => {
		dispatch(removePageSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPageSpinner(spinnerId);
		spinnerId && extractButtonSpinner(spinnerId);
	};

	return (
		<div className="page-content position-relative">
			<div className="container-fluid">
				<Breadcrumbs title="Ardy" breadcrumbItem="News Form" />
				<FormLanguage />
				<hr className="mt-0" />
				<Card>
					<CardBody>
						<Form onChange={() => setChanges(true)} onSubmit={onSubmit}>
							<Row>
								<Col md={12}>
									<Row>
										<Col md={6}>
											<Label>Title*</Label>
											<Input
												type="text"
												placeholder="Ex. Graphic Design"
												className={`form-control  ${
													isInvalidSubmit && !values.title.trim().length
														? "error-border"
														: ""
												}`}
												value={values.title}
												onChange={event =>
													onChange(event, "title", fieldValidation.length_255)
												}
											/>
										</Col>
									</Row>

									<div className="mt-3">
										<Label>Short description</Label>
										{typeof values.shortDescription === "string" ? (
											<RichTextEditor
												value={values.shortDescription}
												blockClassName="custom-rich-text-editor small-rich-text-editor mt-1 rounded overflow-hidden"
												lableClassName="fw-500 font-size-14"
												onEditorChange={event =>
													onEditorChange(event, "shortDescription")
												}
											/>
										) : null}
									</div>

									<div className="mt-3">
										<Label>Description*</Label>
										{typeof values.description === "string" ? (
											<RichTextEditor
												value={values.description}
												blockClassName="custom-rich-text-editor small-rich-text-editor mt-1 rounded overflow-hidden"
												lableClassName="fw-500 font-size-14"
												onEditorChange={event =>
													onEditorChange(event, "description")
												}
											/>
										) : null}
									</div>

									<div className="mt-3">
										<Label>Image(s)</Label>
										<div className="position-relative d-flex flex-wrap">
											{values.images && values.images.length
												? values.images.map((image, imageIndex) => {
														return (
															<div
																key={imageIndex}
																className="me-3 position-relative">
																<img
																	src={image.uploadedFile}
																	alt="/"
																	className="avatar-xxl img-thumbnail mt-1"
																/>
																<Button
																	style={{
																		position: "absolute",
																		top: 0,
																		right: 0,
																	}}
																	color="danger"
																	className="btn-sm p-0 d-flex justify-content-center align-items-center"
																	onClick={() => {
																		removeImage(imageIndex);
																	}}>
																	<i className="bx bx-x font-size-22"></i>
																</Button>
															</div>
														);
												  })
												: null}
											{values.imagePaths && values.imagePaths.length
												? values.imagePaths.map((item, imageIndex) => {
														return (
															<div
																key={imageIndex}
																className="me-3 position-relative">
																<img
																	src={item}
																	alt="/"
																	className="avatar-xxl img-thumbnail mt-1"
																/>
																<Button
																	style={{
																		position: "absolute",
																		top: 0,
																		right: 0,
																	}}
																	color="danger"
																	className="btn-sm p-0 d-flex justify-content-center align-items-center"
																	onClick={() => {
																		removeImageFromImagePaths(imageIndex);
																	}}>
																	<i className="bx bx-x font-size-22"></i>
																</Button>
															</div>
														);
												  })
												: null}
										</div>

										{values.images &&
										!values.images.length &&
										(!values.imagePaths ||
											(values.imagePaths && !values.imagePaths.length)) ? (
											<b className="d-block">
												<i>
													You don't have uploaded images for this content yet.
												</i>
											</b>
										) : null}
										<div className=" mt-3">
											<Button
												type="button"
												color={`primary`}
												className="px-4"
												onClick={event => {
													MainService.triggerUploadClick(event);
												}}>
												Upload image(s)
											</Button>
											<input
												type="file"
												id="avatarImage"
												className="d-none"
												hidden
												multiple
												onChange={event => uploadFile(event, "image")}
											/>
											<div>
												<small>
													<i className="fw-normal">
														Allowed file format(s): .jpg, .jpeg, .png, .svg
													</i>
												</small>
											</div>
										</div>
										{fileReadPercent > 0 &&
										fileReadPercent < 100 &&
										fileReadPercentName === "image" ? (
											<div className="col-12 my-2">
												<ProgressBar now={fileReadPercent} />
											</div>
										) : null}
									</div>
								</Col>
							</Row>
							<div className="d-flex justify-content-end mt-2">
								<ActionButton
									type="submit"
									name="Save"
									disabled={!changes}
									spinnerClass={`w_50`}
									className="btn btn-primary btn-block px-4"
									spinnerId={buttonSpinnerId}
								/>
							</div>
						</Form>
					</CardBody>
				</Card>
			</div>
		</div>
	);
};

export default NewsForm;
