import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router } from "react-router-dom"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import PageSpinner from './components/Spinners/PageSpinner'
import { addPageSpinner, removePageSpinner } from './store/spinner/actions'
import "./assets/scss/theme.scss"
import UnauthMiddleware from "./routes/middleware/UnauthMiddleware"
import AuthMiddleware from "./routes/middleware/Authmiddleware"
import { getLanguages, setFormLanguage } from "./store/language/actions"
import { getTranslations } from "./store/translations/actions"

const App = () => {

  const dispatch = useDispatch()
  const { user } = useSelector(state => state.Login);
  const { pageSpinners } = useSelector(state => state.Spinners);
  const layout = useSelector(state => state.Layout);
  const { language, languages, formLanguage } = useSelector(state => state.Languages);

  useEffect(() => {
    dispatch(getLanguages())
  }, [])

  useEffect(() => {
    if (languages && languages.length && !formLanguage) {
      if (languages.find(lang => lang.isoCode2 === "am")) {
        dispatch(setFormLanguage(languages.find(lang => lang.isoCode2 === "am")))
      } else if (languages.find(lang => lang.isoCode2 === "en")) {
        dispatch(setFormLanguage(languages.find(lang => lang.isoCode2 === "en")))
      } else {
        dispatch(setFormLanguage(languages[0]));
      }
    }
  }, [languages])

  useEffect(() => {
    language && dispatch(getTranslations(language));
  }, [language])

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const Layout = getLayout()

  return (
    <React.Fragment>
      <Router>
        {
          user ?
            <Layout>
              <AuthMiddleware />
            </Layout>
            :
            <NonAuthLayout>
              <UnauthMiddleware />
            </NonAuthLayout>
        }
      </Router>
      <PageSpinner spinner={pageSpinners} />
      <div id="cx-alert-container" className='cx-alert-container' />
    </React.Fragment>
  )
}

export default App
