import { DEFAULT_LANGUAGE_KEY, LANGUAGES_KEY, LANGUAGE_KEY, FORM_LANGUAGE_KEY } from "../../constants/mainKeys";
import { LANGUAGE_SUCCESS, GET_LANGUAGES_SUCCESS, SET_FORM_LANGUAGE,  } from "./actionTypes";


const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

let initialState = {
  languages: [],
  language: localStorage.getItem(LANGUAGE_KEY) && localStorage.getItem(LANGUAGE_KEY) !== (null || "null") ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY,
  formLanguage: localStorage.getItem(FORM_LANGUAGE_KEY) && localStorage.getItem(FORM_LANGUAGE_KEY) !== (null || "null") && isJson(localStorage.getItem(FORM_LANGUAGE_KEY)) ? JSON.parse(localStorage.getItem(FORM_LANGUAGE_KEY)) : null ,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_SUCCESS:
      return {
        ...state,
        language: action.payload,
      };
    case GET_LANGUAGES_SUCCESS:
      localStorage.setItem(LANGUAGES_KEY, JSON.stringify(action.payload))
      return {
        ...state,
        languages: action.payload
      };
    case SET_FORM_LANGUAGE:
      localStorage.setItem(FORM_LANGUAGE_KEY, JSON.stringify(action.payload))
      return {
        ...state,
        formLanguage: action.payload
      };
    default:
      break;
  }
  return state;
};

export default reducer;