import React from 'react'
import Auxiliary from '../../hoc/auxiliary/auxiliary'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setFormLanguage } from '../../store/language/actions';
import { Label, NavItem, NavLink } from 'reactstrap'
import classnames from "classnames"

export default function FormLanguage() {

  const dispatch = useDispatch()
  const { languages, formLanguage } = useSelector(state => state.Languages);

  return (
    <Auxiliary>
      <Label>Select a language</Label>
      {
        languages && languages.length && formLanguage ?
          <div className="wizard clearfix mb-3">
            <div className="steps clearfix">
              <ul>
                {
                  languages.map((lang, index) => {
                    return <NavItem
                      key={index}
                      className={classnames({ current: formLanguage.isoCode2 === lang.isoCode2 })}>
                      <NavLink
                        className={classnames({ current: formLanguage.isoCode2 === lang.isoCode2 })}
                        onClick={() => {
                          dispatch(setFormLanguage(lang))
                        }}
                      >
                        <span className="number capitalize">{lang.isoCode2}</span>{" "}
                        {lang.name}
                      </NavLink>
                    </NavItem>
                  })
                }
              </ul>
            </div>
          </div>
          : null
      }
    </Auxiliary>
  )
}
