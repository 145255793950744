import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import ApiService from '../../services/apiService';
import { ERROR_KEY, REFRESH_TOKEN_KEY, TOKEN_KEY } from '../../constants/mainKeys';
import AlertService from '../../services/alertService';
import { setUserData } from '../../store/auth/login/actions';

const AuthenticationByRefreshToken = (props) => {

  const { language } = useSelector(state => state.language);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.refreshToken) {
      authenticateUser(props.match.params.refreshToken)
    }
  }, [])

  const authenticateUser = (refreshToken) => {
    const formData = new FormData();
    formData.append(`refreshToken`, refreshToken);
    ApiService.getUserDataByRefreshToken(formData).then(response => {
      if (response.data && response.data.accessToken) {
        const data = { ...response.data };
        localStorage.setItem(TOKEN_KEY, data?.accessToken);
        localStorage.setItem(REFRESH_TOKEN_KEY, data?.refreshToken);
        getUserData();
      } else {
        logout();
      }
    }).catch(logout);
  }

  const getUserData = () => {
    ApiService.currentUser().then(response => {
      if (response && response.data) {
        const user = { ...response.data }
        dispatch(setUserData(user));
      }
      props.history.push(`/${language}/dashboard`)
    }).catch(error => getFail(error))
  }

  const logout = () => {
    props.history.push(`/${language}/login`)
    localStorage.clear();
  }

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    props.history.push(`/${language}`)
  }

  return <></>
}
export default withRouter(AuthenticationByRefreshToken);
