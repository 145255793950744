import React, { useState, useEffect, useCallback } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ActionButton from '../../../components/Buttons/ActionButton'
import uuid from 'react-uuid'
import MainService from '../../../services/mainService'
import { ERROR_KEY, MAXIMUM_UPLOAD_FILE_SIZE, VALID_IMAGE_TYPES_KEY, fieldValidation } from '../../../constants/mainKeys'
import AlertService from '../../../services/alertService'
import ApiService from '../../../services/apiService';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import { useSelector, useDispatch } from 'react-redux'
import { Label, Row, Col, Input, Card, CardBody, Form, Button } from 'reactstrap'
import FormLanguage from '../../../components/FormLanguage/FormLanguage'
import ShowMoreText from "react-show-more-text"

const buttonSpinnerId = uuid();

export default function FacultyForm(props) {

  const dispatch = useDispatch();
  const mainService = new MainService();
  const { language, languages, formLanguage } = useSelector(state => state.Languages);
  const { history } = props;
  const [values, setValues] = useState({
    title: "",
    applyLink: "",
    description: "",
    color: "#ffffff",
    image: null,
    logo: null,
    mainVideoLink: "",
    videoLinks: [
      {
        videoTitle: "",
        videoLink: "",
      }
    ]
  })
  const { facultyId } = useParams();
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [changes, setChanges] = useState(false);
  const [facultyData, setFacultyData] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [logoNameToBeRemoved, setLogoNameToBeRemoved] = useState(null);
  const [imageNameToBeRemoved, setImageNameToBeRemoved] = useState(null);
  const [isInvalidVideoLink, setIsInvalidVideoLink] = useState(null);

  useEffect(() => {
    if (facultyId && formLanguage) {
      getFacultyDataById();
    }
  }, [facultyId, formLanguage])

  const getFacultyDataById = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getFacultyDataById(+facultyId, formLanguage.id).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setFacultyData(data);
        data.image = null;
        data.logo = null;
        if (!data.videoLinks || (data.videoLinks && !data.videoLinks.length)) {
          data.videoLinks = [
            {
              videoTitle: "",
              videoLink: "",
            }
          ]
        }
        setValues(data)
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error))
  }

  const uploadFile = (event, field, cb) => {
    if (!event.target.files[0]) { return; }
    const file = event.target.files[0];
    if (file) {
      if (file.size >= MAXIMUM_UPLOAD_FILE_SIZE * 1024 * 1024) {
        AlertService.alert("warning", "Incorrect file size");
        return false;
      }
      mainService.readFile(file, VALID_IMAGE_TYPES_KEY).then(uploadedImage => {
        cb(uploadedImage);
        setValues((values) => ({
          ...values,
          [field]: file,
        }));
      }).catch(error => {
        error && AlertService.alert("error", "Invalid file format");
        cb(null);
      });
    };
  }

  const onChange = (event, field, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false);
  }

  const removeImageFromPaths = (imagePath, fieldName, cb) => {
    const parts = imagePath.split('/');
    const filename = parts[parts.length - 1];
    cb(filename);
    setFacultyData((values) => ({
      ...values,
      [fieldName]: null
    }))
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!formLanguage) { return false; }
    if (!values.title.trim().length) {
      setIsInvalidSubmit(true);
      MainService.scrollToInvalidFieldPosition();
      return false;
    }
    const formData = new FormData();
    if (facultyId) formData.append('id', facultyId);
    if (imageNameToBeRemoved) formData.append("imageNameToBeRemoved", imageNameToBeRemoved);
    if (logoNameToBeRemoved) formData.append("logoNameToBeRemoved", logoNameToBeRemoved);
    formData.append('languageId', formLanguage.id);

    Object.entries(values).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, i) => {
          if (item.videoLink) {
            formData.append(`${key}[${i}].videoTitle`, item.videoTitle)
            formData.append(`${key}[${i}].videoLink`, item.videoLink)
          }
        })
      } else {
        formData.append(key, typeof value === "boolean" || value ? value : "")
      }
    })

    setButtonSpinner(buttonSpinnerId);
    (facultyId ? ApiService.updateFaculty(formData) : ApiService.createFaculty(formData)).then(response => {
      extractButtonSpinner(buttonSpinnerId);
      history.push(`/${language}/faculties`)
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const addVideoLink = () => {
    let emptyField = values.videoLinks.find(item => !item.videoTitle.trim().length || !item.videoLink.trim().length);
    if (emptyField) {
      setIsInvalidVideoLink(true);
      return false;
    }
    const newFields = {
      videoTitle: "",
      videoLink: ""
    };
    let valuesCopy = { ...values };
    let newVideoLinks = [...values.videoLinks];
    newVideoLinks.push(newFields);
    valuesCopy.videoLinks = newVideoLinks;
    setValues(valuesCopy);
  };

  const removeVideoLink = (index) => {
    const valuesCopy = { ...values };
    valuesCopy.videoLinks = valuesCopy.videoLinks.filter((_, i) => i !== index);
    setValues(valuesCopy);
    setChanges(true);
  };

  const onVideoChange = (event, index, name, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    const valuesCopy = { ...values };
    valuesCopy.videoLinks[index][name] = event.target.value;
    setValues(valuesCopy);
    setIsInvalidVideoLink(false);
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="Faculty Form"
        />
        <FormLanguage />
        <hr className='mt-0' />
        <ShowMoreText
          lines={2}
          more="Read more"
          less="Less"
          className="mb-3 mt-0 more-less-text"
          anchorClass="underline cursor-pointer pt-0"
          expanded={false}
          truncatedEndingComponent={<span>...<br /></span>}
        >
          <div className='form-info-text'>
            <p className='mb-1'>This page allows you to create or edit faculty information. Faculties can be an important part of your organization, and this form enables you to manage the details associated with them. Here is a breakdown of the form's features</p>
            <p className='mb-1'> <span className='fw-500'>Title: </span>
              Enter the title of the faculty.
              The title is a required field and must not exceed {fieldValidation.length_100} characters.
              For example, you can set it as "Marketing & Business."
            </p>
            <p className='mb-1'> <span className='fw-500'>Faculty Theme Color: </span>
              You can choose the theme color for the faculty by clicking on the color picker.
              A color picker allows you to select the desired color for the faculty theme.
              You can also enter a color code (e.g., "#3965FF") manually.
              The theme color helps in branding and identifying the faculty.
            </p>
            <p className='mb-1'> <span className='fw-500'>Description: </span>
              You can provide a description of the faculty.
              The description field allows you to add details about the faculty's offerings, courses, or any additional information.
              The description can contain up to {fieldValidation.length_2000} characters.
            </p>
            <p className='mb-1'> <span className='fw-500'>Image and Logo: </span>
              You can upload an image and a logo for the faculty.
              Images are usually used for displaying visuals related to the faculty.
              Logos are typically used as a symbol or emblem of the faculty.
              You can upload a new image or logo, and it will be displayed on the form.
              If you need to remove a previously uploaded image or logo, you can do so by clicking the "X" button.
              Image and logo dimensions can be adjusted as needed.
            </p>
            <p className='mb-1'> <span className='fw-500'>Save Button: </span>
              After entering the faculty details, you can click the "Save" button to save the information.
              The button will be disabled until you make changes to the form.
              A spinner will appear while the data is being processed to indicate that the save operation is in progress.
            </p>
            <p className='mb-1'> <span className='fw-500'>Language: </span>
              Make sure you select the appropriate language for your faculty information using the "Languages" component at the top.
            </p>

          </div>
        </ShowMoreText>
        <Card>
          <CardBody>

            <Form
              onChange={() => setChanges(true)}
              onSubmit={onSubmit}
            >
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label>Title*</Label>
                    <Input
                      type="text"
                      className={`form-control ${isInvalidSubmit && !values.title.trim().length ? "error-border" : ""}`}
                      placeholder="Ex. Marketing & business"
                      value={values.title}
                      onChange={(event) => onChange(event, "title", fieldValidation.length_100)}
                    />
                    <small><i>Max {fieldValidation.length_100} characters.</i></small>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">

                    <Label >Apply link</Label>
                    <Input
                      type="text"
                      placeholder='Ex. https://docs.google.com/document...'
                      className={`form-control`}
                      value={values.applyLink}
                      onChange={(event) => onChange(event, "applyLink", fieldValidation.length_255)}
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="mb-3">
                    <Label>Facluty theme color</Label>
                    <div className='d-flex'>
                      <div
                        className='border rounded overflow-hidden  me-1 p-1'
                        style={{ height: 38, width: 100 }}
                      >
                        <Input
                          id="color-picker"
                          type="color"
                          className={`form-control d-block p-0 h-100 border-none outline-none`}
                          value={values.color}
                          onChange={event => {
                            setValues(() => ({
                              ...values,
                              color: event.target.value
                            }))
                          }}
                        />
                      </div>
                      <Button
                        type="button"
                        color="primary"
                        className='d-flex justify-content-center align-items-center px-2'
                        outline
                        onClick={() => {
                          document.getElementById("color-picker")?.click()
                        }}
                      >
                        <i className='bx bx-pencil font-size-22'></i>
                      </Button>
                    </div>
                    <Input
                      id="color-picker"
                      type="text"
                      className={`form-control mt-1 ps-0 cursor-pointer border-none outline-none shadow-none`}
                      placeholder='Ex. #3965FF'
                      value={values.color}
                      onChange={event => {
                        setValues(() => ({
                          ...values,
                          color: event.target.value
                        }))
                      }}
                    />
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="mb-3">
                    <Label>Description</Label>
                    <textarea
                      name="description"
                      rows="4"
                      placeholder='Ex. Marketing & Business: Unleash the Power of Strategy and Innovation to Propel Your Brand and Drive Success. Explore Effective Marketing Techniques, Market Trends, and Business Strategies for Sustainable Growth.'
                      className="form-control resize-none mt-1"
                      value={values.description}
                      onChange={(event) => onChange(event, "description", fieldValidation.length_2000)}
                    />
                    <small><i>Max {fieldValidation.length_2000} characters.</i></small>
                  </div>
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Images</Label>
                  <Row>
                    <Col md={6}>
                      <div className='border rounded p-2 mt-1'>
                        {
                          uploadedFile || (facultyData && facultyData.imagePath) ?
                            <div className='position-relative d-inline-block'>
                              <img
                                src={uploadedFile ? uploadedFile : facultyData && facultyData.imagePath ? facultyData.imagePath : ""}
                                alt="/"
                                style={{ height: "200px", maxWidth: "100%" }}
                                className="rounded"
                              />
                              <Button
                                style={{ position: "absolute", top: 5, right: 5 }}
                                color="danger"
                                className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  setChanges(true);
                                  if (uploadedFile) {
                                    setUploadedFile(null);
                                    setValues((values) => ({
                                      ...values,
                                      image: null,
                                    }));
                                  } else {
                                    removeImageFromPaths(facultyData.imagePath, "imagePath", setImageNameToBeRemoved)
                                  }
                                }}
                              >
                                <i className="bx bx-x font-size-22"></i>
                              </Button>
                            </div>
                            : null
                        }
                        {
                          facultyData && facultyData.imagePath ?
                            <br />
                            : null
                        }
                        {
                          !uploadedFile && (!facultyData || (facultyData && !facultyData.imagePath)) ?
                            <Button
                              type="button"
                              color={`${!uploadedFile ? "primary" : "danger"}`}
                              className={`px-4 ${facultyData && facultyData.imagePath ? "mt-2" : ""}`}
                              onClick={(event) => {
                                if (!uploadedFile) {
                                  MainService.triggerUploadClick(event);
                                }
                              }}
                            >
                              Upload image
                              <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, "image", setUploadedFile)} />
                            </Button>
                            : null
                        }
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='border rounded p-2 mt-1'>
                        {
                          uploadedLogo || (facultyData && facultyData.logoPath) ?
                            <div className='position-relative d-inline-block'>
                              <img
                                src={uploadedLogo ? uploadedLogo : facultyData && facultyData.logoPath ? facultyData.logoPath : ""}
                                alt="/"
                                style={{ height: "200px", maxWidth: "100%" }}
                                className="rounded"
                              />
                              <Button
                                style={{ position: "absolute", top: 5, right: 5 }}
                                color="danger"
                                className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  setChanges(true);
                                  if (uploadedLogo) {
                                    setUploadedLogo(null);
                                    setValues((values) => ({
                                      ...values,
                                      logo: null,
                                    }));
                                  } else {
                                    removeImageFromPaths(facultyData.logoPath, "logoPath", setLogoNameToBeRemoved)
                                  }
                                }}
                              >
                                <i className="bx bx-x font-size-22"></i>
                              </Button>
                            </div>
                            : null
                        }
                        {
                          facultyData && facultyData.logoPath ?
                            <br />
                            : null
                        }
                        {
                          !uploadedLogo && (!facultyData || (facultyData && !facultyData.logoPath)) ?
                            <Button
                              type="button"
                              color={`${!uploadedLogo ? "primary" : "danger"}`}
                              className={`px-4 ${facultyData && facultyData.logoPath ? "mt-2" : ""}`}
                              onClick={(event) => {
                                if (!uploadedLogo) {
                                  MainService.triggerUploadClick(event);
                                }
                              }}
                            >
                              Upload logo
                              <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, "logo", setUploadedLogo)} />
                            </Button>
                            : null
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <div className='border rounded pb-3 pt-2'>

                      <Col md={12}>
                        <div className="">
                          <Label>Main video</Label>
                          <Input
                            type="text"
                            className={`form-control`}
                            placeholder="Ex. https://www.youtube.com/watch?v=X7FIU7PI06o"
                            value={values.mainVideoLink}
                            onChange={(event) => onChange(event, "mainVideoLink")}
                          />
                        </div>
                      </Col>
                      <hr />
                      <Col sm={12}>
                        <Label>Additional videos</Label>
                        <div>
                          {
                            values.videoLinks.length ?
                              <Col lg={12}>
                                {
                                  values.videoLinks.map((item, index) => {
                                    return <div key={index} className='mb-3 d-flex align-items-center'>
                                      <div className='w-100'>
                                        <Input
                                          type="text"
                                          className={`form-control ${isInvalidVideoLink && !item.videoTitle.trim().length ? "error-border" : ""}`}
                                          placeholder="Video Title"
                                          value={item.videoTitle}
                                          onChange={(event) => onVideoChange(event, index, "videoTitle")}
                                        />
                                        <Input
                                          type="text"
                                          className={`form-control mt-1 ${isInvalidVideoLink && !item.videoLink.trim().length ? "error-border" : ""}`}
                                          placeholder="Video Link Ex. https://www.youtube.com/watch?v=X7FIU7PI06o"
                                          value={item.videoLink}
                                          onChange={(event) => onVideoChange(event, index, "videoLink")}
                                        />

                                      </div>
                                      {
                                        values.videoLinks.length > 1 ?
                                          <div>
                                            <Button
                                              color="danger"
                                              outline
                                              type='button'
                                              className="btn-sm p-2 d-flex justify-content-center align-items-center ms-1"
                                              onClick={() => {
                                                removeVideoLink(index)
                                              }}
                                            >
                                              <i className="bx bx-trash font-size-22"></i>
                                            </Button>
                                          </div>
                                          : null
                                      }
                                    </div>
                                  })
                                }
                                <div className='d-flex justify-content-end'>
                                  <Button
                                    type='button'
                                    color="primary"
                                    outline
                                    className=" btn-sm d-flex justify-content-center align-items-center"
                                    onClick={addVideoLink}
                                  >
                                    <span className='font-size-18 me-1'>+</span>
                                    <span className='font-size-12 me-1'>Add Video</span>
                                  </Button>
                                </div>
                              </Col>
                              : null
                          }
                        </div>
                      </Col>
                    </div>
                  </Row>
                </Col>
              </Row>
              <div className='d-flex justify-content-end mt-3'>
                <ActionButton
                  type="submit"
                  name="Save"
                  disabled={!changes}
                  spinnerClass={`w_50`}
                  className="btn btn-primary btn-block px-4"
                  spinnerId={buttonSpinnerId}
                />
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
