import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import LanguageSaga from './language/saga';
import TransaltionSaga from './translations/saga';

export default function* rootSaga() {
  yield all([
    AccountSaga(),
    ProfileSaga(),
    LayoutSaga(),
    LanguageSaga(),
    TransaltionSaga(),
    fork(AuthSaga),
  ])
}
