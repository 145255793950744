import React, { useCallback, useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Table } from "react-bootstrap";
import AlertService from "../../../services/alertService";
import { ERROR_KEY, SUCCESS_KEY } from "../../../constants/mainKeys";
import ApiService from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import NoData from "../../../components/NoData/NoData";
import FormLanguage from "../../../components/FormLanguage/FormLanguage";
import {
	addButtonSpinner,
	addPageSpinner,
	removeButtonSpinner,
	removePageSpinner,
} from "../../../store/actions";
import uuid from "react-uuid";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";

export default function Lecturers(props) {
	const dispatch = useDispatch();
	const { history } = props;
	const pageSize = 10;
	const { language, formLanguage } = useSelector(state => state.Languages);
	const [lectueres, setLecturers] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);

	useEffect(() => {
		if (formLanguage) {
			getLecturers(1);
		}
		return () => {
			setLecturers([]);
		};
	}, [formLanguage]);

	const handlePageClick = event => {
		getLecturers(event.selected + 1);
		setActivePageNumber(event.selected);
	};

	const getLecturers = currentPage => {
		if (!formLanguage || !currentPage) {
			return false;
		}
		const spinnerId = uuid();
		setPageSpinner(spinnerId);
		ApiService.getLecturers(formLanguage.id, currentPage, pageSize)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				if (response && response.data) {
					setLecturers(response.data);
				}
				extractPageSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const deleteLecturer = lecturerId => {
		if (!lecturerId) {
			return false;
		}
		const spinnerId = uuid();
		AlertService.alertConfirm(`Are you sure ?`, "", "Yes", "No").then(() => {
			setPageSpinner(spinnerId);
			ApiService.deleteLecturer(lecturerId)
				.then(response => {
					setLecturers(lectueres.filter(item => item.id !== +lecturerId));
					AlertService.alert(SUCCESS_KEY, "Data saved");
					extractPageSpinner(spinnerId);
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const setButtonSpinner = useCallback(spinner => {
		dispatch(addButtonSpinner(spinner));
	}, []);

	const setPageSpinner = useCallback(spinner => {
		dispatch(addPageSpinner(spinner));
	}, []);

	const extractButtonSpinner = useCallback(spinner => {
		dispatch(removeButtonSpinner(spinner));
	}, []);

	const extractPageSpinner = useCallback(spinner => {
		dispatch(removePageSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPageSpinner(spinnerId);
		spinnerId && extractButtonSpinner(spinnerId);
	};

	return (
		<div className="page-content position-relative">
			<div className="container-fluid">
				<Breadcrumbs title="Ardy" breadcrumbItem="Users" />
				<FormLanguage />
				<hr className="mt-0" />
				<Row className="mb-3">
					<Col>
						<h4 className="mt-0 font-size-16">Welcome to users page</h4>
						<ShowMoreText
							lines={2}
							more="Read more"
							less="Less"
							className="mb-3 mt-0 more-less-text"
							anchorClass="underline cursor-pointer pt-0"
							expanded={false}
							truncatedEndingComponent={
								<span>
									...
									<br />
								</span>
							}>
							<div className="form-info-text">
								<p className="mb-1">
									On this page, you can manage and view information about your
									users. You can create, edit, or delete user entries as
									needed. Below is a brief overview of how to work with
									users.
								</p>
								<p className="mb-1">
									{" "}
									<span className="fw-500">How to Create a User ? </span>To
									create a new user entry, click the "Create a User"
									button. You will be directed to the user creation form,
									where you can provide details such as the full name, position,
									bio, and an optional image for the user.
								</p>
								<p className="mb-1">
									{" "}
									<span className="fw-500">Edit: </span>Click the pencil icon to
									edit the details of a user.
								</p>
								<p className="mb-1">
									{" "}
									<span className="fw-500">Delete: </span>Click the trash can
									icon to delete a user. A confirmation dialog will appear
									to confirm the deletion.
								</p>
							</div>
						</ShowMoreText>
					</Col>
					<Col sm={12} className="d-flex justify-content-end">
						<Button
							color="primary"
							className="btn btn-primary"
							onClick={() => {
								history.push(`/${language}/user-form`);
							}}>
							Create a user
						</Button>
					</Col>
				</Row>
				<hr />
				{lectueres && lectueres.length ? (
					<Card>
						<CardBody>
							<Table responsive className="table mb-0 table-bordered">
								<thead>
									<tr>
										<th className="no-wrap">Image</th>
										<th className="no-wrap">Full Name</th>
										<th className="no-wrap">Position</th>
										<th className="no-wrap">Bio</th>
										<th className="no-wrap">Linkedin</th>
										<th className="no-wrap">Actions</th>
									</tr>
								</thead>
								<tbody>
									{lectueres.map((item, index) => (
										<tr key={index}>
											{item.imagePath ? (
												<td>
													<img
														className="rounded-circle avatar-sm"
														src={item.imagePath}
														alt="/"
													/>
												</td>
											) : (
												<td>
													<i className="bx bx-user font-size-24"></i>
												</td>
											)}
											<td>
												<span
													className="max-line-2 word-break-break-word"
													title={item.fullName}>
													{item.fullName}
												</span>
											</td>
											<td>
												<span
													className="max-line-2 word-break-break-word"
													title={item.position}>
													{item.position}
												</span>
											</td>
											<td>
												<span
													className="max-line-2 word-break-break-word"
													title={item.bio}>
													{item.bio}
												</span>
											</td>
											<td >
												<span
													className="max-line-2 word-break-break-all"
													title={item?.linkedinLink}>
													{item?.linkedinLink}
												</span>
											</td>
											<td className="d-flex border-none">
												<Button
													color="success"
													className="m-1 btn-sm d-flex justify-content-center align-items-center"
													title="Edit"
													outline
													onClick={() => {
														props.history.push(
															`/${language}/user-form/${item.id}`,
														);
													}}>
													<i className="mdi mdi-pencil font-size-18"></i>
												</Button>
												<Button
													color="danger"
													className="m-1 btn-sm d-flex justify-content-center align-items-center"
													title="Delete"
													outline
													onClick={() => deleteLecturer(item.id)}>
													<i className="mdi mdi-delete font-size-18"></i>
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
							{pagination &&
								pagination.TotalPages &&
								pagination.TotalPages > 1 ? (
								<div className=" mt-4 d-flex justify-content-end w-100">
									<ReactPaginate
										nextLabel="Next"
										onPageChange={handlePageClick}
										pageRangeDisplayed={3}
										marginPagesDisplayed={2}
										pageCount={pagination.TotalPages}
										previousLabel="Previous"
										pageClassName="page-item"
										pageLinkClassName="page-link"
										previousClassName="page-item"
										previousLinkClassName="page-link"
										nextClassName="page-item"
										nextLinkClassName="page-link"
										breakLabel="..."
										breakClassName="page-item"
										breakLinkClassName="page-link"
										containerClassName="pagination flex-wrap"
										activeClassName="active"
										renderOnZeroPageCount={null}
										forcePage={activePageNumber}
									/>
								</div>
							) : null}
						</CardBody>
					</Card>
				) : (
					<div>
						<NoData />
						<div className="my-4 d-flex justify-content-center flex-wrap">
							<b className="d-flex text-center text-wrap">
								You don't have created users yet
							</b>
							<b>
								<Link to={`/${language}/user-form`} className="ms-2">
									<span className="underline">Create a user</span>
									<i className="bx bx-link-external ms-1 d-inline"></i>
								</Link>
							</b>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
