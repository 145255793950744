import React, { useCallback, useState, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { ERROR_KEY, SUCCESS_KEY } from '../../../constants/mainKeys'
import { addPageSpinner, removePageSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../services/alertService'
import { useDispatch } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Label,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { Table } from "react-bootstrap"
import FormLanguage from '../../../components/FormLanguage/FormLanguage'
import ApiService from '../../../services/apiService'
import uuid from 'react-uuid'
import NoData from '../../../components/NoData/NoData'
import ShowMoreText from "react-show-more-text"
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'

const Courses = (props) => {

  const pageSize = 10;
  const { history } = props;
  const dispatch = useDispatch();
  const { language, formLanguage } = useSelector(state => state.Languages);
  const [courses, setCourses] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [courseFaculties, setCourseFaculties] = useState([]);
  const [courseTypes, setCourseTypes] = useState([]);
  const [filterData, setFilterData] = useState({
    languageId: formLanguage?.id,
    courseTypeId: null,
    facultyId: null
  })

  useEffect(() => {
    if (formLanguage) {
      getCourseFaculties();
      getCourseTypes();
    }
    return () => {
      setCourseFaculties([]);
      setCourseTypes([]);
    }
  }, [formLanguage])

  useEffect(() => {
    if (formLanguage) {
      getCourses(1);
    }
    return () => {
      setCourses([]);
    }
  }, [formLanguage, filterData])

  const getCourseFaculties = () => {
    if (!formLanguage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getFaculties(formLanguage.id).then(response => {
      if (response && response.data) {
        const data = [...response.data];
        data.unshift({ title: "All", id: null })
        setCourseFaculties(data);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const onSelectOptionChange = (item, name) => {
    if (!item) { return false; }
    setFilterData((values) => ({
      ...values,
      [name]: item.value
    }))
  }

  const getCourseTypes = () => {
    if (!formLanguage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getCourseTypes(formLanguage.id).then(response => {
      if (response && response.data) {
        const data = [...response.data];
        data.unshift({ title: "All", id: null })
        setCourseTypes(data);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const handlePageClick = (event) => {
    getCourses(event.selected + 1);
    setActivePageNumber(event.selected);
  };

  const getCourses = (currentPage) => {
    if (!formLanguage || !currentPage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getCourses(currentPage, pageSize, filterData).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setCourses(response.data);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteCourse = (courseId) => {
    if (!courseId) { return false; }
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId)
      ApiService.deleteCourse(courseId).then(response => {
        setCourses(courses.filter(item => item.id !== +courseId));
        AlertService.alert(SUCCESS_KEY, "Data saved");
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="Courses"
        />
        <FormLanguage />
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col>
            <h4 className="mt-0 font-size-16">
              Welcome to courses page
            </h4>
            <ShowMoreText
              lines={2}
              more="Read more"
              less="Less"
              className="mb-3 mt-0 more-less-text"
              anchorClass="underline cursor-pointer pt-0"
              expanded={false}
              truncatedEndingComponent={<span>...<br /></span>}
            >
              <div className='form-info-text'>
                <p className='mb-1'>As an administrator, you have full control over the courses within your education platform. You can view, edit and manage all the courses available to you. Here, you'll find a comprehensive list of all the courses on your platform. These are the courses that administrators like you have created and maintain.</p>
                <p className='mb-1'> <span className='fw-500'>Edit Course: </span>Want to make updates or changes to the course? As an administrator, you have the right to edit course details. Click the "Edit" button to make the necessary changes.</p>
                <p className='mb-1'> <span className='fw-500'>Delete Course: </span>If there's a course that should no longer be available, you can also delete it right here. Please remember that this action is irreversible.</p>
                <p className='mb-1'> <span className='fw-500'>Course Media: </span>
                  In the Course "Media" section, you can manage the visual and multimedia elements associated with each course. Administrators have the ability to add or remove media resources to improve the overall learning experience.
                  To enrich the course content, click the "Add Media" button. You can upload an image, video and certificate of the current course.
                  If a media resource is no longer needed or requires updating, administrators can easily delete it by clicking the "Delete" button.
                </p>
              </div>
            </ShowMoreText>
          </Col>
          <Col sm={12}>
            <hr className='mb-1' />
            <div className='d-flex justify-content-between align-items-end flex-wrap'>

              <div className='d-flex justify-content-between align-items-end flex-wrap'>
                <p className='m-2 mb-0 mb-sm-3'><b>Filter by:</b></p>
                <div className='d-flex flex-wrap'>
                  {
                    courseTypes && courseTypes.length ?

                      <div style={{ minWidth: "200px" }} className='m-2'>
                        <Label className='fw-500 font-size-14'>Course type</Label>
                        <ReactSelectOption
                          value={filterData.courseTypeId}
                          selectedValue={(() => {
                            const selectedItem = { ...courseTypes.find(data => data.id === filterData.courseTypeId) };
                            if (Object.keys(selectedItem).length) {
                              selectedItem.value = selectedItem.id;
                              selectedItem.label = selectedItem.title;
                              return selectedItem;
                            } else {
                              return { label: "Course type", value: "" }
                            }
                          })()}
                          className={`rounded`}
                          items={courseTypes.map(data => ({ ...data, label: data.title, value: data.id }))}
                          onChange={item => onSelectOptionChange(item, "courseTypeId")}
                        />
                      </div>
                      : null
                  }
                  {
                    courseFaculties && courseFaculties.length ?
                      <div style={{ minWidth: "200px" }} className='m-2'>
                        <Label className='fw-500 font-size-14'>Faculty type</Label>
                        <ReactSelectOption
                          value={filterData.facultyId}
                          selectedValue={(() => {
                            const selectedItem = { ...courseFaculties.find(data => data.id === filterData.facultyId) };
                            if (Object.keys(selectedItem).length) {
                              selectedItem.value = selectedItem.id;
                              selectedItem.label = selectedItem.title;
                              return selectedItem;
                            } else {
                              return { label: "Faculty type", value: "" }
                            }
                          })()}
                          className={`rounded`}
                          items={courseFaculties.map(data => ({ ...data, label: data.title, value: data.id }))}
                          onChange={item => onSelectOptionChange(item, "facultyId")}
                        />
                      </div>
                      : null
                  }
                </div>
              </div>
              <div>
                <Button
                  color="primary"
                  className="btn btn-primary m-2"
                  onClick={() => {
                    history.push(`/${language}/course-form`)
                  }}
                >
                  Create a course
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        {
          courses && courses.length ?
            <Card>
              <CardBody>
                <Table responsive className="table mb-0 table-bordered">
                  <thead>
                    <tr>
                      <th className='no-wrap'>Image</th>
                      <th className='no-wrap'>Title</th>
                      <th className='no-wrap'>Active</th>
                      <th className='no-wrap'>Course Type</th>
                      <th className='no-wrap'>Faculty Type</th>
                      <th className='no-wrap'>Show on home page</th>
                      <th className='no-wrap'>Media</th>
                      <th className='no-wrap'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courses.map((item, index) => (
                      <tr key={index}>
                        {
                          item.imagePath ?
                            <td>
                              <div
                                className="avatar-md"
                                style={{
                                  backgroundImage: `url(${item.imagePath})`,
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                }}
                              />
                            </td>
                            : <td>
                              <i className='bx bx-image font-size-30'></i>
                            </td>
                        }
                        <td>
                          <span className='word-break-break-word max-line-2' title={item.title}> {item.title}</span>
                        </td>
                        <td>
                          {
                            item.isActive ?
                              <span className="badge badge-soft-success font-size-14">Yes</span>
                              :
                              <span className="badge badge-soft-danger font-size-14">No</span>
                          }
                        </td>
                        <td>
                          {
                            courseTypes && courseTypes.length ?
                              courseTypes.map(courseType => {
                                if (courseType.id === item.courseType) {
                                  return courseType.title
                                }
                              }) : null
                          }
                        </td>
                        <td>
                          {
                            courseFaculties && courseFaculties.length ? courseFaculties.map(courseFaculty => {
                              if (courseFaculty.id === item.facultyType) {
                                return courseFaculty.title
                              }
                            }) : null
                          }
                        </td>
                        <td>
                          {
                            item.showOnHomePage ?
                              <span className="badge badge-soft-success font-size-14">Yes</span>
                              :
                              <span className="badge badge-soft-danger font-size-14">No</span>
                          }
                        </td>
                        <td className='text-primary'>
                          <Link to={`/${language}/course-media/${item.id}`} className="d-flex align-items-center no-wrap">
                            <span className='underline'>Add media</span>
                            <i className='bx bx-link-external ms-1 d-inline'></i>
                          </Link>
                        </td>
                        <td className='d-flex border-none'>
                          <Button
                            color="success"
                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                            title='Edit'
                            outline
                            onClick={() => {
                              props.history.push(`/${language}/course-form/${item.id}`)
                            }}
                          >
                            <i className='mdi mdi-pencil font-size-18'></i>
                          </Button>
                          <Button
                            color="danger"
                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                            title='Delete'
                            outline
                            onClick={() => deleteCourse(item.id)}
                          >
                            <i className='mdi mdi-delete font-size-18'></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {
                  pagination && pagination.TotalPages && pagination.TotalPages > 1 ?
                    <div className=' mt-4 d-flex justify-content-end w-100'>
                      <ReactPaginate
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pagination.TotalPages}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination flex-wrap"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        forcePage={activePageNumber}
                      />
                    </div>
                    : null
                }
              </CardBody>
            </Card>
            : <div>
              <NoData />
              <div className='my-4 d-flex justify-content-center flex-wrap'>
                <b className='d-flex text-center text-wrap'>
                  You don't have created courses yet
                </b>
                <b>
                  <Link to={`/${language}/course-form`} className="ms-2">
                    <span className='underline'>Create a course</span>
                    <i className='bx bx-link-external ms-1 d-inline'></i>
                  </Link>
                </b>
              </div>
            </div>
        }

      </div>
    </div>
  )
}

export default withRouter(Courses)