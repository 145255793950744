import React, { useState, useEffect, useCallback } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ActionButton from "../../../components/Buttons/ActionButton";
import uuid from "react-uuid";
import MainService from "../../../services/mainService";
import {
	ERROR_KEY,
	MAXIMUM_UPLOAD_FILE_SIZE,
	SUCCESS_KEY,
	VALID_IMAGE_TYPES_KEY,
	fieldValidation,
} from "../../../constants/mainKeys";
import AlertService from "../../../services/alertService";
import ApiService from "../../../services/apiService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
	addButtonSpinner,
	addPageSpinner,
	removeButtonSpinner,
	removePageSpinner,
} from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import {
	Label,
	Row,
	Col,
	Input,
	Card,
	CardBody,
	Form,
	Button,
} from "reactstrap";
import FormLanguage from "../../../components/FormLanguage/FormLanguage";
import ShowMoreText from "react-show-more-text";

const buttonSpinnerId = uuid();

export default function LecturerForm(props) {
	const dispatch = useDispatch();
	const mainService = new MainService();
	const { language, languages, formLanguage } = useSelector(
		state => state.Languages,
	);
	const { history } = props;
	const [values, setValues] = useState({
		fullName: "",
		position: "",
		bio: "",
		image: null,
		linkedinLink: "",
	});
	const { lecturerId } = useParams();
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [lecturerData, setLecturerData] = useState(null);
	const [changes, setChanges] = useState(false);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [imageNameToBeRemoved, setImageNameToBeRemoved] = useState(null);

	useEffect(() => {
		if (lecturerId && formLanguage) {
			getLecturerDataById();
		}
	}, [lecturerId, formLanguage]);

	const getLecturerDataById = () => {
		const spinnerId = uuid();
		setPageSpinner(spinnerId);
		ApiService.getLecturerDataById(+lecturerId, formLanguage.id)
			.then(response => {
				if (response && response.data) {
					const data = { ...response.data };
					setLecturerData(data);
					setValues({
						fullName: data.fullName || "",
						position: data.position || "",
						bio: data.bio || "",
						image: null,
						linkedinLink: data.linkedinLink || "",
					});
				}
				extractPageSpinner(spinnerId);
			})
			.catch(error => getFail(error));
	};

	const onChange = (event, field, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		setValues(values => ({
			...values,
			[field]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const uploadFile = (event, field, cb) => {
		if (!event.target.files[0]) {
			return;
		}
		const file = event.target.files[0];
		if (file) {
			if (file.size >= MAXIMUM_UPLOAD_FILE_SIZE * 1024 * 1024) {
				AlertService.alert("warning", "Incorrect file size");
				return false;
			}
			mainService
				.readFile(file, VALID_IMAGE_TYPES_KEY)
				.then(uploadedImage => {
					cb(uploadedImage);
					setValues(values => ({
						...values,
						[field]: file,
					}));
				})
				.catch(error => {
					error && AlertService.alert("error", "Invalid file format");
					cb(null);
				});
		}
	};

	const removeImageFromPaths = (imagePath, fieldName, cb) => {
		const parts = imagePath.split("/");
		const filename = parts[parts.length - 1];
		cb(filename);
		setLecturerData(values => ({
			...values,
			[fieldName]: null,
		}));
	};

	const onSubmit = event => {
		event && event.preventDefault();
		if (!formLanguage) {
			return false;
		}
		if (!values.fullName.trim().length || !values.position.trim().length) {
			setIsInvalidSubmit(true);
			MainService.scrollToInvalidFieldPosition();
			return false;
		}
		setButtonSpinner(buttonSpinnerId);
		const formData = new FormData();
		// Object.entries(values).forEach(([key, value]) => {
		// 	formData.append(key, value);
		// });
		if (lecturerId) formData.append("id", +lecturerId);
		if (imageNameToBeRemoved) {
			formData.append("imageNameToBeRemoved", imageNameToBeRemoved);
		}
		formData.append("fullName", values.fullName);
		formData.append("position", values.position);
		formData.append("bio", values.bio && values.bio.trim().length ? values.bio : "");
		formData.append("image", values.image);
		formData.append("languageId", formLanguage.id);
		formData.append("linkedinLink", values.linkedinLink && values.linkedinLink.trim().length ? values.linkedinLink : "");

		(lecturerId
			? ApiService.updateLecturer(formData)
			: ApiService.createLecturer(formData)
		)
			.then(response => {
				AlertService.alert(SUCCESS_KEY, "Data saved");
				extractButtonSpinner(buttonSpinnerId);
				history.push(`/${language}/users`);
			})
			.catch(error => getFail(error, buttonSpinnerId));
	};

	const setButtonSpinner = useCallback(spinner => {
		dispatch(addButtonSpinner(spinner));
	}, []);

	const setPageSpinner = useCallback(spinner => {
		dispatch(addPageSpinner(spinner));
	}, []);

	const extractButtonSpinner = useCallback(spinner => {
		dispatch(removeButtonSpinner(spinner));
	}, []);

	const extractPageSpinner = useCallback(spinner => {
		dispatch(removePageSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPageSpinner(spinnerId);
		spinnerId && extractButtonSpinner(spinnerId);
	};

	return (
		<div className="page-content position-relative">
			<div className="container-fluid">
				<Breadcrumbs title="Ardy" breadcrumbItem="User Form" />
				<FormLanguage />
				<hr className="mt-0" />
				<ShowMoreText
					lines={2}
					more="Read more"
					less="Less"
					className="mb-3 mt-0 more-less-text"
					anchorClass="underline cursor-pointer pt-0"
					expanded={false}
					truncatedEndingComponent={
						<span>
							...
							<br />
						</span>
					}>
					<div className="form-info-text">
						<p className="mb-1">
							This page allows you to create or update user information. You
							can enter the user's full name, position, and bio.
							Additionally, you can upload an image associated with the
							user.
						</p>
						<p className="mb-1">
							{" "}
							<span className="fw-500">Full Name: </span>Enter the full name of
							the user. This field is required and should not exceed{" "}
							{fieldValidation.length_100} characters.
						</p>
						<p className="mb-1">
							{" "}
							<span className="fw-500">Position: </span>Optionally, specify the
							position of the user. This field should not exceed{" "}
							{fieldValidation.length_100} characters.
						</p>
						<p className="mb-1">
							{" "}
							<span className="fw-500">Bio: </span>Provide a biography or
							description of the user. You can enter up to{" "}
							{fieldValidation.length_2000} characters.
						</p>
						<p className="mb-1">
							{" "}
							<span className="fw-500">Image: </span>You can upload an image of
							the user. Supported image formats are .jpg, .jpeg, .png, and
							.svg. The maximum file size is {MAXIMUM_UPLOAD_FILE_SIZE} MB.
						</p>
						<p className="mb-1">
							Once you've filled in the necessary information and uploaded an
							image (if needed), click the "Save" button to save the data. The
							button will be enabled when there are unsaved changes.
						</p>
					</div>
				</ShowMoreText>
				<Card>
					<CardBody>
						<Form onChange={() => setChanges(true)} onSubmit={onSubmit}>
							<Row>
								<Col md={6}>
									<div className="mb-3">
										<Label>Full Name*</Label>
										<Input
											type="text"
											className={`form-control ${isInvalidSubmit && !values.fullName.trim().length
												? "error-border"
												: ""
												}`}
											placeholder="Full Name"
											value={values.fullName}
											onChange={event =>
												onChange(event, "fullName", fieldValidation.length_100)
											}
										/>
										<small>
											<i>Max {fieldValidation.length_100} characters.</i>
										</small>
									</div>
								</Col>
								<Col md={6}>
									<div className="mb-3">
										<Label>Position*</Label>
										<Input
											type="text"
											className={`form-control ${isInvalidSubmit && !values.position.trim().length
												? "error-border"
												: ""
												}`}
											placeholder="Position"
											value={values.position}
											onChange={event =>
												onChange(event, "position", fieldValidation.length_100)
											}
										/>
										<small>
											<i>Max {fieldValidation.length_100} characters.</i>
										</small>
									</div>
								</Col>
								<Col md={12} lg={6}>
									<div className="mb-3">
										<Label>LinkedIn link</Label>
										<Input
											type="text"
											className={`form-control`}
											placeholder="EX. https://www.linkedin.com/feed/"
											value={values.linkedinLink}
											onChange={event =>
												onChange(
													event,
													"linkedinLink",
													fieldValidation.length_100,
												)
											}
										/>
										<small>
											<i>Max {fieldValidation.length_100} characters.</i>
										</small>
									</div>
								</Col>
								<Col md={12}>
									<div className="mb-3">
										<Label>Bio</Label>
										<textarea
											name="bio"
											rows="4"
											placeholder="Value"
											className="form-control resize-none mt-1"
											value={values.bio}
											onChange={event =>
												onChange(event, "bio", fieldValidation.length_2000)
											}
										/>
										<small>
											<i>Max {fieldValidation.length_2000} characters.</i>
										</small>
									</div>
								</Col>
								<Col md={12}>
									<div className="mb-3">
										{uploadedFile ||
											(lecturerData && lecturerData.imagePath) ? (
											<div className="position-relative d-inline-block">
												<img
													src={
														uploadedFile
															? uploadedFile
															: lecturerData && lecturerData.imagePath
																? lecturerData.imagePath
																: ""
													}
													alt="/"
													style={{ height: "200px" }}
													className="img-thumbnail"
												/>
												<Button
													style={{ position: "absolute", top: 5, right: 5 }}
													color="danger"
													className="btn-sm p-0 d-flex justify-content-center align-items-center"
													onClick={() => {
														setChanges(true);
														if (uploadedFile) {
															setUploadedFile(null);
															setValues(values => ({
																...values,
																image: null,
															}));
														} else {
															removeImageFromPaths(
																lecturerData.imagePath,
																"imagePath",
																setImageNameToBeRemoved,
															);
														}
													}}>
													<i className="bx bx-x font-size-22"></i>
												</Button>
											</div>
										) : null}
										{lecturerData && lecturerData.imagePath ? <br /> : null}
										{!uploadedFile &&
											(!lecturerData ||
												(lecturerData && !lecturerData.imagePath)) ? (
											<Button
												type="button"
												color={`${!uploadedFile ? "primary" : "danger"}`}
												className={`px-4 ${lecturerData && lecturerData.imagePath ? "mt-2" : ""
													}`}
												onClick={event => {
													if (!uploadedFile) {
														MainService.triggerUploadClick(event);
													}
												}}>
												Upload image
												<input
													type="file"
													id="avatarImage"
													className="d-none"
													hidden
													onChange={event =>
														uploadFile(event, "image", setUploadedFile)
													}
												/>
											</Button>
										) : null}
									</div>
								</Col>
							</Row>
							<div className="d-flex justify-content-end mt-3">
								<ActionButton
									type="submit"
									name="Save"
									disabled={!changes}
									spinnerClass={`w_50`}
									className="btn btn-primary btn-block px-4"
									spinnerId={buttonSpinnerId}
								/>
							</div>
						</Form>
					</CardBody>
				</Card>
			</div>
		</div>
	);
}
