import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { useSelector } from 'react-redux'
import AlertService from '../../../services/alertService'
import { ERROR_KEY } from '../../../constants/mainKeys'
import ApiService from '../../../services/apiService'
import { useDispatch } from 'react-redux'
import { Col, Button, Label, Input, Form } from 'reactstrap'
import FormLanguage from '../../../components/FormLanguage/FormLanguage'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import ActionButton from '../../../components/Buttons/ActionButton'
import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor'

const buttonSpinnerId = uuid();

export default function Faqs(props) {
  const dispatch = useDispatch()
  const { formLanguage } = useSelector(state => state.Languages);
  const [isInvalidFaq, setIsInvalidFaq] = useState(false);
  const [changes, setChanges] = useState(false);

  const [values, setValues] = useState({
    key: "FAQ",
    value: [
      {
        question: "",
        answer: "",
        isForCourses: false
      }
    ]
  });

  useEffect(() => {
    getFaq();
  }, [formLanguage])

  const getFaq = () => {
    if (!formLanguage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    setValues({
      key: "",
      value: []
    })
    ApiService.getFaq(formLanguage.id).then(response => {
      if (response && response.data) {
        setValues(response.data);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const addFaq = () => {
    let emptyField = values.value.find(item => !item.question.trim().length || !item.answer.trim().length);
    if (emptyField) {
      setIsInvalidFaq(true);
      return false;
    }
    const newFields = {
      question: "",
      answer: ""
    };
    let newFaq = { ...values };
    newFaq.value.push(newFields);
    setValues(newFaq);
  };

  const removeFaq = (index) => {
    const newFaqs = { ...values };
    newFaqs.value = newFaqs.value.filter((_, i) => i !== index);
    setValues(newFaqs);
    setIsInvalidFaq(false);
    setChanges(true);
  };

  const onFaqChange = (event, index, name, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    const newFaq = { ...values };
    if (name === "isForCourses") {
      newFaq.value[index][name] = event.target.checked;
    }
    else {
      newFaq.value[index][name] = event.target.value;
    }

    setValues(newFaq);
    setIsInvalidFaq(false);
  }

  const onEditorChange = (event, index, name, maxLength = Infinity) => {
    const newValue = event.editor.getData();
    if (maxLength && newValue.length >= maxLength) return;
    const newValues = { ...values };
    newValues.value[index][name] = newValue;
    setValues(newValues);
    setChanges(true);
  }

  const onChangeKeyValue = (event, fieldPath, maxLength = Infinity) => {
    const newValue = event.target.value;
    if (maxLength && newValue.length >= maxLength) return;
    const newValues = { ...values };
    let targetObject = newValues;
    const fieldPathArray = fieldPath.split('.');
    for (let i = 0; i < fieldPathArray.length - 1; i++) {
      targetObject = targetObject[fieldPathArray[i]];
    }
    targetObject[fieldPathArray[fieldPathArray.length - 1]] = newValue;
    setValues(newValues);
  };


  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!formLanguage) { return false; }
    setButtonSpinner(buttonSpinnerId);
    values.languageId = formLanguage.id;
    ApiService.setFaq(values).then(() => {
      setChanges(false);
      extractButtonSpinner(buttonSpinnerId);
      AlertService.alert("success", "Data saved")
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }


  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="FAQ"
        />
        <FormLanguage />
        <hr />
        <Form
          className='mb-3'
          onChange={() => setChanges(true)}
          onSubmit={onSubmit}
        >
          <div className='d-flex justify-content-end '>
            <ActionButton
              type="submit"
              name="Save"
              disabled={!changes}
              spinnerClass={`w_50`}
              className="btn btn-primary btn-block px-4"
              spinnerId={buttonSpinnerId}
            />
          </div>
          <Label>FAQ (Frequently Asked Questions)</Label>
          <div className='border rounded p-2 mb-3'>
            <Input
              type="text"
              className={`form-control my-1`}
              placeholder="Ex. FAQ (key)"
              value={values.key}
              onChange={(event) => onChangeKeyValue(event, "key")}
            />

            {
              values.value.length ?
                <Col lg={12}>
                  {
                    <div className="">
                      {
                        values.value.map((item, index) => {
                          return <div key={index} className='my-2 d-flex align-items-center border rounded p-2'>
                            <div className='w-100'>
                              <small className='fw-500'>Question {index + 1}</small>
                              <Input
                                type="text"
                                className={`form-control ${(isInvalidFaq && !item.question.trim().length) ? "error-border" : ""}`}
                                placeholder="Question"
                                value={item.question}
                                onChange={(event) => onFaqChange(event, index, "question")}
                              />
                              <small className='fw-500 mt-2 d-block'>Answer {index + 1}</small>
                              {
                                typeof item.answer === "string" ?
                                  <RichTextEditor
                                    value={item.answer}
                                    blockClassName="custom-rich-text-editor small-rich-text-editor mt-1 rounded overflow-hidden"
                                    lableClassName="fw-500 font-size-14"
                                    onEditorChange={event => onEditorChange(event, index, "answer")}
                                  />
                                  : null
                              }
                              {/* <textarea
                                rows="2"
                                className={`
                                            form-control 
                                            resize-none mt-1 
                                            ${(isInvalidFaq && !item.answer.trim().length) ? "error-border" : ""}
                                            `}
                                placeholder='Answer'
                                value={item.answer}
                                onChange={(event) => onFaqChange(event, index, "answer")}
                              /> */}
                              <div className="form-check mt-2 ms-2">
                                {item.isForCourses}
                                <input id={`is_for_courses_${index}`} className="form-check-input" onChange={(event) => onFaqChange(event, index, "isForCourses")} checked={item.isForCourses ? true : false} type="checkbox" value={item.isForCourses ? true : false} />
                                <label className="form-check-label" htmlFor={`is_for_courses_${index}`}>
                                  Is for courses
                                </label>
                              </div>
                            </div>
                            {
                              values.value.length > 1 ?
                                <div>
                                  <Button
                                    color="danger"
                                    outline
                                    type='button'
                                    className="btn-sm p-2 d-flex justify-content-center align-items-center ms-1"
                                    onClick={() => {
                                      removeFaq(index)
                                    }}
                                  >
                                    <i className="bx bx-trash font-size-22"></i>
                                  </Button>
                                </div>
                                : null
                            }
                          </div>
                        })
                      }
                    </div>
                  }
                  <div className='d-flex justify-content-end'>
                    <Button
                      type='button'
                      color="primary"
                      outline
                      className=" btn-sm d-flex justify-content-center align-items-center"
                      onClick={addFaq}
                    >
                      <span className='font-size-18 me-1'>+</span>
                      <span className='font-size-12 me-1'>Add FAQ</span>
                    </Button>
                  </div>
                </Col>
                : null
            }
          </div>
          <div className='d-flex justify-content-end '>
            <ActionButton
              type="submit"
              name="Save"
              disabled={!changes}
              spinnerClass={`w_50`}
              className="btn btn-primary btn-block px-4"
              spinnerId={buttonSpinnerId}
            />
          </div>
        </Form>
      </div>
    </div>
  )
}
