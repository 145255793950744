import React from "react";
import BarLoader from "react-spinners/BarLoader";

function DefaultButtonSpinner(props) {

  return (
    props.spinner && props.spinner.length ?
      <div className={`sweet-loading d-flex ${props.spinnerClass}`}>
        <BarLoader size={20} color={props.spinnerColor} />
      </div>
      : null
  );
}

export default DefaultButtonSpinner;