import React, { useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { useSelector } from "react-redux"
import { LANGUAGE_KEY } from "../../../constants/mainKeys"
import { useCallback } from "react"
import { withRouter } from "react-router-dom"
import { setLanguage } from "../../../store/language/actions"
import { useDispatch } from "react-redux"
import PuffLoader from "react-spinners/PuffLoader";

const LanguageDropdown = (props) => {

  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const { language, languages } = useSelector(state => state.Languages);
  const { isShowLanguageSpinner } = useSelector(state => state.Spinners);

  const changeLanguage = isocode => {
    if (!isocode || isocode === language) { return false; }
    if (props.location.pathname.includes(language)) {
      const newPath = props.location.pathname.replace("/" + language + "/", "/" + isocode + "/");
      localStorage.setItem(LANGUAGE_KEY, isocode);
      setLanguageHandler(isocode);
      props.history.push(newPath.toString());
    }
  }

  const setLanguageHandler = useCallback(language => {
    dispatch(setLanguage(language));
  }, []);

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        {
          isShowLanguageSpinner ?
            <div className="d-flex justify-content-center align-items-center h-100 me-3">
              <PuffLoader size={20} />
            </div>
            : <DropdownToggle className="btn header-item" tag="button">
              <p className="text-uppercase underline mb-0">
                {language}
              </p>
            </DropdownToggle>
        }

        {
          languages && languages.length ?
            <DropdownMenu className="language-switch dropdown-menu-end">
              {
                languages.map(item => {
                  return <DropdownItem
                    key={item.id}
                    onClick={() => changeLanguage(item.isoCode2)}
                    className={`notify-item ${language === item.isoCode2 ? "active" : "none"
                      }`}
                  >
                    <span className="align-middle text-uppercase">
                      {item.isoCode2}
                    </span>
                  </DropdownItem>
                })
              }
            </DropdownMenu>
            : null
        }
      </Dropdown>
    </>
  )
}

export default withRouter(LanguageDropdown)
