import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom"
import ForgetPassword from '../../pages/unauthPages/ForgetPassword'
import Login from '../../pages/unauthPages/Login'
import Register from '../../pages/unauthPages/Register'
import RestorePassword from '../../pages/unauthPages/RestorePassword'
import { DEFAULT_LANGUAGE_KEY } from '../../constants/mainKeys'
import { useSelector } from 'react-redux'
import AuthenticationByRefreshToken from '../../pages/authenticationByRefreshToken/AuthenticationByRefreshToken'

export default function UnauthMiddleware() {

  const { language } = useSelector(state => state.Languages);

  return (
    <Switch>
      <Route path="/:language/login" exact component={Login} />
      <Route path="/:language/register" exact component={Register} />
      <Route path="/:language/forgot-password" exact component={ForgetPassword} />
      <Route path="/:language/restore-password" exact component={RestorePassword} />

      {/* AuthenticationByRefreshToken */}
      <Route path="/:language/authentication/:refreshToken" exact component={AuthenticationByRefreshToken} />
      
      <Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}/login`} />
    </Switch>
  )
}