import React, { useState, useEffect, useCallback } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { useSelector, useDispatch } from 'react-redux'
import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Input
} from "reactstrap"
import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor'
import { OBJECT_KEY, VALID_IMAGE_TYPES_KEY, ERROR_KEY, SUCCESS_KEY, PAGES_KEY, VALID_VIDEO_TYPES_KEY, fieldValidation } from '../../../constants/mainKeys'
import ProgressBar from 'react-bootstrap/ProgressBar'
import MainService from '../../../services/mainService'
import AlertService from '../../../services/alertService'
import uuid from 'react-uuid'
import ActionButton from '../../../components/Buttons/ActionButton'
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from '../../../store/spinner/actions'
import FormLanguage from '../../../components/FormLanguage/FormLanguage'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ApiService from '../../../services/apiService'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import ShowMoreText from "react-show-more-text"

const buttonSpinnerId = uuid();
export default function PageContentForm(props) {

  const dispatch = useDispatch();
  const mainService = new MainService();
  const { history } = props;
  const { pageName, sectionId, contentId } = useParams();
  const { language, formLanguage } = useSelector(state => state.Languages);
  const [isInvalidFileFormat, setIsInvalidFileFormat] = useState(false);
  const [fileReadPercent, setFileReadPercent] = useState(0);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [buttonTypes, setButtonTypes] = useState([]);
  const [contentData, setContentData] = useState(null);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [fileReadPercentName, setFileReadPercentName] = useState("");
  const [isShowInsertLink, setIsShowInsertLink] = useState(false);

  const [values, setValues] = useState({
    name: pageName ? pageName : "",
    sectionId: sectionId ? +sectionId : null,
    buttonType: null,
    buttonName: "",
    buttonRedirectPage: "",
    title: null,
    description: null,
    video: null,
    videoNameToRemove: [],
    videoPath: "",
    images: [],
    imageNamesToRemove: [],
    color: "#ffffff",
    videoLink: "",
  })

  useEffect(() => {
    if (contentId) {
      getPageContentById();
    } else {
      setValues(() => ({
        ...values,
        title: "",
        description: "",
      }))
    }
  }, [contentId, formLanguage])

  useEffect(() => {
    getButtonTypes();
  }, [])

  const getButtonTypes = () => {
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    ApiService.getButtonTypes().then(response => {
      if (response && response.data) {
        setButtonTypes(response.data)
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const onChange = (event, field, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false);
  }

  const getPageContentById = () => {
    if (!formLanguage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId);
    setValues(() => ({
      ...values,
      title: null,
      description: null,
    }))
    ApiService.getPageContentById(+contentId, formLanguage.id).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        setContentData(data);
        data.images = [];
        data.imageNamesToRemove = [];
        data.videoNameToRemove = [];
        if (data.button) {
          data.buttonType = data.button.buttonType;
          data.buttonRedirectPage = data.button.buttonRedirectPage;
          data.buttonName = data.button.buttonName;
        }
        if (data.videoLink) {
          setIsShowInsertLink(true);
        }else{
          data.videoLink = ""
        }
        data.title = data.title ? data.title : "";
        data.description = data.description ? data.description : "";
        setValues(data);
      } else {
        setValues({
          title: "",
          description: "",
          images: [],
          imageNamesToRemove: [],
          videoNameToRemove: []
        })
      }
      extractPageSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const onEditorChange = (event, field) => {
    setValues((values) => ({
      ...values,
      [field]: event.editor.getData()
    }));
    setIsInvalidSubmit(false);
  }

  const uploadFile = async (event, name) => {
    const newValues = { ...values };
    setIsInvalidFileFormat(false);
    setFileReadPercentName(name);
    if (event.target.files) {
      for (let i in event.target.files) {
        const file = event.target.files[i];
        if (file && typeof file === OBJECT_KEY) {
          const fileName = file.name;
          await mainService.readFile(file, name === "image" ? VALID_IMAGE_TYPES_KEY : VALID_VIDEO_TYPES_KEY, setFileReadPercent).then(uploadedFile => {
            if (name === "image") {
              newValues.images.push(
                {
                  fileName,
                  file,
                  uploadedFile
                }
              )
            } else {
              newValues.video = file;
              setUploadedVideo(uploadedFile);
            }
          }).catch(error => {
            error && AlertService.alert("error", "Invalid file format");
            setIsInvalidFileFormat(true);
            setFileReadPercent(0);
            setFileReadPercentName("");
          });
        }
      }
    }
    setValues(newValues);
  };

  const onSelectOptionChangeByValue = (item, name) => {
    if (!item) { return false; }
    setValues(() => ({
      ...values,
      [name]: item.value
    }))
    setIsInvalidSubmit(false);
  }

  const removeImage = (imageIndex) => {
    const newImages = [...values.images]
    newImages.splice(imageIndex, 1);
    setValues((values) => ({
      ...values,
      images: newImages
    }));
  }

  const removeImageFromImagePaths = (imageIndex) => {
    const newImagePaths = [...values.imagePaths];
    const newImageNamesToRemove = [...values.imageNamesToRemove];
    const parts = newImagePaths[imageIndex].split('/');
    const filename = parts[parts.length - 1];
    newImagePaths.splice(imageIndex, 1);
    newImageNamesToRemove.push(filename);
    setValues((values) => ({
      ...values,
      imagePaths: newImagePaths,
      imageNamesToRemove: newImageNamesToRemove,
    }));
  }

  const removeContentVideo = () => {
    let newVideoNameToRemove = [...values.videoNameToRemove]
    const parts = values.videoPath.split('/');
    const filename = parts[parts.length - 1];
    newVideoNameToRemove.push(filename);
    setValues(() => ({
      ...values,
      videoPath: "",
      videoNameToRemove: newVideoNameToRemove
    }))
    setContentData((prevState) => ({
      ...prevState,
      videoPath: null
    }))
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!formLanguage) { return false; }
    if (isShowInsertLink && !values.videoLink.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    }
    const formData = new FormData();
    if (contentId) formData.append("id", contentId);
    formData.append('sectionId', values.sectionId);
    formData.append('languageId', formLanguage.id);
    formData.append('title', values.title ? values.title : "");
    formData.append('description', values.description ? values.description : "");
    formData.append('videoLink', values.videoLink);
    if (values.color) formData.append('color', values.color);
    if (values.video) formData.append('video', values.video);
    if (values.buttonType) formData.append('buttonType', values.buttonType);
    if (values.buttonName) formData.append('buttonName', values.buttonName);
    if (values.buttonRedirectPage) formData.append('buttonRedirectPage', values.buttonRedirectPage);
    if (values.videoNameToRemove && values.videoNameToRemove.length) {
      formData.append('videoNameToRemove', values.videoNameToRemove);
    }
    if (values.images && values.images.length) {
      values.images.forEach(item => {
        formData.append('images', item.file);
      });
    }
    if (values.imageNamesToRemove && values.imageNamesToRemove.length) {
      values.imageNamesToRemove.forEach(item => {
        formData.append('imageNamesToRemove', item);
      });
    }
    setButtonSpinner(buttonSpinnerId);
    (contentId ? ApiService.updateContent(formData) : ApiService.createContent(formData)).then(response => {
      AlertService.alert(SUCCESS_KEY, "Data saved");
      extractButtonSpinner(buttonSpinnerId)
      history.push(`/${language}/pages`);
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="Page section form"
        />
        <FormLanguage />
        <hr className='mt-0' />
        <ShowMoreText
          lines={2}
          more="Read more"
          less="Less"
          className="mb-3 mt-0 more-less-text"
          anchorClass="underline cursor-pointer pt-0"
          expanded={false}
          truncatedEndingComponent={<span>...<br /></span>}
        >
          <div className='form-info-text'>
            <p className='mb-1'>This page is used to create or edit page content. It provides a user-friendly interface for managing various aspects of a page, including buttons, background color, text content, videos, and images.</p>
            <p className='mb-1'> <span className='fw-500'>Choose Button Type: </span>You can select a button type from a list of available options. Each button type is associated with a specific design.</p>
            <p className='mb-1'> <span className='fw-500'>Button Name and Redirect: </span>If you choose to include a button, you can specify its name and the page it should redirect to when clicked.</p>
            <p className='mb-1'> <span className='fw-500'>Background Color: </span>Customize the background color of the section using a color picker.</p>
            <p className='mb-1'> <span className='fw-500'>Rich Text Editor: </span>Use a rich text editor to create and edit the title and description for the page content. You can format text and add multimedia content.</p>
            <p className='mb-1'> <span className='fw-500'>Video: </span>Upload or link a video to your page content. You can choose to insert a link or directly upload a video file. Supported video formats include .mp4.</p>
            <p className='mb-1'> <span className='fw-500'>Images: </span>Add images to your content. You can upload multiple images in formats such as .jpg, .jpeg, .png, and .svg. Uploaded images will be displayed on the page.</p>

          </div>
        </ShowMoreText>
        <Card>
          <CardBody>

            <Form
              onSubmit={onSubmit}
            >
              <div>
                <Row >

                  {
                    buttonTypes && buttonTypes.length ?
                      <Col md={6} className='mb-2'>
                        <div>
                          <Label className='fw-500 font-size-14'>Choose the button type</Label>
                          <div className='d-flex'>
                            <ReactSelectOption
                              value={values.buttonType}
                              className={`rounded w-100`}
                              selectedValue={(() => {
                                const selectedItem = { ...buttonTypes.find(data => data.type === values.buttonType) };
                                if (Object.keys(selectedItem).length) {
                                  selectedItem.value = selectedItem.id;
                                  selectedItem.label = selectedItem.name;
                                  return selectedItem;
                                } else {
                                  return { label: "", value: "" }
                                }
                              })()}
                              items={buttonTypes.map(data => ({ ...data, label: data.name, value: data.type }))}
                              onChange={item => onSelectOptionChangeByValue(item, "buttonType")}
                            />
                            {
                              values.buttonType ?
                                <Button
                                  color="danger"
                                  outline
                                  type='button'
                                  className="btn-sm px-2 py-1 ms-1 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    setValues(() => ({
                                      ...values,
                                      buttonType: null,
                                      buttonName: "",
                                      buttonRedirectPage: "",
                                    }))
                                  }}
                                >
                                  <i className="bx bx-x font-size-18"></i>
                                </Button>
                                : null
                            }
                          </div>
                        </div>
                        {
                          values.buttonType ?
                            buttonTypes.map((item, index) => {
                              if (item.type === values.buttonType) {
                                return <div
                                  key={index}
                                  className='img-thumbnail mt-1'
                                  style={{
                                    backgroundImage: `url(${item.imagePath})`,
                                    height: "100px",
                                    width: "100%",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center"
                                  }}
                                />
                              }
                            })
                            : null
                        }
                      </Col>
                      : null
                  }
                  <Col sm={12}>
                    <div className="mb-3">
                      <Label>Section background color</Label>
                      <div className='d-flex'>
                        <div
                          className='border rounded overflow-hidden  me-1 p-1'
                          style={{ height: 38, width: 100 }}
                        >
                          <Input
                            id="color-picker"
                            type="color"
                            className={`form-control d-block p-0 h-100 border-none outline-none`}
                            value={values.color}
                            onChange={event => {
                              setValues(() => ({
                                ...values,
                                color: event.target.value
                              }))
                            }}
                          />
                        </div>

                        <Button
                          type="button"
                          color="primary"
                          className='d-flex justify-content-center align-items-center px-2'
                          outline
                          onClick={() => {
                            document.getElementById("color-picker")?.click()
                          }}
                        >
                          <i className='bx bx-pencil font-size-22'></i>
                        </Button>
                      </div>
                      <Input
                        id="color-picker"
                        type="text"
                        className={`form-control mt-1 ps-0 cursor-pointer border-none outline-none shadow-none`}
                        placeholder='Ex. #3965FF'
                        value={values.color}
                        onChange={event => {
                          setValues(() => ({
                            ...values,
                            color: event.target.value
                          }))
                        }}
                      />
                    </div>
                  </Col>
                  {
                    values.buttonType ?
                      <Col sm={12} className='mt-1 mb-3'>
                        <Row>
                          <Col md={6}>
                            <Label className='fw-500 font-size-14'>Button name</Label>
                            <Input
                              type="text"
                              className={`form-control  ${(isInvalidSubmit && !values.buttonName.trim().length) ? "error-border" : ""}`}
                              placeholder="Ex. Choose profession"
                              value={values.buttonName}
                              onChange={(event) => onChange(event, "buttonName")}
                            />
                          </Col>
                          <Col md={6}>
                            <Label className='fw-500 font-size-14'>Button redirect page</Label>
                            <Input
                              type="text"
                              className={`form-control  ${(isInvalidSubmit && !values.buttonRedirectPage.trim().length) ? "error-border" : ""}`}
                              placeholder="Ex. https://ardy.am/en/about"
                              value={values.buttonRedirectPage}
                              onChange={(event) => onChange(event, "buttonRedirectPage")}
                            />
                          </Col>
                        </Row>
                      </Col>
                      : null
                  }
                  <Col sm={12}>
                    {
                      typeof values.title === "string" ?
                        <RichTextEditor
                          value={values.title}
                          blockClassName="custom-rich-text-editor small-rich-text-editor"
                          labelValue="Title"
                          // isInvalidField={isInvalidSubmit && !values.title.trim().length}
                          lableClassName="fw-500 font-size-14"
                          onEditorChange={event => onEditorChange(event, "title")}
                        />
                        : null
                    }
                    {
                      typeof values.description === "string" ?
                        <RichTextEditor
                          value={values.description}
                          blockClassName="mt-4 custom-rich-text-editor"
                          lableClassName="fw-500 font-size-14"
                          // isInvalidField={isInvalidSubmit && !values.description.trim().length}
                          labelValue="Description"
                          onEditorChange={event => onEditorChange(event, "description")}
                        />
                        : null
                    }
                    <label className={`fw-500 font-size-14 mt-4`}>Video</label>
                    <div className="form-check mb-3">
                      <input
                        id="insertLink"
                        type="checkbox"
                        className="form-check-input"
                        checked={isShowInsertLink}
                        onChange={event => {
                          if (event.target.checked && contentData && contentData.videoPath) {
                            removeContentVideo();
                          } else {
                            setValues(() => ({
                              ...values,
                              videoLink: ""
                            }))
                          }
                          setIsShowInsertLink(event.target.checked)
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="insertLink"
                      >
                        Use insert link
                      </label>
                    </div>

                    {
                      isShowInsertLink ?
                        <div className="mb-3">
                          <Label>Link url</Label>
                          <textarea
                            name="videoLink"
                            rows="2"
                            placeholder="Ex. https://www.youtube.com/watch?v=LXb3EKWsInQ"
                            className={`form-control resize-none  ${isInvalidSubmit && isShowInsertLink && !values.videoLink.trim().length ? "error-border" : ""}`}
                            value={values.videoLink}
                            onChange={(event) => onChange(event, "videoLink", fieldValidation.length_255)}
                          />
                        </div>

                        : <Auxiliary>
                          <div className='position-relative d-flex flex-wrap'>
                            {
                              uploadedVideo ?
                                <div className='d-flex justify-content-center mt-auto'>
                                  <div className='position-relative'>
                                    <video
                                      src={uploadedVideo}
                                      controls
                                      // poster={courseData.videoPath ? videoPoster :""}
                                      controlsList="nodownload nopictonpicture"
                                      className="course-media-file-container rounded w-100"
                                    >
                                    </video>
                                    <Button
                                      style={{ position: "absolute", top: 5, right: 5 }}
                                      color="danger"
                                      className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                      onClick={() => {
                                        setUploadedVideo(null);
                                        setValues(() => ({
                                          ...values,
                                          video: null,
                                        }))
                                      }}
                                    >
                                      <i className="bx bx-x font-size-22"></i>
                                    </Button>
                                  </div>
                                </div>
                                : null
                            }
                            {
                              contentData && contentData.videoPath && !uploadedVideo ?
                                <div className='me-3 position-relative'>
                                  <video
                                    src={contentData.videoPath}
                                    alt="/"
                                    controls
                                    className="course-media-file-container rounded mt-1"
                                  />
                                  <Button
                                    style={{ position: "absolute", top: 0, right: 0 }}
                                    color="danger"
                                    className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                      removeContentVideo()
                                    }}
                                  >
                                    <i className="bx bx-x font-size-22"></i>
                                  </Button>
                                </div>
                                : null
                            }
                          </div>
                          {
                            !uploadedVideo && (!contentData || (contentData && !contentData.videoPath)) ?
                              <b className='d-block'><i>You don't have uploaded video for this content yet.</i></b>
                              : null
                          }
                          {
                            !contentData || (contentData && !contentData.videoPath) ?
                              <div className=" mt-3">
                                <Button
                                  type="button"
                                  color={`primary`}
                                  className='px-4'
                                  onClick={(event) => {
                                    MainService.triggerUploadClick(event);
                                  }}
                                >
                                  Upload video
                                </Button>
                                <input type="file" id='video' className="d-none" hidden onChange={(event) => uploadFile(event, "video")} />
                                <div>
                                  <small><i className='fw-normal'>Allowed file format(s): .mp4</i></small>
                                </div>
                              </div>
                              : null
                          }
                          {
                            fileReadPercent > 0 && fileReadPercent < 100 && fileReadPercentName === "video" ?
                              <div className="col-12 my-2">
                                <ProgressBar now={fileReadPercent} />
                              </div>
                              : null
                          }
                        </Auxiliary>
                    }


                    <hr />
                    <label className={`fw-500 font-size-14`}>Image(s)</label>
                    <div className='position-relative d-flex flex-wrap'>
                      {
                        values.images && values.images.length ?
                          values.images.map((image, imageIndex) => {
                            return <div key={imageIndex} className='me-3 position-relative'>
                              <img
                                src={image.uploadedFile}
                                alt="/"
                                className="avatar-xxl img-thumbnail mt-1"
                              />
                              <Button
                                style={{ position: "absolute", top: 0, right: 0 }}
                                color="danger"
                                className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  removeImage(imageIndex)
                                }}
                              >
                                <i className="bx bx-x font-size-22"></i>
                              </Button>
                            </div>
                          })
                          : null
                      }
                      {
                        values.imagePaths && values.imagePaths.length ?
                          values.imagePaths.map((item, imageIndex) => {
                            return <div key={imageIndex} className='me-3 position-relative'>
                              <img
                                src={item}
                                alt="/"
                                className="avatar-xxl img-thumbnail mt-1"
                              />
                              <Button
                                style={{ position: "absolute", top: 0, right: 0 }}
                                color="danger"
                                className="btn-sm p-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  removeImageFromImagePaths(imageIndex)
                                }}
                              >
                                <i className="bx bx-x font-size-22"></i>
                              </Button>
                            </div>
                          }) : null
                      }
                    </div>
                    {
                      !values.images.length && (!values.imagePaths || (values.imagePaths && !values.imagePaths.length)) ?
                        <b className='d-block'><i>You don't have uploaded images for this content yet.</i></b>
                        : null
                    }
                    <div className=" mt-3">
                      <Button
                        type="button"
                        color={`primary`}
                        className='px-4'
                        onClick={(event) => {
                          MainService.triggerUploadClick(event);
                        }}
                      >
                        Upload image(s)
                      </Button>
                      <input type="file" id='avatarImage' className="d-none" hidden multiple onChange={(event) => uploadFile(event, "image")} />
                      <div>
                        <small><i className='fw-normal'>Allowed file format(s): .jpg, .jpeg, .png, .svg</i></small>
                      </div>
                    </div>
                    {
                      fileReadPercent > 0 && fileReadPercent < 100 && fileReadPercentName === "image" ?
                        <div className="col-12 my-2">
                          <ProgressBar now={fileReadPercent} />
                        </div>
                        : null
                    }
                  </Col>
                </Row>
              </div>
              <hr />
              <div className='d-flex justify-content-end mt-3'>
                <ActionButton
                  type="submit"
                  name="Save"
                  spinnerClass={`w_50`}
                  className="btn btn-primary btn-block px-4"
                  spinnerId={buttonSpinnerId}
                />
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

