import React, { useCallback, useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ReactPaginate from "react-paginate";
import { Table } from "react-bootstrap";
import AlertService from "../../../services/alertService";
import { ERROR_KEY, SUCCESS_KEY } from "../../../constants/mainKeys";
import ApiService from "../../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Label } from "reactstrap";
import NoData from "../../../components/NoData/NoData";
import {
	addButtonSpinner,
	addPageSpinner,
	removeButtonSpinner,
	removePageSpinner,
} from "../../../store/actions";
import uuid from "react-uuid";
import { ARDY_COURSE_URL_KEY } from "../../../constants/apiKeys";
import DownloadCSV from "../../../components/DownloadCSV/DownloadCSV";
import { CSVLink } from "react-csv";
import ReactSelectOption from "../../../components/SelectOptions/ReactSelectOption";
import MainService from "../../../services/mainService";
import moment from "moment";

export default function ShortCourseApplications(props) {
	const dispatch = useDispatch();
	const pageSize = 10;
	const { language } = useSelector(state => state.Languages);
	const [shortCourseApplications, setShortCourseApplications] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [csvData, setCsvData] = useState([]);
	const [courseFaculties, setCourseFaculties] = useState([]);

	const [filterData, setFilterData] = useState({
		facultyId: null
	})

	useEffect(() => {
		getCourseFaculties();
		getAllShortCourseApplications();

		return () => {
			setCourseFaculties([]);
			setCsvData([]);
		}
	}, [])

	useEffect(() => {
		getShortCourseApplications(1);
		setActivePageNumber(0)
		return () => {
			setShortCourseApplications([]);
		};
	}, [filterData]);

	const getCourseFaculties = () => {
		const spinnerId = uuid();
		setPageSpinner(spinnerId)
		ApiService.getFaculties(1).then(response => {
			if (response && response.data) {
				const data = [...response.data];
				data.unshift({ title: "All", id: null })
				setCourseFaculties(data);
			}
			extractPageSpinner(spinnerId)
		}).catch(error => getFail(error, spinnerId))
	}

	const handlePageClick = event => {
		getShortCourseApplications(event.selected + 1);
		setActivePageNumber(event.selected);
	};

	const getShortCourseApplications = (currentPage, pageSize = 10) => {
		if (!currentPage) {
			return false;
		}
		const spinnerId = uuid();
		setPageSpinner(spinnerId);
		ApiService.getShortCourseApplications(currentPage, pageSize, filterData)
			.then(response => {
				if (response && response.pagination) setPagination(JSON.parse(response.pagination));
				if (response && response.data) {
					setShortCourseApplications(response.data);
				}
				extractPageSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getAllShortCourseApplications = () => {
		const spinnerId = uuid();
		setPageSpinner(spinnerId);
		ApiService.getAllShortCourseApplications()
			.then(response => {
				if (response && response.data) {
					let data = [...response.data];
					const filteredData = data.map(item => ({
						courseName: item.courseName || '',
						name: item.name || '',
						email: item.email || '',
						phoneNumber: item.phoneNumber || '',
						education: item.education || '',
						educationalInstitution: item.educationalInstitution || '',
						placeOfWork: item.placeOfWork || '',
						referralSource: item.referralSource || '',
						createDate: item.createDate ? moment(MainService.convertUTCDateToLocalDate(new Date(item.createDate))).format("ll HH:mm") : '',
						questions: item.questions || '',
					}));
					const keys = Object.keys(filteredData[0]);
					const stringKeys = keys.filter(key => typeof filteredData[0][key] === 'string');
					const formattedKeys = stringKeys.map(camelCaseToWords);
					const _csvData = [formattedKeys];
					filteredData.forEach(item => {
						const values = stringKeys.map(key => item[key]);
						_csvData.push(values);
					});
					setCsvData(_csvData);
				}

				extractPageSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const camelCaseToWords = (str) => {
		return str.replace(/([a-z])([A-Z])/g, '$1 $2')
			.replace(/\b\w/g, function (char) { return char.toUpperCase(); });
	}

	const onSelectOptionChange = (item, name) => {
		if (!item) { return false; }
		setFilterData((values) => ({
			...values,
			[name]: item.value
		}))
	}

	const deleteApplication = id => {
		if (!id) {
			return false;
		}
		const spinnerId = uuid();
		AlertService.alertConfirm(`Are you sure ?`, "", "Yes", "No").then(() => {
			setPageSpinner(spinnerId);
			ApiService.deleteApplication(id)
				.then(response => {
					setShortCourseApplications(
						shortCourseApplications.filter(item => item.id !== +id),
					);
					setCsvData(csvData.filter(item => item.id !== +id))
					AlertService.alert(SUCCESS_KEY, "Data saved");
					extractPageSpinner(spinnerId);
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const setButtonSpinner = useCallback(spinner => {
		dispatch(addButtonSpinner(spinner));
	}, []);

	const setPageSpinner = useCallback(spinner => {
		dispatch(addPageSpinner(spinner));
	}, []);

	const extractButtonSpinner = useCallback(spinner => {
		dispatch(removeButtonSpinner(spinner));
	}, []);

	const extractPageSpinner = useCallback(spinner => {
		dispatch(removePageSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPageSpinner(spinnerId);
		spinnerId && extractButtonSpinner(spinnerId);
	};

	return (
		<div className="page-content position-relative">
			<div className="container-fluid">
				<Breadcrumbs title="Ardy" breadcrumbItem="Short Course Applications" />
				<hr className="mt-0" />
				<div className='d-flex justify-content-between align-items-end flex-wrap mb-3'>

					<div className='d-flex justify-content-between align-items-end flex-wrap '>
						<p className='m-2 mb-0 mb-sm-3'><b>Filter by:</b></p>
						<div className='d-flex flex-wrap'>
							{
								courseFaculties && courseFaculties.length ?
									<div style={{ minWidth: "200px" }} className='m-2'>
										<Label className='fw-500 font-size-14'>Faculty type</Label>
										<ReactSelectOption
											value={filterData.facultyId}
											selectedValue={(() => {
												const selectedItem = { ...courseFaculties.find(data => data.id === filterData.facultyId) };
												if (Object.keys(selectedItem).length) {
													selectedItem.value = selectedItem.id;
													selectedItem.label = selectedItem.title;
													return selectedItem;
												} else {
													return { label: "Faculty type", value: "" }
												}
											})()}
											className={`rounded`}
											items={courseFaculties.map(data => ({ ...data, label: data.title, value: data.id }))}
											onChange={item => onSelectOptionChange(item, "facultyId")}
										/>
									</div>
									: null
							}
						</div>
					</div>
					<div>
						{
							csvData && csvData.length ?
								<div className='d-flex justify-content-end mb-3'>
									<CSVLink data={csvData} filename={`short_course_applications_${Date.now()}.csv`}>
										<Button
											color="primary"
											className="m-1 d-flex justify-content-center align-items-center"
											title='Download CSV'
											onClick={() => { }}
										>
											Download CSV
										</Button>
									</CSVLink>
								</div>
								: null
						}
					</div>
				</div>
				<hr className="mt-0" />
				{shortCourseApplications && shortCourseApplications.length ? (
					<Card className="border-none">
						<CardBody className='pt-0'>
							<Table responsive className="table mb-0 table-bordered">
								<thead>
									<tr>
										<th className="no-wrap">Course Name</th>
										<th className="no-wrap">Full name</th>
										<th className="no-wrap">Email</th>
										<th className="no-wrap">Phone Number</th>
										<th className="no-wrap">Education</th>
										<th className="no-wrap">Educational institution</th>
										<th className="no-wrap">Place Of Work</th>
										<th className="no-wrap">Referral source</th>
										<th className="no-wrap">Questions</th>
										<th className="no-wrap">Create Date</th>
										<th className="no-wrap">Action</th>
									</tr>
								</thead>
								<tbody>
									{shortCourseApplications.map((item, index) => (
										<tr key={index}>
											<td>
												<span title={item.courseName}>
													<a
														href={`${ARDY_COURSE_URL_KEY}${item.courseId}`}
														className="underline"
														target="_blank">
														{item.courseName}
													</a>
												</span>
											</td>
											<td style={{ minWidth: "250px" }}>
												<span title={item.name}>{item.name}</span>
											</td>
											<td>
												<span title={item.email}>{item.email}</span>
											</td>
											<td style={{ minWidth: "250px" }}>
												<span title={item.phoneNumber}>{item.phoneNumber}</span>
											</td>
											<td style={{ minWidth: "250px" }}>
												<span title={item.education}>{item.education}</span>
											</td>
											<td style={{ minWidth: "250px" }}>
												<span title={item.educationalInstitution}>
													{item.educationalInstitution}
												</span>
											</td>
											<td style={{ minWidth: "250px" }}>
												<span title={item.placeOfWork}>{item.placeOfWork}</span>
											</td>
											<td style={{ minWidth: "250px" }}>
												<span title={item.referralSource}>
													{item.referralSource}
												</span>
											</td>
											<td style={{ minWidth: "250px" }}>
												<span title={item.questions}>{item.questions}</span>
											</td>
											<td style={{ minWidth: "250px" }}>
												<span>
													{
														item.createDate ?
															moment(MainService.convertUTCDateToLocalDate(new Date(item.createDate))).format("ll HH:mm")
															: null
													}
												</span>
											</td>
											<td>
												<div className='d-flex'>
													{/* <DownloadCSV value={item} /> */}
													<Button
														color="danger"
														className="m-1 btn-sm d-flex justify-content-center align-items-center"
														title='Delete'
														outline
														onClick={() => deleteApplication(item.id)}
													>
														<i className='mdi mdi-delete font-size-18'></i>
													</Button>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
							{pagination &&
								pagination.TotalPages &&
								pagination.TotalPages > 1 ? (
								<div className=" mt-4 d-flex justify-content-end w-100">
									<ReactPaginate
										nextLabel="Next"
										onPageChange={handlePageClick}
										pageRangeDisplayed={3}
										marginPagesDisplayed={2}
										pageCount={pagination.TotalPages}
										previousLabel="Previous"
										pageClassName="page-item"
										pageLinkClassName="page-link"
										previousClassName="page-item"
										previousLinkClassName="page-link"
										nextClassName="page-item"
										nextLinkClassName="page-link"
										breakLabel="..."
										breakClassName="page-item"
										breakLinkClassName="page-link"
										containerClassName="pagination flex-wrap"
										activeClassName="active"
										renderOnZeroPageCount={null}
										forcePage={activePageNumber}
									/>
								</div>
							) : null}
						</CardBody>
					</Card>
				) : (
					<div>
						<NoData />
					</div>
				)}
			</div>
		</div>
	);
}
