import React, { useState, useEffect, useCallback } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ActionButton from "../../../components/Buttons/ActionButton";
import uuid from "react-uuid";
import MainService from "../../../services/mainService";
import {
	ERROR_KEY,
	MAXIMUM_UPLOAD_FILE_SIZE,
	VALID_IMAGE_TYPES_KEY,
	fieldValidation,
} from "../../../constants/mainKeys";
import AlertService from "../../../services/alertService";
import ApiService from "../../../services/apiService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
	addButtonSpinner,
	addPageSpinner,
	removeButtonSpinner,
	removePageSpinner,
} from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import {
	Label,
	Row,
	Col,
	Input,
	Card,
	CardBody,
	Form,
	Button,
} from "reactstrap";
import FormLanguage from "../../../components/FormLanguage/FormLanguage";
import ShowMoreText from "react-show-more-text";

const buttonSpinnerId = uuid();

export default function CourseTypeForm(props) {
	const dispatch = useDispatch();
	const mainService = new MainService();
	const { language, languages, formLanguage } = useSelector(
		state => state.Languages,
	);
	const { history } = props;
	const [values, setValues] = useState({
		title: "",
		description: "",
		color: "#ffffff",
		image: null,
		showApplicationForm: false,
		useCardLayout: false,
		showRegistrationForm: false,
	});
	const { courseTypeId } = useParams();
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [changes, setChanges] = useState(false);
	const [courseTypeData, setCourseTypeData] = useState(null);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [imageNameToBeRemoved, setImageNameToBeRemoved] = useState(null);

	useEffect(() => {
		if (courseTypeId && formLanguage) {
			getCourseTypeById();
		}
	}, [courseTypeId, formLanguage]);

	const getCourseTypeById = () => {
		const spinnerId = uuid();
		setPageSpinner(spinnerId);
		ApiService.getCourseTypeById(+courseTypeId, formLanguage.id)
			.then(response => {
				if (response && response.data) {
					const data = { ...response.data };
					setCourseTypeData(data);
					data.image = null;
					setValues(data);
				}
				extractPageSpinner(spinnerId);
			})
			.catch(error => getFail(error));
	};

	const uploadFile = (event, field, cb) => {
		if (!event.target.files[0]) {
			return;
		}
		const file = event.target.files[0];
		if (file) {
			if (file.size >= MAXIMUM_UPLOAD_FILE_SIZE * 1024 * 1024) {
				AlertService.alert("warning", "Incorrect file size");
				return false;
			}
			mainService
				.readFile(file, VALID_IMAGE_TYPES_KEY)
				.then(uploadedImage => {
					cb(uploadedImage);
					setValues(values => ({
						...values,
						[field]: file,
					}));
				})
				.catch(error => {
					error && AlertService.alert("error", "Invalid file format");
					cb(null);
				});
		}
	};

	const removeImageFromPaths = (imagePath, fieldName, cb) => {
		const parts = imagePath.split("/");
		const filename = parts[parts.length - 1];
		cb(filename);
		setCourseTypeData(values => ({
			...values,
			[fieldName]: null,
		}));
	};

	const onChange = (event, field, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		setValues(values => ({
			...values,
			[field]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const onSubmit = event => {
		event && event.preventDefault();
		if (!formLanguage) {
			return false;
		}
		if (!values.title.trim().length) {
			setIsInvalidSubmit(true);
			MainService.scrollToInvalidFieldPosition();
			return false;
		}
		const formData = new FormData();
		if (courseTypeId) formData.append("id", courseTypeId);
		if (imageNameToBeRemoved)
			formData.append("imageNameToBeRemoved", imageNameToBeRemoved);
		formData.append("languageId", formLanguage.id);

		Object.entries(values).forEach(([key, value]) => {
			formData.append(key, typeof value === "boolean" || value ? value : "");
		});

		setButtonSpinner(buttonSpinnerId);
		(courseTypeId
			? ApiService.updateCourseType(formData)
			: ApiService.createCourseType(formData)
		)
			.then(response => {
				extractButtonSpinner(buttonSpinnerId);
				history.push(`/${language}/course-types`);
			})
			.catch(error => getFail(error, buttonSpinnerId));
	};

	const setButtonSpinner = useCallback(spinner => {
		dispatch(addButtonSpinner(spinner));
	}, []);

	const setPageSpinner = useCallback(spinner => {
		dispatch(addPageSpinner(spinner));
	}, []);

	const extractButtonSpinner = useCallback(spinner => {
		dispatch(removeButtonSpinner(spinner));
	}, []);

	const extractPageSpinner = useCallback(spinner => {
		dispatch(removePageSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPageSpinner(spinnerId);
		spinnerId && extractButtonSpinner(spinnerId);
	};

	return (
		<div className="page-content position-relative">
			<div className="container-fluid">
				<Breadcrumbs title="Ardy" breadcrumbItem="Course type Form" />
				<FormLanguage />
				<hr className="mt-0" />
				<ShowMoreText
					lines={2}
					more="Read more"
					less="Less"
					className="mb-3 mt-0 more-less-text"
					anchorClass="underline cursor-pointer pt-0"
					expanded={false}
					truncatedEndingComponent={
						<span>
							...
							<br />
						</span>
					}>
					<div className="form-info-text">
						<p className="mb-1">
							Welcome to the Course Type Form page, where you can create and
							manage different types of courses. This page allows you to define
							the characteristics and details of each course type. Here's an
							overview of the page's features.
						</p>
						<p className="mb-1">
							{" "}
							<span className="fw-500">Title: </span>
							Enter a descriptive title for your course type. This title helps
							users understand the purpose and focus of the course type. The
							title field is required and should be no more than{" "}
							{fieldValidation.length_100} characters.
						</p>
						<p className="mb-1">
							{" "}
							<span className="fw-500">Theme Color: </span>
							Choose a theme color that represents the course type. This color
							adds a visual identity to the course type. You can pick a color
							from the color picker or manually enter a color code (e.g.,
							#3965FF).
						</p>
						<p className="mb-1">
							{" "}
							<span className="fw-500">Description: </span>
							Provide a detailed description of the course type. Use this space
							to explain the unique features and benefits of this course type.
							The description field allows up to {
								fieldValidation.length_2000
							}{" "}
							characters.
						</p>
						<p className="mb-1">
							{" "}
							<span className="fw-500">Upload Image: </span>
							You have the option to upload an image that visually represents
							the course type. This image enhances the course type's visual
							appeal. Supported image formats are displayed in the color-picker
							button. Uploaded images can be removed by clicking the "X" button.
						</p>
						<p className="mb-1">
							{" "}
							<span className="fw-500">Save Changes: </span>
							Once you have filled in the required details and made any
							necessary changes, click the "Save" button to create or update the
							course type. The button will be disabled until you make changes to
							the form fields. A spinner will appear during the submission
							process.
						</p>
						<p className="mb-1">
							{" "}
							<span className="fw-500">Language: </span>
							Choose the language in which you want to create the course type
							using the "Languages" component at the top. Select the appropriate
							language for your audience.
						</p>
					</div>
				</ShowMoreText>
				<Card>
					<CardBody>
						<Form onChange={() => setChanges(true)} onSubmit={onSubmit}>
							<Row>
								<Col md={6}>
									<div className="mb-3">
										<Label>Title*</Label>
										<Input
											type="text"
											className={`form-control ${
												isInvalidSubmit && !values.title.trim().length
													? "error-border"
													: ""
											}`}
											placeholder="Ex. Paid courses"
											value={values.title}
											onChange={event =>
												onChange(event, "title", fieldValidation.length_100)
											}
										/>
										<small>
											<i>Max {fieldValidation.length_100} characters.</i>
										</small>
									</div>
								</Col>
								<Col sm={6}>
									<div className="mb-3">
										<Label>Course type theme color</Label>
										<div className="d-flex">
											<div
												className="border rounded overflow-hidden  me-1 p-1"
												style={{ height: 38, width: 100 }}>
												<Input
													id="color-picker"
													type="color"
													className={`form-control d-block p-0 h-100 border-none outline-none`}
													value={values.color}
													onChange={event => {
														setValues(() => ({
															...values,
															color: event.target.value,
														}));
													}}
												/>
											</div>

											<Button
												type="button"
												color="primary"
												className="d-flex justify-content-center align-items-center px-2"
												outline
												onClick={() => {
													document.getElementById("color-picker")?.click();
												}}>
												<i className="bx bx-pencil font-size-22"></i>
											</Button>
										</div>
										<Input
											id="color-picker"
											type="text"
											className={`form-control mt-1 ps-0 cursor-pointer border-none outline-none shadow-none`}
											placeholder="Ex. #3965FF"
											value={values.color}
											onChange={event => {
												setValues(() => ({
													...values,
													color: event.target.value,
												}));
											}}
										/>
									</div>
								</Col>
								<Col sm={6}>
									<div className="mb-3">
										<Label>Show Request Form</Label>
										<div className="form-check form-switch">
											<input
												type="checkbox"
												className="form-check-input checkbox"
												id="checkbox_1"
												checked={values.showApplicationForm}
												onChange={event =>
													setValues(values => ({
														...values,
														showApplicationForm: event.target.checked,
													}))
												}
											/>

											<label className="form-check-label" htmlFor="checkbox_1">
												Yes
											</label>
										</div>
									</div>
								</Col>
								<Col sm={6}>
									<div className="mb-3">
										<Label>Use Card Layout</Label>
										<div className="form-check form-switch">
											<input
												type="checkbox"
												className="form-check-input checkbox"
												id="checkbox_2"
												checked={values.useCardLayout}
												onChange={event =>
													setValues(values => ({
														...values,
														useCardLayout: event.target.checked,
													}))
												}
											/>

											<label className="form-check-label" htmlFor="checkbox_2">
												Yes
											</label>
										</div>
									</div>
								</Col>
								<Col sm={6}>
									<div className="mb-3">
										<Label>Show Registration Form</Label>
										<div className="form-check form-switch">
											<input
												type="checkbox"
												className="form-check-input checkbox"
												id="showRegistrationForm"
												checked={values.showRegistrationForm}
												onChange={event =>
													setValues(values => ({
														...values,
														showRegistrationForm: event.target.checked,
													}))
												}
											/>

											<label
												className="form-check-label"
												htmlFor="showRegistrationForm">
												Yes
											</label>
										</div>
									</div>
								</Col>

								<Col sm={12}>
									<div className="mb-3">
										<Label>Description</Label>
										<textarea
											name="description"
											rows="4"
											placeholder="Ex. Unlock premium knowledge and expertise with our ' Paid courses.' Invest in your personal and professional growth by accessing exclusive content, expert guidance, and valuable resources. Elevate your skills and stay ahead in your field with our comprehensive paid courses."
											className="form-control resize-none mt-1"
											value={values.description}
											onChange={event =>
												onChange(
													event,
													"description",
													fieldValidation.length_2000,
												)
											}
										/>
										<small>
											<i>Max {fieldValidation.length_2000} characters.</i>
										</small>
									</div>
								</Col>
								<Col md={12}>
									<div className="mb-3">
										{uploadedFile ||
										(courseTypeData && courseTypeData.imagePath) ? (
											<div className="position-relative d-inline-block">
												<img
													src={
														uploadedFile
															? uploadedFile
															: courseTypeData && courseTypeData.imagePath
															? courseTypeData.imagePath
															: ""
													}
													alt="/"
													style={{ height: "200px" }}
													className="img-thumbnail"
												/>
												<Button
													style={{ position: "absolute", top: 5, right: 5 }}
													color="danger"
													className="btn-sm p-0 d-flex justify-content-center align-items-center"
													onClick={() => {
														setChanges(true);
														if (uploadedFile) {
															setUploadedFile(null);
															setValues(values => ({
																...values,
																image: null,
															}));
														} else {
															removeImageFromPaths(
																courseTypeData.imagePath,
																"imagePath",
																setImageNameToBeRemoved,
															);
														}
													}}>
													<i className="bx bx-x font-size-22"></i>
												</Button>
											</div>
										) : null}
										{courseTypeData && courseTypeData.imagePath ? <br /> : null}
										{!uploadedFile &&
										(!courseTypeData ||
											(courseTypeData && !courseTypeData.imagePath)) ? (
											<Button
												type="button"
												color={`${!uploadedFile ? "primary" : "danger"}`}
												className={`px-4 ${
													courseTypeData && courseTypeData.imagePath
														? "mt-2"
														: ""
												}`}
												onClick={event => {
													if (!uploadedFile) {
														MainService.triggerUploadClick(event);
													}
												}}>
												Upload image
												<input
													type="file"
													id="avatarImage"
													className="d-none"
													hidden
													onChange={event =>
														uploadFile(event, "image", setUploadedFile)
													}
												/>
											</Button>
										) : null}
									</div>
								</Col>
							</Row>
							<div className="d-flex justify-content-end mt-3">
								<ActionButton
									type="submit"
									name="Save"
									disabled={!changes}
									spinnerClass={`w_50`}
									className="btn btn-primary btn-block px-4"
									spinnerId={buttonSpinnerId}
								/>
							</div>
						</Form>
					</CardBody>
				</Card>
			</div>
		</div>
	);
}
