import React, { useCallback, useState, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { ERROR_KEY, SUCCESS_KEY } from '../../../constants/mainKeys'
import { addPageSpinner, removePageSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../services/alertService'
import { useDispatch } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { Table } from "react-bootstrap"
import FormLanguage from '../../../components/FormLanguage/FormLanguage'
import ApiService from '../../../services/apiService'
import uuid from 'react-uuid'
import NoData from '../../../components/NoData/NoData'
import ShowMoreText from "react-show-more-text"

const News = (props) => {

  const pageSize = 10;
  const { history } = props;
  const dispatch = useDispatch();
  const { language, formLanguage } = useSelector(state => state.Languages);
  const [news, setNews] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);

  useEffect(() => {
    if (formLanguage) {
      getNews(1);
    }
    return () => {
      setNews([]);
    }
  }, [formLanguage])

  const handlePageClick = (event) => {
    getNews(event.selected + 1);
    setActivePageNumber(event.selected);
  };

  const getNews = (currentPage) => {
    if (!formLanguage || !currentPage) { return false; }
    const spinnerId = uuid();
    setPageSpinner(spinnerId)
    ApiService.getNews(formLanguage.id, currentPage, pageSize).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setNews(response.data);
      }
      extractPageSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const deleteNews = (newsId) => {
    if (!newsId) { return false; }
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setPageSpinner(spinnerId)
      ApiService.deleteNews(newsId).then(response => {
        setNews(news.filter(item => item.id !== +newsId));
        AlertService.alert(SUCCESS_KEY, "Data saved");
        extractPageSpinner(spinnerId)
      }).catch(error => getFail(error, spinnerId))
    })
  }

  const setPageSpinner = useCallback(spinner => {
    dispatch(addPageSpinner(spinner));
  }, []);

  const extractPageSpinner = useCallback(spinner => {
    dispatch(removePageSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPageSpinner(spinnerId);
  }

  return (
    <div className='page-content position-relative'>
      <div className="container-fluid">
        <Breadcrumbs
          title="Ardy"
          breadcrumbItem="News"
        />
        <FormLanguage />
        <hr className='mt-0' />
        <Row className='mb-3'>
          <Col sm={12} className='d-flex justify-content-end'>
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => {
                history.push(`/${language}/news-form`)
              }}
            > 
              Create a news
            </Button>
          </Col>
        </Row>
        {
          news && news.length ?
            <Card>
              <CardBody>
                <Table responsive className="table mb-0 table-bordered">
                  <thead>
                    <tr>
                      <th className='no-wrap'>Image</th>
                      <th className='no-wrap'>Title</th>
                      <th className='no-wrap'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {news.map((item, index) => (
                      <tr key={index}>
                        {
                          item.imagePaths && item.imagePaths.length ?
                            <td>
                              <div
                                className="avatar-md"
                                style={{
                                  backgroundImage: `url(${item.imagePaths[0]})`,
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                }}
                              />
                            </td>
                            : <td>
                              <i className='bx bx-image font-size-30'></i>
                            </td>
                        }
                        <td>
                          <span className='word-break-break-word max-line-2' title={item.title}> {item.title}</span>
                        </td>

                        <td className='d-flex border-none'>
                          <Button
                            color="success"
                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                            title='Edit'
                            outline
                            onClick={() => {
                              props.history.push(`/${language}/news-form/${item.id}`)
                            }}
                          >
                            <i className='mdi mdi-pencil font-size-18'></i>
                          </Button>
                          <Button
                            color="danger"
                            className="m-1 btn-sm d-flex justify-content-center align-items-center"
                            title='Delete'
                            outline
                            onClick={() => deleteNews(item.id)}
                          >
                            <i className='mdi mdi-delete font-size-18'></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {
                  pagination && pagination.TotalPages && pagination.TotalPages > 1 ?
                    <div className=' mt-4 d-flex justify-content-end w-100'>
                      <ReactPaginate
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pagination.TotalPages}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination flex-wrap"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        forcePage={activePageNumber}
                      />
                    </div>
                    : null
                }
              </CardBody>
            </Card>
            : <div>
              <NoData />
              <div className='my-4 d-flex justify-content-center flex-wrap'>
                <b className='d-flex text-center text-wrap'>
                  You don't have created News yet
                </b>
                <b>
                  <Link to={`/${language}/news-form`} className="ms-2">
                    <span className='underline'>Create a news</span>
                    <i className='bx bx-link-external ms-1 d-inline'></i>
                  </Link>
                </b>
              </div>
            </div>
        }

      </div>
    </div>
  )
}

export default withRouter(News)